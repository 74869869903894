import { useEffect, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';
import jsonToFormData from "@ajoelp/json-to-formdata";
import 'react-toastify/dist/ReactToastify.css';

import { Back } from "../Back";
import FormInput from "../FormElement/FormInput";
import FormUploadImage from "../FormElement/FormUploadImage";
import { IRrppRequest } from "../../types/IRrpp";
import RrppService from "../../services/rrppService";
import FormSelect from "../FormElement/FormSelect";
import { AuthContext } from "../../context/AuthContext";
import { StateContext } from "../../context/StateProvider";
import {teamRoles} from "../../utils/roles";

const schema = yup.object().shape({
  first_name: yup.string()
    .required('El nombre es requerido'),
  last_name: yup.string()
    .required('El apellido es requerido'),
  email: yup.string()
    .required('El email es requerido')
    .email('El correo electrónico no es válido'),
  password: yup.string()
    .required('El password es requerido'),
  profile_picture: yup.mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
        if(value instanceof File){
          return value.size <= 20000000;
        }
        return true;
    })
});

const schemaEdit = yup.object().shape({
  first_name: yup.string()
    .required('El nombre es requerido'),
  last_name: yup.string()
    .required('El apellido es requerido'),
  email: yup.string()
    .required('El email es requerido')
    .email('El correo electrónico no es válido'),
  password: yup.string(),
  profile_picture: yup.mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
        if(value instanceof File){
          return value.size <= 20000000;
        }
        return true;
    })
});

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  picture: ''
};

const statusOptions: Array<object> = [
  { value: 'active', text: 'Activo' },
  { value: 'paused', text: 'Pausado' },
]

export const FormRrpp = () => {
  const { user, role } = useContext(AuthContext);
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const { id, producer_id, producer_producer_id } = useParams();

  const createItems = useMutation(
    (item: FormData) => RrppService.create(item), {
    onError(error: any) { toast.error(error.response.data.error) },
  }
  );
  const updateItems = useMutation(
    (item: FormData) => RrppService.update(id, item), {
    onError(error: any) { toast.error(error.response.data.error) },
  }
  );
  const getUserApi = useMutation(
    (id: string) => RrppService.get(id)
  );

  const form = useForm<IRrppRequest>({
    resolver: yupResolver(id ? schemaEdit : schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    console.log(id);
    if (id) {
      getUserApi.mutateAsync(id).then(res => {
        if (!res.team_role && ['validator','leader','seller'].includes(res.user_role)) {
          res.team_role = res.user_role
        }
        form.reset(res)
      });
    }
  }, [id, form.reset]);

  const { state, dispatch } = useContext(StateContext);

  const onSubmit = async (data: IRrppRequest) => {
    if (user.user_role != 'admin')
      data.producer_id = user.id;
    if (data.team_role && ['validator','leader','seller'].includes(data.team_role)) {
      data.user_role = data.team_role;
    }
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true
    });

    dispatch({ type: "showLoaderScreen", payload: true });
    if (id) {
      updateItems.mutateAsync(formData).then(res => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
        handleBack();
      });
    } else {
      createItems.mutateAsync(formData).then(res => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Registro creado correctamente" });
        handleBack();
      });
    }
  };

  const handleBack = () => {
    let prefix = '';
    if (role != 'admin') {
      prefix += '/client';
    } else {
      if (producer_producer_id)
        prefix += `/admin/producers/${producer_id}/producer_producer/${producer_producer_id}`;
      else prefix += '/admin';
    }
    navigate(prefix + '/rrpp');
  };

  const onError = (error: any) => {
    console.log("error ", error);
  }

  return (
    <div className="">
      <Back onClick={handleBack} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold"> {id ? 'Editar Vendedor' : 'Crear nuevo Vendedor'}</h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormInput
              name="first_name"
              type="text"
              label="Nombre"
            />
            <FormInput
              name="last_name"
              type="text"
              label="Apellido"
            />
            <FormInput
              name="email"
              type="text"
              label="Correo"
            />
            <FormInput
              name="password"
              type="password"
              label="Contraseña"
            />
            {
              role == "admin" && !producer_producer_id ?
                <FormSelect
                  name='team_role'
                  options={teamRoles}
                  label="Rol"
                /> :
                <div></div>
            }
            {
              currentLocation.pathname.includes('edit') ?
                <FormSelect
                  name='status'
                  options={statusOptions}
                  label="Estado del cliente"
                />
                : null
            }
            <div className="flex justify-center gap-10 ">
              <FormUploadImage
                name="profile_picture"
                label="Picture"
              />
            </div>
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {id ? 'Editar Vendedor' : 'Crear Vendedor'}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
