import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { GoArrowUp, GoGraph } from "react-icons/go";
import { toast } from "react-toastify";
import { bool } from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import moment from "moment/moment";

import { Title } from "../Title";
import { FilterPageWithLink } from "../../table/FilterPageWithLink";
import { FilterQtyPage } from "../../table/FilterQtyPage";
import { useMutation } from "@tanstack/react-query";
import OrderService from "../../../services/orderService";
import { IOrdersResponse } from "../../../types/IOrder";
import { IFilterRequest } from "../../../types/IFilter";
import { formatDate } from "../../../utils/format-date";
import { formatMoney } from "../../../utils/format-money";
import { getPaymentStatusFromValue } from "../../../utils/status";
import DropdownDots from "../../FormElement/DropdownDots";
import { StateContext } from "../../../context/StateProvider";
import LoaderCircle from "../../FormElement/LoaderCircle";
import { FilterSection } from "../../table/FilterSection";
import { AuthContext } from "../../../context/AuthContext";
import { IEventResponse } from "../../../types/IEvent";
import eventService from "../../../services/eventService";
import TableCellLink from "../../FormElement/TableCellLink.tsx";
import config from "../../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const filterSectionProps = [
  { title: "Todos las ordenes", id: "all" },
  { title: "Pendiente de aprobación", id: "pending" },
  { title: "Aprobado", id: "approved" },
  { title: "Rechazadas", id: "rejected" },
];

const options: Array<any> = [
  { text: "Generar Factura", value: "generate_invoice" },
  { text: "Cambiar estado a procesando", value: "in_process" },
  { text: "Cambiar estado a en espera", value: "pending" },
  { text: "Cambiar estado a completado", value: "approved" },
  { text: "Cambiar estado a cancelado", value: "rejected" },
  { text: "Mover a papelera", value: "move_to_trash" },
];

const columnData = [
  "Orden",
  "Fecha",
  "Vendedores",
  "Email",
  "Cantidad",
  "Precio",
  "Tipo",
  "Status Pago",
  /* "Nombre",
      "Usados",
      "Status",
      "Método de pago", */
  "",
];

type selectedProps = {
  data: IEventResponse;
};

export const OrdersEvent = ({ data }: selectedProps) => {
  const [searchParams] = useSearchParams();
  const page =
    searchParams.get("page") !== null
      ? +(searchParams.get("page") as string)
      : 1;

  const defaultFilters = {
    limit: config.filter_default_limit,
    page,
    status: "all",
    sortBy: "createdAt",
  };

  const { event_id } = useParams();
  const [result, setResult] = useState<IOrdersResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const getItems = useMutation((filters: IFilterRequest) =>
    OrderService.getAll(filters)
  );
  const cleanOrders = useMutation(() =>
    OrderService.cleanRejectedOrders(event_id || "")
  );
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [action, setAction] = useState("generate_invoice");

  const { state, dispatch } = useContext(StateContext);

  useEffect(() => {
    handleFilter({ ...filters, event_id });
  }, [window.location.href]);

  const handleFilter = async (filter: IFilterRequest) => {
    const newFilters = { ...filters, ...filter, event_id };
    await setFilters(newFilters);
    setShowLoader(true);
    await getItems.mutateAsync({ ...newFilters, page }).then((res) => {
      setResult(res);
      setShowLoader(false);
    });
  };

  // const handleOrderDetail = (id: string) => {
  //   if (window.location.pathname.includes("admin/"))
  //     navigate("/admin/order/" + id);
  //   if (window.location.pathname.includes("client/"))
  //     navigate("/client/order/" + id);
  // };

  const getOrderDetailLink = (id: string) => {
    let prefix: string = "";

    if (window.location.pathname.includes("admin/")) prefix = "admin";
    if (window.location.pathname.includes("client/")) prefix = "client";

    return `/${prefix}/order/${id}`;
  };

  const handleOrderDetail = (id: string) => {
    navigate(getOrderDetailLink(id));
  };

  const handleFilterSection = async (filter?: string) => {
    console.log(filter);

    if (!filter) return;
    const newFilters = { ...filters, page: 1, status: filter, event_id };
    await setFilters(newFilters);
    setShowLoader(true);
    getItems.mutateAsync(newFilters).then((res) => {
      setResult(res);
      setShowLoader(false);
    });
  };

  let resultado: any[] = [];
  resultado = result.rows;

  let paramsFilters: any = { status: "", sortBy: "" };

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    setShowLoader(true);

    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm);
      searcher(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, event_id]);

  const searcher = async (text: string) => {
    setSearch(text);
    paramsFilters = { ...filters, query: text, event_id };
    await setFilters({ ...filters, page: 1, query: text });
    await getItems.mutateAsync(paramsFilters).then((res) => {
      setShowLoader(false);
      setResult(res);
    });
  };

  const handleEdit = (id: string) => {
    if (window.location.pathname.includes("admin/"))
      navigate(`/admin/order/${id}/${"edit"}`);
    if (window.location.pathname.includes("client/"))
      navigate(`/client/order/${id}/${"edit"}`);
  };

  const handleSendRecycler = (id: string) => {
    if (
      window.confirm(
        "Confirma que quiere enviar este registro a la papelera de reciclaje ?"
      )
    ) {
      const newFilters = { ...filters, event_id };
      recyclerItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync(newFilters).then((res) => setResult(res));
      });
    }
  };

  const recyclerItems = useMutation(
    (id: string) => OrderService.sendRecycler(id),
    {
      onSuccess(data: any) {
        // toast.success(data.message);
        dispatch({
          type: "toastSuccess",
          payload: "El Order fue enviado a la papelera de reciclaje.",
        });
      },
    }
  );

  const getTicketsUsed = (order_tickets = []) => {
    let result = order_tickets.reduce(
      (total, currentValue: any) =>
        currentValue.status == "used" ? total + 1 : total,
      0
    );
    return result;
  };

  const handleChangeStatus = (statuschange: string) => {
    console.log(statuschange);
    setAction(statuschange);
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    const ids = [];
    for (let index = 0; index < resultado.length; index++) {
      const item = resultado[index].id;
      ids.push(item);
    }
    setIsCheck(ids);
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any, idItem: string) => {
    console.log(e.target.checked);
    const { checked } = e.target;
    setIsCheck([...isCheck, idItem]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== idItem));
    }
    setIsCheckAll(false);
  };

  const handleVerify = (idItem: string) => {
    return isCheck.includes(idItem);
  };

  const handleVerifyAll = () => {
    return isCheckAll;
  };

  const handleAplyChanges = () => {
    if (isCheck.length != 0) {
      console.log(action);

      // if (action == "generate_invoice") {
      //   for (let index = 0; index < isCheck.length; index++) {
      //     const element = isCheck[index];
      //     OrderService.getInvoice({ order_id: element }).then((res: any) => {
      //       console.log(res);
      //     });
      //   }
      // }
      if (action == "generate_invoice") {
        dispatch({ type: "showLoaderScreen", payload: true });
        const formData = jsonToFormData(
          { order_ids: isCheck },
          {
            arrayIndexes: true,
            excludeNull: true,
          }
        );
        OrderService.createInvoice(formData)
          .then((res: any) => {
            let msg = "Facturas generadas correctamente";
            let toastType = "toastSuccess";
            if (
              res.message["success"] != undefined &&
              res.message["failed"] != undefined &&
              res.message["having"] != undefined
            ) {
              if (res.message["failed"] || res.message["having"]) {
                if (res.message["failed"] == isCheck.length) {
                  msg =
                    "No se puede generar la(s) factura(s). Las órdenes deben estar aprobadas.";
                  toastType = "toastError";
                } else {
                  msg = `${res.message["success"]} exitoso(s), ${res.message["having"]} ya generado(s), ${res.message["failed"]} fallido(s)`;
                  // toastType = 'toastError';
                }
              }
            }
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({ type: toastType, payload: msg });
          })
          .catch((e) => {
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({
              type: "toastError",
              payload: "Ocurrió un error. Inténtelo de nuevo.",
            });
          });
      }
      if (
        action == "pending" ||
        action == "approved" ||
        action == "in_process" ||
        action == "rejected"
      ) {
        const data = {
          action: "status",
          value: action,
          order_ids: isCheck,
        };
        const formData = jsonToFormData(data, {
          arrayIndexes: true,
          excludeNull: true,
        });
        OrderService.bulkUpdate(formData).then((res: any) => {
          handleFilter({ ...filters, event_id });
        });
      }
    } else {
      toast.error("Selecione una Orden");
    }
  };

  const handleCleanRejectedOrders = async () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    await cleanOrders.mutateAsync().then((cleanedOrdersQty) => {
      let message = "No hay órdenes para liberar.";
      if (cleanedOrdersQty === 1) message = "Se liberó 1 orden.";
      if (cleanedOrdersQty > 1)
        message = `Se liberaron ${cleanedOrdersQty} órdenes`;

      dispatch({ type: "showLoaderScreen", payload: false });
      dispatch({ type: "toastSuccess", payload: message });
    });
  };

  return (
    <>
      <div className="flex justify-between">
        <Title>Órdenes</Title>
        {["admin", "producer"].includes(auth.role) && (
          <button
            className="flex items-center justify-center text-[#3E7EFF]"
            type="button"
            onClick={() => navigate("export")}
          >
            <GoGraph className="mr-1" />
            <strong>Exportar a CSV</strong>
          </button>
        )}
        <input
          type="text"
          placeholder="Search..."
          className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="flex w-full  justify-between overflow-auto border-b pt-10">
        <FilterSection
          filterSectionProps={filterSectionProps}
          handleFilter={handleFilterSection}
        />
        {auth.role === "admin" && (
          <button
            onClick={() => handleCleanRejectedOrders()}
            className="h-[30px] rounded-full border-[1px] border-black-100 px-[22px] text-sm font-bold text-black-100 hover:bg-black-100 hover:text-white"
          >
            Liberar órdenes
          </button>
        )}
      </div>
      <div className="mt-[24px] flex">
        <div className="flex items-center rounded-xl border border-colorBorder pl-[20px]">
          <p>Acciones en lote</p>
          <select
            id="filterPage"
            className="my-[10px] border border-transparent pl-[14px] font-sans text-[12px] font-[700] leading-[21px] text-[#171721] focus:border-none focus:shadow-none focus:outline-none focus:ring-transparent"
            value={action}
            onChange={(e) => handleChangeStatus(e.target.value)}
          >
            {options?.map((_, i) => (
              <option value={_.value ? _.value : _} key={i}>
                {_.text ? _.text : _}
              </option>
            ))}
          </select>
        </div>

        <div className="ml-[24px] flex items-center justify-center">
          <button
            onClick={() => handleAplyChanges()}
            className="h-[30px] rounded-full border-[1px] px-[22px] text-sm font-bold text-black-100 hover:bg-transparent"
          >
            Aplicar acción
          </button>
        </div>
      </div>
      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            <th className="border-b-[1px] border-colorBorder pb-4">
              <input
                id="checkbox-all"
                onClick={handleSelectAll}
                type="checkbox"
                checked={handleVerifyAll()}
                className="text-blue-600 focus:ring-blue-500 rounded border-gray-200"
              />
            </th>
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}

            <th className="border-b-[1px] border-colorBorder pb-4"></th>
          </tr>
        </thead>
        <tbody>
          {resultado.length ? (
            resultado.map((item, i) => (
              <motion.tr
                key={i}
                layout
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0.2 }}
                transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                className={`h-16  rounded-xl text-start text-sm font-medium outline outline-[1px] outline-colorBorder`}
              >
                <td className="pl-4">
                  <input
                    type="checkbox"
                    onClick={(e) => handleClick(e, item.id)}
                    checked={handleVerify(item.id)}
                    className="text-blue-600 focus:ring-blue-500 rounded border-gray-200"
                  />
                </td>
                <TableCellLink
                  href={getOrderDetailLink(item.id)}
                  className="cursor-pointer pl-4"
                >
                  #{item.id}
                </TableCellLink>
                <TableCellLink
                  href={getOrderDetailLink(item.id)}
                  className="cursor-pointer pl-4"
                >
                  {formatDate(item.updatedAt)}
                </TableCellLink>
                <TableCellLink
                  href={getOrderDetailLink(item.id)}
                  className="cursor-pointer pl-4"
                >
                  {item.seller[0]?.first_name
                    ? `${item.seller[0]?.first_name} ${item.seller[0].last_name}`
                    : "-"}
                  {item.seller[0]?.status == "deleted" && (
                    <small className="font-light"> (eliminado)</small>
                  )}
                </TableCellLink>
                <TableCellLink
                  href={getOrderDetailLink(item.id)}
                  className="cursor-pointer pl-4"
                >
                  {item.user.email ? item.user.email : "-"}
                </TableCellLink>
                <TableCellLink
                  href={getOrderDetailLink(item.id)}
                  className="cursor-pointer pl-4"
                >
                  {item?.order_tickets.reduce(
                    (total, currentValue) => total + currentValue.qty,
                    0
                  )}
                </TableCellLink>
                <TableCellLink
                  href={getOrderDetailLink(item.id)}
                  className="cursor-pointer pl-4"
                >
                  {formatMoney(item.total)}
                </TableCellLink>
                <TableCellLink
                  href={getOrderDetailLink(item.id)}
                  className="cursor-pointer pl-4"
                >
                  {" "}
                  {Array.from(
                    new Set(
                      item?.order_tickets.flatMap((e) =>
                        e.ticket.map((e) => e.type)
                      )
                    )
                  ).join("/")}
                </TableCellLink>
                <td className="pl-4">
                  <div className="flex items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder px-5  py-2 text-center ">
                    <div
                      className={`rounded-full ${
                        item.status === "approved" || item.status === "success"
                          ? "bg-green"
                          : item.status == "pending"
                          ? "bg-orange"
                          : "bg-red"
                      } p-[.35rem]`}
                    />
                    <p className="capitalize">
                      {getPaymentStatusFromValue(item.status)}
                    </p>
                  </div>
                </td>
                <td className="pl-4">
                  <DropdownDots
                    options={[
                      {
                        title: "Editar",
                        action: () => handleEdit(item.id),
                        icon: <FaEdit size={23} />,
                      },
                      {
                        title: "Enviar a la papelera",
                        action: () => handleSendRecycler(item.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                </td>
              </motion.tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
      {showLoader ? (
        <div className="flex items-center justify-center">
          <LoaderCircle />
        </div>
      ) : (
        result.rows.length == 0 && (
          <p className="text-center">No existen órdenes disponibles</p>
        )
      )}
      <div className="flex justify-between pt-10">
        <FilterPageWithLink
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          currentPage={page}
        />
        <FilterQtyPage handleFilter={handleFilter} />
      </div>
    </>
  );
};
