import { FC, Fragment } from "react"
import { FormInputCsv } from "../../../types/FormInputCsv"
import LoaderCircle from "../../FormElement/LoaderCircle";

export const ItemForLoad: FC<FormInputCsv> = ({completed, active_file_name, file_name, progress = 0, size_file, total, seller, new_mails, errors, loading }: any) => {

    return (
        <div className="w-[47rem] flex-col flex-wrap  overflow-auto  border-b px-10 pt-5 pb-5">
            <div className="flex w-full items-center justify-center gap-4">
                <div className="font-sans font-medium text-base">
                {file_name}

                {
                    completed ?
                    <Fragment>
                        <div className="font-sans font-light font-black text-xs">
                        TOTAL {total} registros / {seller} vendedores bombo, {new_mails} nuevos mails (no
                        registrados)
                        </div>
                        { errors && Array.isArray(errors) && !!errors.length &&
                        <div className="text-xs text-red">
                            <p className="small"><strong>Estos emails ya son líderes, y no pueden ser vendedores:</strong></p>
                            <p>{errors.join(',')}</p>
                        </div>
                        }
                        { errors && typeof errors == 'string' &&
                            <div className="text-xs text-red">
                                <p className="small"><strong>Error:</strong></p>
                                <p>{errors}</p>
                            </div>
                        }
                    </Fragment>
                    :
                    ''
                }

                </div>

                {

                    active_file_name == file_name ?

                    completed ?
                    <Fragment>
                        <div className="relative h-2.5 flex-grow rounded-full"></div>

                        <button
                        className="rounded-sm border-[1px] border-[#CDD3D8] px-4 py-1 font-bold text-black-100"
                        >
                        {size_file}KB
                        </button>
                    </Fragment>
                    :
                    <Fragment>
                        <div className="relative h-2.5 w-full flex-grow rounded-full bg-gray-200">

                        <div
                            className="h-2.5 rounded-full bg-black-100"
                            style={{ width: `${progress}%` }}
                        ></div>
                        </div>


                        <p className="font-medium">{progress}%</p>
                        {!!loading && <LoaderCircle width={32} height={32}/>}
                    </Fragment>

                    :

                    completed ?
                    <Fragment>
                        <div className="relative h-2.5 flex-grow rounded-full"></div>

                        <button
                        className="rounded-sm border-[1px] border-[#CDD3D8] px-4 py-1 font-bold text-black-100"
                        >
                        {size_file}KB
                        </button>
                    </Fragment>
                    :
                    ''

                }


            </div>
        </div>
    )
}
