import React, {ChangeEvent, Fragment, useContext, useEffect, useState} from "react";
import { Title } from "../../../events/Title";
import {useMutation} from "@tanstack/react-query";
import UserService from "../../../../services/userService";
import { StateContext } from "../../../../context/StateProvider";
import CropUpload from "../../../CropUpload";
import { blobToFile, generateImageDefaultFromUrlYoutube } from "../../../../utils/form";
import jsonToFormData from "@ajoelp/json-to-formdata";
import EventService from "../../../../services/eventService";
import { IEventPictureResponse } from "../../../../types/IEventPicture";
import ReactPlayer from "react-player";
import DropdownDots from "../../../FormElement/DropdownDots";
import { ModalLink } from "./ModalLink"
type multimediaProps = {
    eventId: string;
    loadEvent?: () => void;
}

export const Multimedia = ({eventId, loadEvent = ()=>{}}: multimediaProps) => {
    const { state, dispatch } = useContext(StateContext);
    const[result, setResult] = useState<IEventPictureResponse[]>([]);
    const [showCropper, setShowCropper] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [showModalLink, setShowModalLink] = useState(false);
    const [link, setLink] = useState("");
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const createItem = useMutation(
        (item: FormData) => EventService.uploadFile(item),
    );

    const getItems = useMutation(
        (params: any) => UserService.getPictures(params),
    );

    const deleteItems = useMutation((id: string) => EventService.removePicture(id));

    useEffect(() => {
        load();
    },[]);

    const load = async() => {
        await getItems.mutateAsync({event_id: eventId})
        .then((res: any)=>{
            console.log("Res ", res);
            setResult(res)
            dispatch({ type: "showLoaderScreen", payload: false });
        })
        .catch((c:any)=>dispatch({ type: "showLoaderScreen", payload: false }))
    }

    const handleClick = () => {
        hiddenFileInput.current?.click();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.currentTarget.files;
        if(fileUploaded){
            const file= fileUploaded[0];
            if((file.type).includes("image")){
                console.log("imagen ");
                setFile(URL.createObjectURL(file));
                setShowCropper(true);
            }else if((file.type).includes("video")){
                console.log('video');
                uploadPicture(file);
            }else{
                alert('Solo se permite archivo tipo imagen');
            }
        }
    };

    const handleCloseCrop = () => {
        setShowCropper(false);
    }

    const handleSuccess = (theBlob: Blob) => {
        handleCloseCrop();
        const file = blobToFile(theBlob);
        uploadPicture(file);
    }

    const uploadPicture = async(file: File) => {
        const req = {
            picture : file,
            event_id: eventId,
            media_type: file.type
        }
        let formData = jsonToFormData(req, {
            arrayIndexes: true,
            excludeNull: true
        });
        dispatch({ type: "showLoaderScreen", payload: true });
        createItem.mutateAsync(formData).then((res: any) => {
            load();
            loadEvent();
            dispatch({ type: "toastSuccess", payload: "Archivo subido correctamente" });
        })
          .catch((reason: any)=>{
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({ type: "toastError", payload: "El archivo no pudo subirse correctamente" });
          });
    }

    const handleDelete = (id: string) => {
        if (window.confirm('Está seguro de eliminar este archivo?')) {
            dispatch({ type: "showLoaderScreen", payload: true });
            deleteItems.mutateAsync(id).then((r: any)=>{
                load();
                loadEvent();
                dispatch({ type: "toastSuccess", payload: "Archivo eliminado correctamente" });
            });
        }
    }

    const handleCloseModalLink = () => {
        setShowModalLink(false);
    }

    const handleUploadLink = (link: string) => {
        setShowModalLink(false);
        if(link){
            const req = {
                picture : link,
                event_id: eventId,
                media_type: "link"
            }
            let formData = jsonToFormData(req, {
                arrayIndexes: true,
                excludeNull: true
            });
            dispatch({ type: "showLoaderScreen", payload: true });
            createItem.mutateAsync(formData).then((res: any) => {
                load();
                loadEvent();
                dispatch({ type: "toastSuccess", payload: "Archivo subido correctamente" });
            })
            .catch((reason: any)=>{
                dispatch({ type: "showLoaderScreen", payload: false });
                dispatch({ type: "toastError", payload: "El archivo no pudo subirse correctamente" });
            });
        }else{
            dispatch({ type: "toastError", payload: "El link esta vacio" });
        }
    }

  return (
    <div>
        { showModalLink && <ModalLink handleCloseModal={handleCloseModalLink} handleUpload={handleUploadLink}/>}
        { showCropper &&
            <CropUpload
              image={file}
              initialAspectRatio={3 / 4}
              cropBoxResizable={false}
              closeModal={handleCloseCrop}
              onSuccess={handleSuccess} 
              dragMode={'crop'}/>
        }
        <div className="flex justify-between">
            <Title>Multimedia</Title>
            <DropdownDots
                children={
                    <button className="rounded-full border-[2px] border-black-100 px-10 py-2 font-bold  text-black-100 hover:bg-black-100 hover:text-white">
                        Subir archivo
                    </button>
                }
                options={[
                    // {title: 'imagen/video', action: ()=>handleClick(), icon: (<div />)},
                    {title: 'link de youtube', action: ()=>{setShowModalLink(true)}, icon: (<div />)},
                ]}
            />
            <input type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                className="hidden"
            />
        </div>
      <div className="flex w-full flex-wrap gap-5 pt-10">
        {result.map((_, i) =>
            <div
              key={i}
              className="relative w-36 h-56 rounded-xl"
            >
                <div className="cursor-pointer flex items-center justify-center py-2 font-bold bg-black-100 text-white  hover:bg-white hover:text-black-100 hover:border-[2px] hover:border-black-100" onClick={()=>handleDelete(_.id)}>
                    Eliminar
                </div>
                {
                    _.media_type.includes('image') ?
                    <div
                        className="w-36 h-full justify-center bg-gray-200"
                        style={{ backgroundImage: `url(${_.picture_url || 'inherit' })`, backgroundSize: "cover" , backgroundPosition: "top center", backgroundRepeat: "no-repeat" }}
                    >
                    </div> :
                    _.media_type.includes('video') ?
                    <ReactPlayer
                        url={_.picture_url}
                        height="100%"
                        width="100%"
                        controls
                        muted
                        playing
                        onEnded={()=>console.log('Se termino el video')}
                    /> :
                    _.media_type.includes('link') ? 
                    (  
                        <div
                            className="flex w-36 h-full justify-center items-center bg-gray-200"
                            style={{ backgroundImage: `url(${generateImageDefaultFromUrlYoutube(_.picture_url) || 'https://img.youtube.com/vi/%3Cyoutube-video-id%3E/mqdefault.jpg' })`, backgroundSize: "cover" , backgroundPosition: "top center", backgroundRepeat: "no-repeat", cursor: 'pointer' }}
                            onClick={()=>{window.open(String(_.picture_url))}}
                        >
                            <p className="text-2xl font-medium text-gray-100 text-center">link</p>
                        </div>
                    )
                     : <Fragment/>
                }
            </div>
        )}
      </div>
    </div>
  );
};
