import React, { FC } from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { FormInputProps } from '../../../types/FormInputsProps';

const styleInput = {
    input:
        "h-24 w-full rounded-lg border border-colorBorder pt-12 pl-8 font-bold text-black-100 focus:border-none focus:outline-none focus:ring-colorBorder",
};

const FormInput: FC<FormInputProps> = ({ name, label, placeholder, disabled = false, min = '', max = '', readonly = "", accept }) => {
    const formState = useFormState();

    return (
        <Controller
            name={name}
            render={({ field }) => (
                <div className="relative w-full mb-6">
                    <p className="absolute left-8 top-4 font-semibold text-gray-100">
                        {label}
                    </p>
                    <input
                        type="file"
                        className={styleInput.input}
                        id={`upload_${name}`}
                        name={name}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        onChange={(e: React.SyntheticEvent<EventTarget>) => {
                            const target = e.target as HTMLInputElement;
                            if (!target.files) return;
                            const newFile = Object.values(target.files).map((file: File) => file);
                            field.onChange(newFile[0]);
                        }}
                        accept={accept ? accept : ''}
                    />

                    {formState.errors[name] &&
                        <p className="mt-2 text-sm text-red text-red-600 dark:text-red-500">
                            {String(formState.errors[name] ? formState.errors[name]?.message : '')}
                        </p>
                    }
                    { field.value && typeof field.value == 'string' && new RegExp('^(https?|ftp)://').test(field.value) &&
                        <a href={field.value} target="_blank" className="text-gray-100 pr-6 text-[#3b82f6]">{field.value}</a>
                    }
                </div>
            )}
        />
    );
};

export default FormInput;
