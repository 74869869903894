import React, { useEffect, useState, useContext } from "react";
import { Title } from "../Title";
import { useMutation } from "@tanstack/react-query";
import { ITicketsResponse } from "../../../types/ITicket";
import TicketService from "../../../services/ticketService";
import FormInput from "../../FormElement/FormInput";
import { useForm, FormProvider } from "react-hook-form";
import { ISendInvitationRequest, ISendTicketsLogs } from "../../../types/IEvent";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import FormSelectFull from "../../FormElement/FormSelectFull";
import circleCheck from "../../../assets/circle-check.png";
import EventService from "../../../services/eventService";
import { StateContext } from "../../../context/StateProvider";
import OrderService from "../../../services/orderService";
import { motion } from "framer-motion";
import { AuthContext } from "../../../context/AuthContext";
import { formatDateTime } from "../../../utils/format-date";
import { FilterPage } from "../../table/FilterPage";
import { FilterQtyPage } from "../../table/FilterQtyPage";
import { IFilterRequest } from "../../../types/IFilter";
import {getFullNameOrEmail} from "../../../utils/form";
import LoaderCircle from "../../FormElement/LoaderCircle";
import {FaTrashAlt} from "react-icons/fa";
import DropdownDots from "../../FormElement/DropdownDots";
import config from "../../../config/variables";

type SendTicketsProps = {
  eventId: string;
};

const schema = yup.object().shape({
  emails: yup.array().required("Campo requerido"),
  tickets: yup.array().min(1, "Campo requerido"),
  qty: yup.array().min(1, "Campo requerido"),
});

const defaultValues = {
  emails: "",
  tickets: "",
  qty: "",
};

const defaultFiltersLogs = {
  to: 'user',
  limit: config.filter_default_limit,
  page: 1,
  status: '',
  sortBy: 'createdAt',
  sortOrder: 'desc',
}

export const SendInvitationsEvent = ({ eventId }: SendTicketsProps) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const auth = useContext(AuthContext);
  const [groupFields, setGroupFields] = useState(1);
  const [filtersLogs, setFiltersLogs] = useState<IFilterRequest>({ ...defaultFiltersLogs, event_id: eventId });
  const [tickets, setTickets] = useState<ITicketsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });

  const [orders, setOrders] = useState({
    rows: [],
    total: 0,
    pages: 0,
  });

  const [logs, setLogs] = useState<ISendTicketsLogs>({
    rows: [],
    total: 0,
    pages: 0,
  });
  const [emailOrigin, setEmailOrigin] = useState<any>("");
  const [emailDestination, setEmailDestination] = useState<any>("");
  const [searchLogs, setSearchLogs] = useState("");
  const [loadingLogs, setLoadingLogs] = useState(false);

  const { dispatch } = useContext(StateContext);

  const getTickets = useMutation(() =>
    TicketService.getAll({ event_id: eventId, type: "Free" })
  );

  const getOrders = useMutation(() =>
    OrderService.getAllORdersInvitation({ event_id: eventId })
  );

  const sendInvitations = useMutation((item: FormData) =>
    EventService.sendInvitationsToSeller(item)
  );

  const getLogs = useMutation(
    (filters: IFilterRequest) => EventService.getSendTicketsLogs(filters)
  );

  const removeLogs = useMutation(
      (logId: string) => EventService.removeSendTicketsLogs(logId)
  );

  useEffect(() => {
    getTickets.mutateAsync().then((res) => {
      setTickets(res)
      console.log(res);
    });
    loadOrders();
    getLogs.mutateAsync(filtersLogs).then((res) => setLogs(res));


  }, []);

  const loadOrders = () => {
    getOrders.mutateAsync().then((res) => setOrders(res));
  }

  const form = useForm<ISendInvitationRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (data: ISendInvitationRequest) => {
    const validData = getValidData(data);
    if (validData == null) return;
    console.log("valid data ", validData);
    dispatch({ type: "showLoaderScreen", payload: true });
    let formData = jsonToFormData(validData, {
      arrayIndexes: true,
      excludeNull: true,
    });
    sendInvitations
      .mutateAsync(formData)
      .then(async (res) => {
        form.reset({
          full_names: ["", "", "", "", ""],
          emails: ["", "", "", "", ""],
          tickets: ["", "", "", "", ""],
          qty: ["", "", "", "", ""],
        });
        setShowSuccess(!showSuccess);
        dispatch({ type: "showLoaderScreen", payload: false });
      })
      .catch((reason) => {
        if (Array.isArray(reason.response.data.message)) {
          for (let message of reason.response.data.message) {
            dispatch({ type: "toastError", payload: message });
          }
        } else {
          dispatch({
            type: "toastError",
            payload: reason.response.data.message,
          });
        }
        dispatch({ type: "showLoaderScreen", payload: false });
      });
  };

  const getValidData = (data: ISendInvitationRequest) => {
    const newData: any = { full_names: [], emails: [], tickets: [], qty: [] };
    for (let i = 0; i < groupFields; i++) {
      if (
        data.full_names[i] &&
        data.emails[i] &&
        data.tickets[i] &&
        data.qty[i]
      ) {
        newData.full_names.push(data.full_names[i]);
        newData.emails.push(data.emails[i]);
        newData.tickets.push(data.tickets[i]);
        newData.qty.push(data.qty[i]);
      } else if (
        !data.full_names[i] &&
        !data.emails[i] &&
        !data.tickets[i] &&
        !data.qty[i]
      ) {
      } else {
        if (!data.full_names[i]) {
          dispatch({
            type: "toastError",
            payload: `En la fila ${i + 1} el nombre es requerido.`,
          });
        }
        if (!data.emails[i]) {
          dispatch({
            type: "toastError",
            payload: `En la fila ${i + 1} el email es requerido.`,
          });
        }
        if (!data.tickets[i]) {
          dispatch({
            type: "toastError",
            payload: `En la fila ${i + 1} seleccione un ticket.`,
          });
        }
        if (!data.qty[i]) {
          dispatch({
            type: "toastError",
            payload: `En la fila ${i + 1} la cantidad es requerida.`,
          });
        }

        return null;
      }
    }
    if (
      newData.emails.length == 0 &&
      newData.tickets.length == 0 &&
      newData.qty.length == 0
    )
      return null;
    return newData;
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const handleAddGroupFields = () => {
    if (groupFields == 5) return;
    setGroupFields(groupFields + 1);
  };

  const reSendTicket = (email: string, id: any) => {
    OrderService.updateOrderTicketSend(id, { email: email }).then(
      (res) => {
        console.log(res);

        loadOrders()
        dispatch({
          type: "toastSuccess",
          payload: `ticket re enviado correctamente`,
        });
      }
    );
  };

  const handleReSend = () => {
    console.log(eventId);

    OrderService.updateOrderTicketSendGroup({ email_origin: emailOrigin, email_destination: emailDestination, event_id: eventId }).then(
      (res) => {
        console.log(res);

        loadOrders()
        dispatch({
          type: "toastSuccess",
          payload: `ticket re enviado correctamente`,
        });
      }
    );
  };

  const columnData = ["Líder", "Email", "Fecha de envío", "Ticket", "Cantidad de tickets", "Acciones"];

  const handleFilterLogs = async (filter: any) => {
    const newFilters = { ...filtersLogs, ...filter }
    await setFiltersLogs(newFilters);
    await getLogs.mutateAsync(newFilters).then(res => {
      setLogs(res)
    });
  }

  useEffect(() => {
    if (searchLogs != ""){
      const delayDebounceFn = setTimeout(() => {
        loadDataLogs();
      }, 600)
      return () => clearTimeout(delayDebounceFn)
    }
    else {
      loadDataLogs();
    }
  }, [searchLogs])

  const loadDataLogs = () => {
    setLoadingLogs(true);
    getLogs.mutateAsync(filtersLogs).then(res => {
      setLogs(res)
      setLoadingLogs(false);
    });
  }

  const searcherLogs = async (e: any) => {
    setSearchLogs(e.target.value)
    const paramsFilters = { ...filtersLogs,  page: 1, query: e.target.value };
    await setFiltersLogs(paramsFilters);
  }

  const handleDeleteLog = (id: string) => {
    if (confirm('Está seguro de eliminar este ítem?')) {
      removeLogs.mutateAsync(id).then((res) => {
        getLogs.mutateAsync(filtersLogs).then(res => {
          setLogs(res)
          setLoadingLogs(false);
        });
      });
    }
  }

  const compareDate = (date: string) => {
    const dateAdd = new Date(date);
    const today = new Date();
    if (today < dateAdd) {
      return true
    }
    else {
      return false
    }
  };

  return (
    <div>
      {showSuccess ? (
        <div className="flex flex-col gap-10 xl:px-[23rem]">
          <div>
            <img
              src={circleCheck}
              alt="check"
              className="m-auto mb-4 text-center"
            />
            <h1 className="mb-4 text-center text-[20px] font-bold">
              Felicitaciones
            </h1>
            <p className="text-center">Tickets enviados exitosamente</p>
          </div>

          <div className="mb-16 flex justify-center gap-10 pt-10">
            <button
              onClick={() => {
                setGroupFields(1);
                setShowSuccess(!showSuccess);
                loadOrders();
                handleFilterLogs({});
              }}
              className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium text-white"
            >
              Enviar mas tickets
            </button>
          </div>
        </div>
      ) : (
        <>
          <div>
            <div>
              <Title>Envío de invitaciones</Title>
              <div className="xl:px-96- flex  flex-col justify-center gap-10 pt-12">
                <FormProvider {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit, onError)}
                    className="flex flex-col gap-10 pb-20"
                  >
                    {[...Array(groupFields)].map((row, i) => (
                      <div className="grid grid-cols-4 gap-2 xl:gap-10" key={i}>
                        <div className="">
                          <FormInput
                            name={`full_names[${i}]`}
                            label="Nombre completo"
                          />
                        </div>
                        <div className="">
                          <FormInput
                            type="email"
                            name={`emails[${i}]`}
                            label="Email"
                          />
                        </div>
                        <div className="">
                          <FormSelectFull
                            name={`tickets[${i}]`}
                            options={[
                              {
                                value: "",
                                text:

                                    tickets.rows.length > 0 && compareDate(tickets.rows[0]?.event[0]?.date_end)
                                      ? "Seleccione Ticket"
                                      :
                                      compareDate(tickets.rows[0]?.event[0]?.date_end) ?
                                      "Aún no existen tickets free"
                                      :
                                      "Evento expirado"

                              },
                            ].concat(
                              compareDate(tickets?.rows[0]?.event[0]?.date_end) ?
                                tickets.rows.map((c) => {
                                  return { value: c.id, text: c.name };
                                })
                                :
                                []
                            )}
                            label="Tipo de ticket"
                          />


                        </div>
                        <div className="">
                          <FormInput
                            type="number"
                            name={`qty[${i}]`}
                            label="Cantidad de tickets a enviar"
                            min="1"
                          />
                        </div>
                      </div>
                    ))}
                    {groupFields < 5 && (
                      <div>
                        <button
                          onClick={handleAddGroupFields}
                          type="button"
                          className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
                        >
                          + Agregar más
                        </button>
                      </div>
                    )}
                    <button className="m-auto mt-10 w-60 rounded-full border-[2px] border-black-100 bg-black-100 px-10 py-2  font-bold text-white ">
                      Enviar tickets
                    </button>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
          {/* <div>
            <div>
              <div>
                <Title>Re-enviar Ticket</Title>
                <div className="flex justify-between mt-5 mb-5">
                  <div className="relative w-full flex cursor-pointer justify-center items-center mr-8">
                    <p className="absolute left-8 top-4 font-semibold text-gray-100">
                      Email origen
                    </p>
                    <input
                      className="h-24 w-full rounded-lg border border-colorBorder pt-6 pl-6 font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder"
                      type="text"
                      name="email_origin "
                      value={emailOrigin}
                      onChange={(e) => setEmailOrigin(e.target.value)}
                    />
                  </div>
                  <div className="relative w-full flex cursor-pointer justify-center items-center ml-6">
                    <p className="absolute left-8 top-4 font-semibold text-gray-100">
                      Email destino
                    </p>
                    <input
                      className="h-24 w-full rounded-lg border border-colorBorder pt-6 pl-6  font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder"
                      name="email_destination"
                      value={emailDestination}
                      onChange={(e) => setEmailDestination(e.target.value)}
                    />
                  </div>

                  <div className="w-full flex h-[80px] items-center justify-center">
                    <button className="m-auto w-60 rounded-full border-[2px] border-black-100 bg-black-100 px-10 py-2  font-bold text-white " type="submit" onClick={() => handleReSend()}>
                      re-enviar
                    </button>
                  </div>
                </div>
              </div>
              <div>
                {
                  orders.rows.length != 0 ?
                    <Title>Tickets enviados</Title>
                    : <div></div>
                }

                {
                  orders?.rows.map((_, i) => (
                    _.email ?
                      <InputsEmailResend _={_} key={_.id} onSubmit={reSendTicket} />
                      :
                      <div></div>
                  ))
                }
              </div>
            </div>
          </div> */}

          <div>
            <Title>Historial</Title>
            <div className="flex mt-2">
              {!!tickets.rows && (
                  <select
                      id="filterPage"
                      className="w-[350px] cursor-pointer rounded-lg py-3 pl-4 pr-8 font-medium text-gray-100 outline outline-1 outline-colorBorder"
                      onChange={searcherLogs}
                  >
                    <option value="">Seleccione un ticket</option>
                    {tickets.rows.map((_, i) => (
                        <option value={_.id} key={i}>
                          {_.name}
                        </option>
                    ))}
                  </select>
              )}
              {loadingLogs && <LoaderCircle width={28} height={28}/>}
            </div>
            <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
              <thead className=" divide-solid border ">
                <tr className=" pb-4 text-sm font-bold text-gray-100">
                  {columnData.map((column, index) => (
                    <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {logs.rows.map((_, i) => (
                  <motion.tr
                    key={i}
                    layout
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0.2 }}
                    transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                    className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                  >
                    <td className="pl-4 font-bold capitalize">
                      {_.user ? getFullNameOrEmail(_.user) : _.email}
                      {_.user?.status == 'deleted' && <small className="font-light"> (eliminado)</small>}
                    </td>
                    <td className="capitalize">{ _.email }</td>
                    <td className="capitalize">{formatDateTime(_.date_send)}</td>
                    <td className="flex h-full items-center gap-2 capitalize">
                      {_.ticket.name}
                    </td>
                    <td className="">{_.qty} ticket(s)</td>
                    <td className="">
                      <DropdownDots options={[
                          { title: 'Eliminar', action: () => handleDeleteLog(_.id), icon: (<FaTrashAlt size={23} />) }
                      ]} />
                    </td>
                  </motion.tr>
                ))}
                {!!logs.rows.length &&
                    <motion.tr
                        key={'total'}
                        layout
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        initial={{ opacity: 0.2 }}
                        transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                        className={`h-16 rounded-xl text-start font-extrabold`}
                    >
                      <td className="font-bold text-right pr-4" colSpan={3}>TOTAL: </td>
                      <td className="">{logs.rows[0].total} ticket(s)</td>
                    </motion.tr>
                }
              </tbody>
            </table>
            {logs.rows.length === 0 ? <p className="text-center">No existen registros disponibles</p> : null}
            <div className="flex justify-between pt-10">
              <FilterPage handleFilter={handleFilterLogs} total={logs.total} limit={filtersLogs.limit} currentPage={filtersLogs.page} />
              <FilterQtyPage handleFilter={handleFilterLogs} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export function InputsEmailResend({ _, onSubmit = (e: any, data: any) => { } }) {
  // one state per entry
  const [tag, setTag] = useState<string>(_.email)

  return (
    <div key={_.id}>
      {/* <form onSubmit={() => onSubmit(tag, _.id)}> */}
      <div className="flex justify-between mr-6">
        <div className="flex cursor-pointer h-[80px] justify-center items-center">
          <input
            className="w-full rounded-lg border border-none text-start font-bold text-black-100 focus:border-none focus:outline-none  focus:ring-colorBorder"
            type="text"
            name="email"
            placeholder="Email"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
        </div>
        <div className="flex text-[#7B7B7B] h-[80px] justify-center items-center">{_.ticket[0].name}</div>
        <div className="flex text-[#7B7B7B] h-[80px] justify-center items-center">{_.qty}</div>
        <div className="flex text-[#7B7B7B] h-[80px] justify-center items-center">{_.ticket[0].type}</div>
        {/* <div className="flex h-[80px] items-center justify-center">
          <button className="m-auto w-60 rounded-full border-[2px] border-black-100 bg-black-100 px-10 py-2  font-bold text-white " type="submit" onClick={() => onSubmit(tag, _.id)}>
            re-enviar
          </button>
        </div> */}
      </div>
      {/* </form> */}
    </div >
  )
}
