import { Footer } from "../footer/Footer"
import logo from "../../assets/logo.svg";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import AuthService from "../../services/authService";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { StateContext } from "../../context/StateProvider";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const TwoFactorAuthValidationCodeView = () => {
    const auth = useContext(AuthContext);
    const [otp, setOtp] = useState<string>('');
    const navigate = useNavigate();
    const { dispatch } = useContext(StateContext);

    const getQRCode = useMutation(
        () => AuthService.getQRCode(auth.user.id)
    );

    useEffect(() => {
        if (auth.user.id === '') {
            navigate("/")
            return
        }
    }, [])

    const handleSubmit = () => {
        auth.login({
            user_id: auth.user.id,
            validation_code: otp
        })
            .then(res => {
                res.permitted_producers ? dispatch({ type: 'setPermittedProducers', payload: res.permitted_producers }) : dispatch({ type: 'setPermittedProducers', payload: [] });

                res.user_role == "admin" ? navigate("/admin/events") : navigate("/client/producers");

                if ((res.user_role === "producer" || res.user_role === "coproducer") && res.first_time_login === true) {
                    navigate("/client/change_password");
                } else {
                    navigate("/client/producers");
                }
            })
            .catch((e) => {
                let message = 'Código 2FA inválido';
                toast.error(message);
            });
    };

    return (
        <main className="flex h-screen flex-col items-center justify-between bg-white">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="flex h-full flex-col items-center justify-center gap-4">
                <picture className="w-48">
                    <img className="" width="100%" src={logo} />
                </picture>
                <h1 className="pt-16 text-2xl font-medium">Validación de 2FA</h1>
                <h2 className="pt-8 text-xl font-bold">Ingresá los 6 dígitos que se muestran en tu app de autenticación!</h2>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} style={{ color: 'black', borderColor: 'black', width: '35px' }} />}
                />
                <button onClick={() => handleSubmit()} disabled={otp.length < 6} className={`${otp.length === 6 ? 'bg-black-100' : 'bg-gray-100'} select-none rounded-full  py-3 px-36 mt-8 font-medium text-white`}>
                    Ingresar
                </button>
            </div>
            <Footer />
        </main>
    )
}