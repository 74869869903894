const types = [
    {value: 'all', label: 'Todos'},
    {value: 'fiat', label: 'Fiat'},
    {value: 'crypto', label: 'Crypto'},
]

const paymentMethodsMP = [
    {value: 'argencard', label: 'Argencard'},
    {value: 'sol', label: 'Sol'},
    {value: 'sucredito', label: 'Sucredito'},
    {value: 'visa', label: 'Visa'},
    {value: 'master', label: 'Mastercard'},
    {value: 'naranja', label: 'Naranja'},
    {value: 'tarshop', label: 'Tarjeta Shopping'},
    {value: 'maestro', label: 'Maestro'},
    {value: 'debin_transfer', label: 'DEBIN'},
    {value: 'amex', label: 'American Express'},
    {value: 'debmaster', label: 'Mastercard Débito'},
    {value: 'debcabal', label: 'Cabal Débito'},
    {value: 'debvisa', label: 'Visa Débito'},
    {value: 'cmr', label: 'CMR'},
    {value: 'cabal', label: 'Cabal'},
    {value: 'cencosud', label: 'Cencosud'},
    {value: 'diners', label: 'Diners'},
    {value: 'account_money', label: 'Efectivo'},
]

export function getPaymentTypeFromValue(status: string) {
    const result = types.find(i => i.value === status);
    return result ? result.label : status;
}

export function getPaymentMethodMPFromValue(status: string) {
    const result = paymentMethodsMP.find(i => i.value === status);
    return result ? result.label : status;
}
