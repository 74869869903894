import React, { FC } from "react";
import { Status } from "../Status";
import { boolean } from "yup";
import { IClientResponse } from "../../types/IClient";
import img from "../../assets/profile-default.png";
import { formatDate } from "../../utils/format-date";
const styles = {
  titleLi: "font-bold pb-1 text-lg",
  pLi: "text-gray-100",
  wrapperLi: "flex justify-between gap-28 ",
};

type typeProps = {
  data: IClientResponse;
}

export const BannerClient: FC<typeProps> = ({ data }) => {
  return (
    <div className="flex h-[10rem] w-full gap-80   overflow-auto rounded-xl px-5 py-5 outline outline-2 outline-colorBorder">
      <div className="flex w-80 xl:gap-5 ">
        {/* <img className=" flex self-start overflow-hidden rounded-full bg-[#D6D6D6] p-10" /> */}
        <img
          src={data.profile_picture ? data.profile_picture : img}
          className="w-[64px] h-[64px] rounded-full bg-[#D6D6D6] object-cover"
          alt=""
        />
        <div className="flex flex-col ">
          <h1 className="text-2xl font-bold">{`${data.first_name} ${data.last_name}`}</h1>
          <p className="font-medium text-gray-100">Id del cliente: {data.id}</p>
        </div>
      </div>

      <ul className="flex    ">
        <div className={`${styles.wrapperLi} pb-3`}>
          <li>
            <h3 className={`${styles.titleLi}`}>Fecha de alta</h3>
            { data.createdAt && <p className={`${styles.pLi}`}>{formatDate(data.createdAt)}</p> }
          </li>
          <li>
            <h3 className={`${styles.titleLi}`}>Email</h3>
            <p className={`${styles.pLi}`}>{data.email}</p>
          </li>
          <li>
            <h3 className={`${styles.titleLi}`}>Reward</h3>
            <p className={`${styles.pLi}`}>150 puntos</p>
          </li>

          <div className=" flex items-center ">
            <Status status={"activo"} />
          </div>
        </div>
      </ul>
    </div>
  );
};
