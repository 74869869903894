import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { IEventResponse } from "../../../types/IEvent";
import { INewResponse } from "../../../types/INews";
import { AnyCnameRecord } from "dns";

type EventSectionProps = {
    order: number;
    data: IEventResponse | INewResponse;
    handleRemoveId: (id: string) => void;
    isDrawing: boolean;
    isNews?: boolean;
}

export const EventSection = ({data, order, handleRemoveId, isDrawing, isNews}: EventSectionProps) => {

    return (
        <div className={`flex justify-between w-[100%] mx-auto rounded-lg border border-colorBorder py-2 px-4 cursor-move ${isDrawing ? 'bg-gray-200': ''}`}>
          <div className="flex items-center gap-5">
            {
              data ? 
              <img src={isNews ? data.picture : data.promo_image} className="h-16 w-16 rounded-full object-cover" alt=""/> :
              <div className="h-16 w-16 overflow-hidden rounded-full bg-gray-200"></div>
            }
            <div>
              <h3 className="text-lg font-medium">{data ? isNews ? data.title : data.name : ''}</h3>
              <p className="text-red text-lg font-medium">orden: {order+1}</p>
            </div>
          </div>
          <div className="mt-2">
            <button type="button" onClick={()=>handleRemoveId(data.id)}>
              <AiFillCloseCircle size={23} />
            </button>
          </div>
        </div>
    );
}