import api from "./api";
import authHeader from "./authHeader";
import { IFeedbackResponse } from "../types/IFeedback";
import { IPaginationResponse } from "../types/IPagination";

const getAll = async (params: object = {}) => {
  const response = await api().get<IPaginationResponse>(`feedbacks/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<IFeedbackResponse>(
    `feedbacks/detail/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<IFeedbackResponse>(
    `feedbacks/create`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<IFeedbackResponse>(
    `feedbacks/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<IFeedbackResponse>(
    `feedbacks/delete/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const getCsv = async (params: object = {}) => {
  const response = await api().get<any>(`feedbacks/csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const FeedbackService = {
  getAll,
  get,
  create,
  update,
  remove,
  getCsv,
};

export default FeedbackService;
