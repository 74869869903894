import React, { useState, useRef, FC } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import useComponentVisible from "../../../hooks/use-component-visible.hook";
import { Props } from "@headlessui/react/dist/types";
import Restricted from "../../../context/Restricted";

type DropdownDotsItem = {
    title?: string;
    description?: string;
    href?: string;
    action?: (event: React.MouseEvent<HTMLElement>) => void;
    icon?: any;
    restricted?: string;
}
type DropdownDotsProps = {
    options: Array<DropdownDotsItem>;
    children?: React.ReactNode;
}

export default function DropdownDots({ options = [], children }: DropdownDotsProps) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    return (
        <div className="inline-flex z-[100]" ref={ref} >
            <div className="relative">
                <button className="text-gray-100 flex" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                    {children ?
                        children
                        :
                        <BiDotsHorizontalRounded size={23} />
                    }
                </button>

                {isComponentVisible ?
                    <div className={`absolute right-0 z-10 w-[207px] origin-top-right bg-white border border-gray-50 rounded-md shadow-lg mt-1`}>
                        <div className="p-2">
                            {
                                options.map((item, index) => (
                                    item.title ?
                                    <Restricted to={item.restricted || 'any'} key={index}>
                                        <button
                                            onClick={item.action}
                                            className="block w-full px-4 py-2 text-sm text-black-100 rounded-lg hover:bg-gray-100 hover:bg-opacity-20 hover:text-black-700"
                                        >
                                            <div className="flex items-center" onClick={() => setIsComponentVisible(false)}>
                                                {item.icon}
                                                <div>
                                                    <p className="w-max">{item.title && <strong> {item.title}</strong>}</p>
                                                    <p className="text-[10px] font-normal text-left">{item.description}</p>
                                                </div>
                                            </div>
                                        </button>
                                    </Restricted>
                                    :
                                    <div></div>
                                ))
                            }
                        </div>
                    </div>
                    :
                    <div></div>
                }
            </div>
        </div>
    );
}
