import {
  IArtistResponse,
  IArtistReviewsResponse,
  IArtistsResponse,
} from "../types/IArtist";
import { IFilterRequest } from "../types/IFilter";
import api, { staticApi } from "./api";
import authHeader from "./authHeader";

const getAll = async (params: IFilterRequest) => {
  console.log(params);

  const response = await staticApi.get<IArtistsResponse>(`artists/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<IArtistResponse>(`artists/detail/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<IArtistResponse>(`artists/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<IArtistResponse>(
    `artists/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<IArtistResponse>(`artists/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const deleteAccount = async (id: string) => {
  const response = await api().post<IArtistResponse>(
    `artists/delete_account/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const getCsv = async (params: object = {}) => {
  const response = await api().get<any>(`artists/csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getReviews = async (params: IFilterRequest) => {
  const response = await api().get<IArtistReviewsResponse>(
    `artists/reviews/${params.id}`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const ArtistService = {
  getAll,
  get,
  create,
  update,
  remove,
  deleteAccount,
  getCsv,
  getReviews,
};

export default ArtistService;
