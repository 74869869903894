import React from "react";

export const Events = ({ name = "Marcos Foglia", value = '', handleOnChange = (e: React.ChangeEvent<HTMLInputElement>)=>{}, isSelected = false}) => {
  return (
    <label htmlFor={name} className="w-[20rem]">
      <div className="flex w-full items-center gap-5 rounded-2xl border border-colorBorder py-5 pl-4 font-medium mt-1 mb-1">
        <input
          type="checkbox"
          name="events[]"
          id={name}
          value={value}
          checked={isSelected}
          onChange={handleOnChange}
          className="rounded-full p-2 checked:bg-black-100   focus:ring-transparent active:bg-black-100"
        />
        {name}
      </div>
    </label>
  );
};
