import { useContext } from "react";

import { ReportsEvent } from "../events/selectedEvent/ReportsEvent";
import { AuthContext } from "../../context/AuthContext";

export const ReportsView = () => {
  const auth = useContext(AuthContext);

  return (
    <div className=" grid w-full lg:grid-cols-12">
      <div className="col-span-12">
        <ReportsEvent producer_id={auth? auth.producer?.id : undefined} />
      </div>
    </div>
  );
};

