import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from '../../../types/FormInputsProps';
import {Color, ColorPicker, useColor, toColor} from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

const FormSwitch: FC<FormInputProps> = ({ name, label } ) => {
    const styleInput = {
        input:
            "min-h-24 w-full rounded-lg border border-colorBorder pt-12 pl-8 font-bold text-black-100 focus:border-none focus:outline-none focus:ring-colorBorder pb-1",
    };
    const [color, setColor] = useColor("hex", '#000000');
    const [firstSet, setFirstSet] = useState(false);
    const {
        control,
        formState: { errors },
        getValues,
    } = useFormContext();
    useEffect(() => {
        const colorInput = getValues(name);
        if (colorInput && !firstSet) {
            setColor(toColor('hex', colorInput));
            setFirstSet(true);
        }
    }, [getValues(name)]);
  return (
    <Controller
        name={name}
        control={control}
        render={({ field: { name, value, onBlur, ref, onChange } })=> (
        <div className="relative w-full mb-6">
            <p className="absolute left-8 top-4 font-semibold text-gray-100">
                {label}
            </p>
            <div className="flex w-full flex-wrap gap-4">
                <div className={styleInput.input}>
                    <input
                        name={name}
                        type="hidden"
                        value={value}
                        onBlur={onBlur}
                        ref={ref}
                    />
                    <ColorPicker
                        width={456}
                        height={228}
                        color={color}
                        onChange={(c) =>{
                            setColor(c);
                            onChange(c.hex);
                        }}
                        hideHSV
                        hideRGB
                        dark
                    />

                    {!!value &&
                        <button
                            type="button"
                            className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium text-white mt-1"
                            onClick={() => {
                                setColor(toColor('hex', ''));
                                onChange('');
                        }}>
                            Eliminar color
                        </button>
                    }
                </div>
            </div>
            {errors[name]&&
              <p className="mt-2 text-sm text-red text-red-600 dark:text-red-500">
                {String(errors[name] ? errors[name]?.message : '')}
              </p>
            }
        </div>
      )}
    />
  );
};

export default FormSwitch;
