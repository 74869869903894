import { Fragment, useState, ChangeEvent, useRef } from "react";
import { GrClose, GrZoomOut, GrZoomIn } from "react-icons/gr";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import cropDefaultOptions from "./crop-default-options";
import './styles.css';
import { b64toBlob } from '../../utils/form';
type propsType = {
    image: any;
    closeModal: () => void;
    onSuccess: (file: any) => void;
    initialAspectRatio: any;
    cropBoxResizable: boolean;
    dragMode: any;
    maxWidth?: number;
    maxHeight?: number;
}

const CropUpload = ({image, initialAspectRatio, cropBoxResizable, dragMode, closeModal, onSuccess, maxWidth, maxHeight }: propsType) => {
    const cropperRef = useRef<any>(null);
    const [zoom, setZoom] = useState(0.1);
    const getCropData = () => {
        if (cropperRef.current != null) {
            const imageElement: any = cropperRef.current;
            const cropper: any = imageElement?.cropper;
            const cropped = cropper.getCroppedCanvas();
            const roundedCanvas = getRoundedCanvas(cropped);
            if(roundedCanvas) {
                const roundedImage = b64toBlob(roundedCanvas.toDataURL());
                onSuccess(roundedImage);
            }
        }
    }
    
    const getRoundedCanvas = (sourceCanvas:any) => {
        let canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const width = maxWidth ? maxWidth : sourceCanvas.width;
        const height = maxHeight ? maxHeight : sourceCanvas.height;
      
        canvas.width = width;
        canvas.height = height;
        if(context){
            context.imageSmoothingEnabled = true;
            context.drawImage(sourceCanvas, 0, 0, width, height);
            context.globalCompositeOperation = 'destination-in';
            context.beginPath();
            context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
            context.fill();
            return canvas;
        }
    }

    const updateWidth = (value: number) => {
        let newZoom = zoom + value;
        if(newZoom >= 0 && newZoom <= 1){
            newZoom = parseFloat(newZoom.toFixed(1));
            setZoom(newZoom);
            if (cropperRef.current != null) {
                const imageElement: any = cropperRef.current;
                const cropper: any = imageElement?.cropper;
                cropper.zoomTo(newZoom);
            }
        }
    }
    const handleZoomChange = (e: any) => {
        setZoom(e.target.value);
        if (cropperRef.current != null) {
            const imageElement: any = cropperRef.current;
            const cropper: any = imageElement?.cropper;
            cropper.zoomTo(e.target.value);
        }
    }
    
    const limitZoom = (e: any) => {
        const ratio = e.detail.ratio;
        if (ratio > 1) {
            e.preventDefault();
        }else{
            setZoom(ratio);
        }
    }

    return (
        <Fragment>
            <div className="fixed inset-0 z-10 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-sm">
            <div
                className="absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white "
            >
                <div className="flex w-full justify-between pl-14 pr-10 pt-5 text-center">
                    <h3 className=" text-lg font-bold">
                        Ajustar imagen
                    </h3>
                    <button className="" onClick={closeModal}>
                        <GrClose />
                    </button>
                </div>

                <div id='crop-custom' className=" h-auto w-[47rem] flex-col flex-wrap overflow-auto">
                    <Cropper
                        style={{ height: 400, width: "100%", borderRadius: '50%' }}
                        zoomTo={0.1}
                        initialAspectRatio={initialAspectRatio ? initialAspectRatio : cropDefaultOptions.initialAspectRatio}
                        preview=""
                        src={image}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        ref={cropperRef}
                        viewMode={1}
                        zoomable={true}
                        cropBoxMovable={true}
                        zoom ={(e)=> limitZoom(e) }
                        guides={true}
                        cropBoxResizable={cropBoxResizable}
                        dragMode={dragMode ? dragMode : cropDefaultOptions.dragMode}
                        restore={true}
                        center={true}
                    />
                </div>
                <div className="w-full mt-2 flex justify-center items-center gap-2">
                    <GrZoomOut size={16} className="cursor-pointer" onClick={()=>updateWidth(-0.1)}/>
                    <input
                        type='range'
                        onChange={handleZoomChange}
                        min={0.1}
                        max={1}
                        step={0.1}
                        value={zoom}
                        className="accent-[black]"
                    />
                    <GrZoomIn size={16} className="cursor-pointer" onClick={()=>updateWidth(0.1)}/>
                </div>
                <div className="flex w-full justify-between px-8  pt-5 text-center mt-2 mb-6">
                    <button
                        onClick={getCropData}
                        type="button"
                        className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
                    >
                        Guardar
                    </button>
                </div>
            </div>
        </div>
        </Fragment>
    );
}

export default CropUpload;

