import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";

import { Back } from "../../Back";
import FormInput from "../../FormElement/FormInput";
import { ILabelRequest } from "../../../types/ILabel";
import { ICountriesResponse } from "../../../types/ICountry";
import { setFormErrorsFromServer } from "../../../utils/form";
import LabelService from "../../../services/labelService";
import CountryService from "../../../services/countryService";
import FormCropperImage from "../../FormElement/FormCropperImage";
import { StateContext } from "../../../context/StateProvider";


const schema = yup.object().shape({
  name: yup.string()
    .required('Campo requerido'),
  description: yup.string()
    .required('Campo requerido'),
  logo: yup.mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if (value instanceof File) {
        return value.size <= 20000000;
      }
      return true;
    }),
});

const defaultValues = {
  name: '',
  description: '',
  logo: '',
};

export const FormLabel = ({ }) => {
  const navigate = useNavigate()
  const { label_id } = useParams();
  const { state, dispatch } = useContext(StateContext);

  const [countryOptions, setCountryOptions] = useState<ICountriesResponse>({ rows: [], pages: 0, total: 0 });
  const form = useForm<ILabelRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    getCountries.mutateAsync().then(res => setCountryOptions(res));
  }, [form.reset]);

  const createItems = useMutation(
    (item: FormData) => LabelService.create(item),
  );
  const updateItems = useMutation(
    (item: FormData) => LabelService.update(label_id || '', item),
  );
  const getCountries = useMutation(
    () => CountryService.getAll(),
  );
  const getItem = useMutation(() => LabelService.get(label_id || ''));

  const onSubmit = async (data: ILabelRequest) => {
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true
    });

    dispatch({ type: "showLoaderScreen", payload: true });
    if (label_id && label_id.length) {
      updateItems.mutateAsync(formData)
        .then(res => {
          handleBack()
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
        })
        .catch((reason) => {
          setFormErrorsFromServer(reason, form.setError)
        });
    } else {
      createItems.mutateAsync(formData).then(res => {
        dispatch({ type: "showLoaderScreen", payload: false });
        handleCongratulations();
      })
        .catch((reason) => {
          setFormErrorsFromServer(reason, form.setError)
        });
    }
  };

  const handleBack = () => {
    navigate(`/admin/labels`);
  };

  const handleCongratulations = () => {
    navigate(`/admin/labels/congratulations`);
  };

  useEffect(() => {
    if (label_id) {
      getItem.mutateAsync().then(res => form.reset(res));
    }
  }, [form.reset]);

  const onError = (error: any) => {
    console.log("error ", error);
  }

  return (
    <div>
      <Back onClick={handleBack} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">{label_id ? 'Editar Label' : 'Crear nuevo Label'}</h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)} className="flex flex-col gap-10">
            <div className="flex justify-center gap-10 ">
              <FormInput
                name="name"
                type="text"
                label="Nombre del label"
              />
            </div>
            <FormCropperImage
              name="logo"
              label="Logo del label (Tamaño: 512 x 512)"
              accept="image/png, image/gif, image/jpeg"
              initialAspectRatio={1}
              cropBoxResizable={false}
              dragMode="move"
              maxWidth={512}
              maxHeight={512}
            />
            <FormInput
              name="description"
              type="textarea"
              label="Texto descriptivo"
            />
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                onClick={handleBack}
                type="button"
                className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                Guardar
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
