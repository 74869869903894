import React from "react";
import {PictureAndName} from "../PictureAndName/PictureAndName";

export const SelectTeamLeader = ({ firstName = '', lastName = '', profilePicture = '', value = '', handleOnChange = (e: React.ChangeEvent<HTMLInputElement>)=>{}}) => {
  return (
    <label htmlFor={value} className="w-[20rem]">
      <div className="flex w-full items-center gap-5 rounded-2xl border border-colorBorder py-5 pl-4 font-medium ">
        <input
          type="checkbox"
          name="leader_id"
          id={value}
          value={value}
          onChange={handleOnChange}
          className="rounded-full p-2 checked:bg-black-100   focus:ring-transparent active:bg-black-100"
        />
        <PictureAndName profilePicture={profilePicture} firstName={firstName} lastName={lastName}/>
      </div>
    </label>
  );
};
