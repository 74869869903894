import api from "./api";
import authHeader from "./authHeader";
import { ILabelResponse } from "../types/ILabel";
import { IPaginationResponse } from "../types/IPagination";

const getAll = async (params: object = {}) => {
  const response = await api().get<IPaginationResponse>(`labels/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<ILabelResponse>(`labels/detail/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<ILabelResponse>(`labels/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<ILabelResponse>(
    `labels/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<ILabelResponse>(`labels/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const getCsv = async (params: object = {}) => {
  const response = await api().get<any>(`labels/csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const LabelService = {
  getAll,
  get,
  create,
  update,
  remove,
  getCsv,
};

export default LabelService;
