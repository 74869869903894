import api from "./api";
import authHeader from "./authHeader";

const deletePicture = async (pictureId: string) => {
  const response = await api().delete(`picture-reports/${pictureId}`, {
    headers: authHeader(),
  });
  return response.data;
};

const PictureReportsService = {
  deletePicture,
};

export default PictureReportsService;
