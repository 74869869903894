import api from "./api";
import apiFront from "./apiFront";
import authHeader from "./authHeader";
import { ITicketResponse, ITicketRequest } from "../types/ITicket";
import { IPaginationResponse } from "../types/IPagination";
import { IOrderTicketLogResponse } from "../types/IOrderTicketLog";

const getAll = async (params: object) => {
  const response = await api().get<IPaginationResponse>(`tickets/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<ITicketRequest>(`tickets/detail/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<ITicketResponse>(`tickets/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<ITicketResponse>(
    `tickets/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const approve = async (id: string) => {
  const response = await api().put<ITicketResponse>(
    `tickets/approve/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const reject = async (id: string, reason: string) => {
  const response = await api().put<ITicketResponse>(
    `tickets/reject/${id}`,
    { reason },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<ITicketResponse>(`tickets/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const sendRecycler = async (id: string) => {
  const response = await api().post<ITicketResponse>(
    `tickets/send_recycler/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const sendTicket = async (item: any) => {
  const response = await apiFront().post<ITicketResponse>(`ticket/send`, item, {
    headers: authHeader(),
  });
  return response.data;
};

const emptyTrash = async () => {
  const response = await api().delete<ITicketResponse>(`tickets/empty_trash`, {
    headers: authHeader(),
  });
  return response.data;
};

const getTraceability = async (id: string) => {
  const response = await api().get<IOrderTicketLogResponse[]>(
    `tickets/traceability/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const TicketService = {
  getAll,
  get,
  create,
  update,
  approve,
  reject,
  remove,
  sendRecycler,
  sendTicket,
  emptyTrash,
  getTraceability,
};

export default TicketService;
