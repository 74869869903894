import { ITermRequest, ITermResponse } from "../types/ITerms";
import { IFilterRequest } from "../types/IFilter";
import api from "./api";
import authHeader from "./authHeader";

const getAll = async (params = {}) => {
  const response = await api().get<any>(`terms`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const create = async (item: ITermRequest) => {
  const response = await api().post<ITermResponse>(`terms/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string, item: ITermRequest) => {
  const response = await api().put<ITermResponse>(`terms/update/${id}`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<ITermResponse>(`terms/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const TermsService = {
  getAll,
  create,
  update,
  remove,
};

export default TermsService;
