import { useState } from "react";
import ISortOption from "../../types/ISortOption";
import abajo from "../../assets/abajo.png";
import arriba from "../../assets/arriba.png";

type sortProps = {
  setSort?: (filter: string) => void;
  options?: Array<ISortOption>;
  handleChangeState?: (filter: string) => void;
  state: string
};

export const FilterOrder = ({ setSort = () => { }, options, handleChangeState = () => { }, state}: sortProps) => {
  
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // if (typeof setSort === 'function') {
    setSort(event.target.value);
    // }
  };

  return (
    <div className="">
      <form className=" text-sm font-bold">
        <label>Ordenar:</label>
        <select
          name="filtrar"
          id="filtrar"
          className="cursor-pointer border-none "
          onChange={e => handleChange(e)}
        >
          {
            (
              options ?
                options.map((o) => (
                  <option key={o.value} value={o.value}>{o.text}</option>
                ))
                :
                <>
                  <option value="date_start">Fecha</option>
                  <option value="name">Nombre</option>
                </>
            )
          }

        </select>
        {
          state == "desc" ?
            <button className="ml-3" type="button" onClick={() => handleChangeState("asc")}><img className="w-3" src={arriba}></img> </button>
            :
            <button className="ml-3" type="button" onClick={() => handleChangeState("desc")}><img className="w-3" src={abajo}></img></button>
        }

      </form>

    </div>
  );
};
