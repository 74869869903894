import api from "./api";
import authHeader from "./authHeader";
import {
  ICollectablesResponse,
  IUserResponse,
  IUsersResponse,
} from "../types/IUser";
import { IFilterRequest } from "../types/IFilter";
import { IOrderTicketsResponse } from "../types/IOrderTicket";
import { IEventPictureResponse } from "../types/IEventPicture";

const getAll = async (params: IFilterRequest) => {
  const response = await api().get<any>(`users/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<IUserResponse>(`users/detail/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const getUserTickets = async (user_id: string, params: object = {}) => {
  const response = await api().get(`users/user_tickets/${user_id}`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<IUserResponse>(`users/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<IUserResponse>(`users/update/${id}`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<IUserResponse>(`users/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const deleteAccount = async (id: string) => {
  const response = await api().post<IUserResponse>(
    `users/delete_account/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const getCollectables = async (params: IFilterRequest) => {
  const response = await api().get<ICollectablesResponse>(
    `users/collectables`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const getOrderTickets = async (id: string, params: object = {}) => {
  const response = await api().get<IOrderTicketsResponse>(
    `users/tickets/${id}`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const getRewards = async (id: string, params: object = {}) => {
  const response = await api().get<IOrderTicketsResponse>(
    `users/rewards/${id}`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const getPictures = async (params: object = {}) => {
  const response = await api().get<IEventPictureResponse[]>(`users/pictures`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getReported = async (params: IFilterRequest) => {
  const response = await api().get<any>(`users/reported`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getCounter = async (params: IFilterRequest) => {
  const response = await api().get<any>(`users/counter`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const emptyTrash = async () => {
  const response = await api().delete<IUserResponse>(`users/empty_trash`, {
    headers: authHeader(),
  });
  return response.data;
};

const UserService = {
  getAll,
  get,
  getUserTickets,
  create,
  update,
  remove,
  deleteAccount,
  getCollectables,
  getOrderTickets,
  getRewards,
  getPictures,
  getReported,
  getCounter,
  emptyTrash,
};

export default UserService;
