import React, { createContext, useReducer } from "react";
import { stateReducer } from "./stateReducer";
import cropDefaultOptions from "../components/FormElement/FormCropperImage/crop-default-options";

type InitialStateType = {
  showAddTeam: boolean,
  showTeam: boolean,
  availableMembers: Array<any>
  members: Array<any>
  orders_ticket: Array<any>
  showAssignLeader: boolean;
  membersToAssign: Array<any>;
  membersAssign: Array<any>;
  showReview: boolean,
  reviewData: any,
  showAddEvent: boolean,
  showAddNews: boolean;
  showEvent: boolean,
  events: Array<any>,
  selectedEvents: Array<any>,
  showAddArtist: boolean,
  showArtist: boolean,
  showRequestRefund: boolean,
  artists: Array<any>,
  selectedArtists: Array<any>,
  showAddValidator: boolean,
  showValidator: boolean,
  validators: Array<any>,
  selectedValidators: Array<any>,
  showAddUser: boolean,
  showUser: boolean,
  users: Array<any>,
  selectedUsers: Array<any>,
  selectedOrderTicket: any,
  showLoaderScreen: boolean,
  showCropper: boolean,
  cropperOptions: any,
  currentInputImageCropper: any,
  currentInputOnChangeCropper: any,
  showMessage: boolean,
  reloadEvent: any,

  showEditCard: boolean,
  producerSelected: any,
  producerUpdated: any,
  showAddProducer: boolean,
  listProducersByUser: Array<any>,
  selectedProducers: Array<any>,
  showProducer: boolean,
  permittedProducers: Array<any>,
  showImportCsv: boolean,
  reloadOrderTicket: boolean,

  showHistory: boolean,
  typeHistory: string,
  eventIDHistory: string,
  showInviteViaCSV: boolean,
  selectedNews: Array<any>,
  selectedSections: Array<string>,
}

const initialState = {
  showAddTeam: false,
  showRequestRefund: false,
  showTeam: false,
  members: [],
  orders_ticket: [],
  availableMembers: [],
  showAssignLeader: false,
  membersToAssign: [],
  membersAssign: [],
  showReview: false,
  reviewData: null,
  showAddEvent: false,
  showAddNews: false,
  showEvent: false,
  events: [],
  selectedEvents: [],
  showAddArtist: false,
  showArtist: false,
  artists: [],
  selectedArtists: [],
  showAddValidator: false,
  showValidator: false,
  validators: [],
  selectedValidators: [],
  showAddUser: false,
  showUser: false,
  users: [],
  selectedUsers: [],
  selectedOrderTicket: null,
  showLoaderScreen: false,
  showCropper: false,
  cropperOptions: cropDefaultOptions,
  currentInputImageCropper: null,
  currentInputOnChangeCropper: null,
  showMessage: false,
  reloadEvent: null,

  showEditCard: false,
  producerSelected: null,
  producerUpdated: null,
  showAddProducer: false,
  listProducersByUser: [],
  selectedProducers: [],
  showProducer: false,
  permittedProducers: [],
  showImportCsv: false,
  reloadOrderTicket: false,

  showHistory: false,
  typeHistory: '',
  eventIDHistory: '',
  showInviteViaCSV: false,
  selectedNews: [],
  selectedSections: [],
};

export interface ContextProps {
  showRequestRefund: boolean;
  showAddTeam: boolean;
  showTeam: boolean;
  members: any;
  orders_ticket:any
  availableMembers: any;
  showAssignLeader: boolean;
  membersToAssign: any;
  membersAssign: any;
  showReview: boolean;
  reviewData: any;
  showAddNews: boolean;
  showAddEvent: boolean,
  showEvent: boolean,
  events: any;
  selectedEvents: any;
  showAddArtist: boolean,
  showArtist: boolean,
  artists: any;
  selectedArtists: any;
  showAddValidator: boolean,
  showValidator: boolean,
  validators: any;
  selectedValidators: any;
  showLoaderScreen: boolean;
  showCropper: boolean;
  cropperOptions: any;
  currentInputImageCropper: any;
  currentInputOnChangeCropper: any;
  showMessage: boolean,
  reloadEvent: any,

  showEditCard: boolean,
  producerSelected: any,
  producerUpdated: any,
  showAddProducer: boolean,
  listProducersByUser: any,
  selectedProducers: any,
  showProducer: boolean,
  permittedProducers: any,
  showImportCsv: boolean,
  reloadOrderTicket: boolean,

  showHistory: boolean,
  typeHistory: string,
  eventIDHistory: string,
  showInviteViaCSV: boolean,
  
  selectedNews: any;
  selectedSections: any;
}

export const StateContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({state: initialState, dispatch: ()=>null});

export const StateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};
