import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import ClientService from '../../services/clientService';
import { Back } from '../Back';
import FormInput from '../FormElement/FormInput';
import { useEffect, FC , useContext, useState} from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { IClientRequest } from '../../types/IClient';
import jsonToFormData from "@ajoelp/json-to-formdata";
import {StateContext} from "../../context/StateProvider";
import {AuthContext} from "../../context/AuthContext";
import { AiFillEyeInvisible, AiOutlineEye } from 'react-icons/ai';

const schema = yup.object().shape({
    old_password: yup.string()
        .required('Contraseña es requerida'),
    password: yup.string()
        .required('La nueva contraseña es requerida')
        .min(6, 'La contraseña debe contener al menos 6 caracteres'),
    password_confirm: yup.string().oneOf([yup.ref('password'), null], 'La contraseña debe coincidir'),
});

const defaultValues = {
    old_password: '',
    password: '',
    password_confirm: '',
};

type typeProps = {
};

export const FormPassword: FC<typeProps> = () => {
    const navigate = useNavigate();
    const {user, getUser} = useContext(AuthContext);
    const { state, dispatch} = useContext(StateContext);
    const [ showCurrentPassword, setShowCurrentPassword ] = useState(false);
    const [ showNewPassword, setShowNewPassword ] = useState(false);
    const [ showRepeatPassword, setShowRepeatPassword ] = useState(false);
    const updateItems = useMutation(
        (item: FormData) => ClientService.update(user.id, item),
    );

    const form = useForm<IClientRequest>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });
    useEffect(() => {

    },[form.reset]);

    const onSubmit = async(data: IClientRequest) => {
        data={ ...data, first_time_login: false, user_role: user.user_role }
        let formData = jsonToFormData(data, {
        arrayIndexes: true,
        excludeNull: true
        });
        dispatch({ type: "showLoaderScreen", payload: true });
        await updateItems.mutateAsync(formData).then(async res => {
            await getUser();
            form.reset({});
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({ type: "toastSuccess", payload: "Contraseña actualizada" });
            navigate('/client/producers');
        })
            .catch((reason)=>{
                dispatch({ type: "showLoaderScreen", payload: false });
                dispatch({ type: "toastError", payload: reason.response.data.message });
            });
    };

    const onError = (error:any) => {
        console.log("error ",error);
    }

    const handleClickShowCurrentPassword = () =>{
        setShowCurrentPassword(!showCurrentPassword);
    }

    const handleClickShowNewPassword = () =>{
        setShowNewPassword(!showNewPassword);
    }

    const handleClickShowRepeatPassword = () =>{
        setShowRepeatPassword(!showRepeatPassword);
    }

    return (
        <div className="">
            <Back onClick={() => navigate("/client/producers")}/>
          <div className="flex flex-col gap-10 xl:px-[23rem]">
            <h1 className="text-center text-3xl font-bold">Cambio de Contraseña</h1>
            <FormProvider {...form}>
              <form  onSubmit={form.handleSubmit(onSubmit, onError)}>
                <div className='relative'>
                  <FormInput
                      name="old_password"
                      type={showCurrentPassword ? 'text' : 'password'}
                      label="Contraseña Actual"
                  />
                  <div
                  className="icon_button absolute right-4 top-4 cursor-pointer"
                    onClick={handleClickShowCurrentPassword}
                    >
                    {
                        showCurrentPassword ?
                        <AiOutlineEye
                            className="text-[#92929D] "
                            size={20}
                        /> :
                        <AiFillEyeInvisible
                            className="text-[#92929D] "
                            size={20}
                        />
                    }
                  </div>
                </div>
                <div className='relative'>
                  <FormInput
                      name="password"
                      type={showNewPassword ? 'text' : 'password'}
                      label="Nueva Contraseña"
                  />
                  <div
                  className="icon_button absolute right-4 top-4 cursor-pointer"
                    onClick={handleClickShowNewPassword}
                    >
                    {
                        showNewPassword ?
                        <AiOutlineEye
                            className="text-[#92929D] "
                            size={20}
                        /> :
                        <AiFillEyeInvisible
                            className="text-[#92929D] "
                            size={20}
                        />
                    }
                  </div>
                </div>
                <div className='relative'>
                  <FormInput
                      name="password_confirm"
                      type={showRepeatPassword ? 'text' : 'password'}
                      label="Repetir Contraseña"
                  />
                  <div
                  className="icon_button absolute right-4 top-4 cursor-pointer"
                    onClick={handleClickShowRepeatPassword}
                    >
                    {
                        showRepeatPassword ?
                        <AiOutlineEye
                            className="text-[#92929D] "
                            size={20}
                        /> :
                        <AiFillEyeInvisible
                            className="text-[#92929D] "
                            size={20}
                        />
                    }
                  </div>
                </div>
                <div className="mb-16 flex justify-center gap-10 pt-10">
                  <button
                    type= "submit"
                    className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                  >
                     Guardar Contraseña
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      );
}
