import React, { Fragment, useContext, useEffect, useState } from "react";
import { Title } from "../Title";
import { useMutation } from "@tanstack/react-query";
import TeamService from "../../../services/teamService";
import {ITeamResponse, ITeamsResponse} from "../../../types/ITeam";
import { ITicketsResponse } from "../../../types/ITicket";
import TicketService from "../../../services/ticketService";
import FormInput from "../../FormElement/FormInput";
import { useForm, FormProvider } from "react-hook-form";
import {ISendTicketsLogs, ISendTicketsRequest} from "../../../types/IEvent";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import {getFullNameOrEmail, setFormErrorsFromServer} from "../../../utils/form";
import FormSelectFull from "../../FormElement/FormSelectFull";
import circleCheck from "../../../assets/circle-check.png";
import EventService from "../../../services/eventService";
import { StateContext } from "../../../context/StateProvider";
import {motion} from "framer-motion";
import {formatDateTime} from "../../../utils/format-date";
import DropdownDots from "../../FormElement/DropdownDots";
import {FaTrashAlt} from "react-icons/fa";
import {IFilterRequest, IFilterTicketRequest} from "../../../types/IFilter";
import {FilterPage} from "../../table/FilterPage";
import {FilterQtyPage} from "../../table/FilterQtyPage";
import LoaderCircle from "../../FormElement/LoaderCircle";
import config from "../../../config/variables";

type SendTicketsProps = {
  producerId?: string;
  eventId: string;
};

const schema = yup.object().shape({
  rrpps: yup.array().required("Campo requerido"),
  tickets: yup.array().required("Campo requerido"),
  qty: yup.array().required("Campo requerido"),
});

const defaultValues = {
  rrpps: "",
  tickets: "",
  qty: "",
};

const defaultFiltersLogs = {
  to: 'leader',
  limit: config.filter_default_limit,
  page:1,
  status: '',
  sortBy: 'createdAt',
  sortOrder: 'desc',
}

export const SendTicketsEvent = ({ producerId, eventId }: SendTicketsProps) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [groupFields, setGroupFields] = useState(1);
  const[filtersLogs, setFiltersLogs] = useState<IFilterRequest>({...defaultFiltersLogs, event_id: eventId});
  const [members, setMembers] = useState<ITeamsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });
  const [leaders, setLeaders] = useState<Array<ITeamResponse>>([]);
  const [tickets, setTickets] = useState<ITicketsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });
  const [logs, setLogs] = useState<ISendTicketsLogs>({
    rows: [],
    total: 0,
    pages: 0,
  });
  const [searchLogs, setSearchLogs] = useState("");
  const [loadingLogs, setLoadingLogs] = useState(false);

  const getMembers = useMutation(() =>
    TeamService.getAll({ event_id: eventId })
  );

  const getTickets = useMutation(() =>
    TicketService.getAll({ event_id: eventId })
  );

  const createItems = useMutation((item: FormData) =>
    EventService.sendTicketsToSeller(item)
  );

  const getLogs = useMutation(
      (filters: IFilterRequest) => EventService.getSendTicketsLogs(filters)
  );

  const removeLogs = useMutation(
      (logId: string) => EventService.removeSendTicketsLogs(logId)
  );

  const { dispatch } = useContext(StateContext);

  useEffect(() => {
    getMembers.mutateAsync().then((res) => {
      const key = 'user_id';
      res.rows = [...new Map(res.rows.map(item =>
          [item[key], item])).values()];
      setMembers(res);
      setLeaders(res.rows.filter((i) => i.team_role == 'leader'));
    });
    getTickets.mutateAsync().then((res) => setTickets(res));
    getLogs.mutateAsync(filtersLogs).then((res) => setLogs(res));
  }, []);

  const form = useForm<ISendTicketsRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = async (data: ISendTicketsRequest) => {
    const validData = getValidData(data);
    if (validData == null) return;
    let formData = jsonToFormData(validData, {
      arrayIndexes: true,
      excludeNull: true,
    });
    dispatch({ type: "showLoaderScreen", payload: true });
    createItems
      .mutateAsync(formData)
      .then(async (res) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        form.reset({
          rrpps: ["", "", "", "", ""],
          tickets: ["", "", "", "", ""],
          qty: ["", "", "", "", ""],
        });
        setShowSuccess(!showSuccess);
      })
      .catch((reason) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        if (Array.isArray(reason.response.data.message)) {
          for (let message of reason.response.data.message) {
            dispatch({ type: "toastError", payload: message });
          }
        } else {
          dispatch({ type: "toastError", payload: reason.response.data.message });
        }
      });
  };

  const getValidData = (data: ISendTicketsRequest) => {
    const newData: any = { rrpps: [], tickets: [], qty: [] };
    for (let i = 0; i < groupFields; i++) {
      if (data.rrpps[i] && data.tickets[i] && data.qty[i]) {
        newData.rrpps.push(data.rrpps[i]);
        newData.tickets.push(data.tickets[i]);
        newData.qty.push(data.qty[i]);
      } else if (!data.rrpps[i] && !data.tickets[i] && !data.qty[i]) {
      } else {
        if (!data.rrpps[i]) {
          dispatch({
            type: "toastError",
            payload: `En la fila ${i + 1} el lider es requerido.`,
          });
        }
        if (!data.tickets[i]) {
          dispatch({
            type: "toastError",
            payload: `En la fila ${i + 1} el ticket es requerido.`,
          });
        }
        if (!data.qty[i]) {
          dispatch({
            type: "toastError",
            payload: `En la fila ${i + 1} la cantidad es requerida.`,
          });
        }
        return null;
      }
    }
    if (
      newData.rrpps.length == 0 &&
      newData.tickets.length == 0 &&
      newData.qty.length == 0
    )
      return null;
    return newData;
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const handleAddGroupFields = () => {
    if (groupFields == 5) return;
    setGroupFields(groupFields + 1);
  };

  const columnData = ["Líder", "Email", "Fecha de envío", "Ticket", "Cantidad de tickets", "Acciones"];

  const handleFilterLogs = async(filter: any) => {
    const newFilters = {...filtersLogs, ...filter}
    await setFiltersLogs(newFilters);
    await getLogs.mutateAsync(newFilters).then(res=>{
      setLogs(res)
    });
  }

  useEffect(() => {
    if (searchLogs != ""){
      const delayDebounceFn = setTimeout(() => {
        loadDataLogs();
      }, 600)
      return () => clearTimeout(delayDebounceFn)
    }
    else {
      loadDataLogs();
    }
  }, [searchLogs])

  const loadDataLogs = () => {
    setLoadingLogs(true);
    getLogs.mutateAsync(filtersLogs).then(res => {
      setLogs(res)
      setLoadingLogs(false);
    });
  }

  const searcherLogs = async (e: any) => {
    setSearchLogs(e.target.value)
    const paramsFilters = { ...filtersLogs,  page: 1, query: e.target.value };
    await setFiltersLogs(paramsFilters);
  }

  const handleDeleteLog = (id: string) => {
    if (confirm('Está seguro de eliminar este ítem?')) {
      removeLogs.mutateAsync(id).then((res) => {
        getLogs.mutateAsync(filtersLogs).then(res => {
          setLogs(res)
          setLoadingLogs(false);
        });
      });
    }
  }

  return (
    <div>
      {showSuccess ? (
        <div className="flex flex-col gap-10 xl:px-[23rem]">
          <div>
            <img
              src={circleCheck}
              alt="check"
              className="m-auto mb-4 text-center"
            />
            <h1 className="mb-4 text-center text-[20px] font-bold">
              Felicitaciones
            </h1>
            <p className="text-center">Tickets enviados exitosamente</p>
          </div>

          <div className="mb-16 flex justify-center gap-10 pt-10">
            <button
              onClick={() => {
                setGroupFields(1);
                handleFilterLogs({});
                setShowSuccess(!showSuccess);
              }}
              className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium text-white"
            >
              Enviar mas tickets
            </button>
          </div>
        </div>
      ) : (
        <Fragment>
          <Title>Envío Tickets</Title>
          <div className="xl:px-96- flex  flex-col justify-center gap-10 pt-12">
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit, onError)}
                className="flex flex-col gap-10 pb-20"
              >
                {[...Array(groupFields)].map((row, i) => (
                  <div className="grid grid-cols-4 xl:gap-10 gap-2" key={i}>
                    <div className="col-span-2">
                      <FormSelectFull
                        name={`rrpps[${i}]`}
                        options={
                          [
                            { text: `Seleccionar Líder...`, value: '' }
                          ].concat(
                            leaders.map((c) => {
                              return {
                                value: c.user_id,
                                text:
                                  c.user[0]?.first_name +
                                  " " +
                                  c.user[0]?.last_name,
                              };
                            })
                          )
                        }
                        label="Seleccione un Líder"
                      />
                    </div>
                    <div className="">
                      <FormSelectFull
                        name={`tickets[${i}]`}
                        options={[
                          { value: "", text: "Seleccionar Ticket..." },
                        ].concat(
                          tickets.rows
                            .filter((row) => row.type === "Free")
                            .map((c) => {
                              return { value: c.id, text: c.name };
                            })
                        )}
                        label="Tipo de ticket"
                      />
                    </div>
                    <div className="">
                      <FormInput
                        type="number"
                        name={`qty[${i}]`}
                        label="Cantidad de tickets a enviar"
                        min="1"
                      />
                    </div>
                  </div>
                ))}
                {groupFields < 5 && (
                  <div>
                    <button
                      onClick={handleAddGroupFields}
                      type="button"
                      className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
                    >
                      + Agregar un RRPP
                    </button>
                  </div>
                )}
                <button className="m-auto mt-10 w-60 rounded-full border-[2px] border-black-100 bg-black-100 px-10 py-2  font-bold text-white ">
                  Enviar tickets
                </button>
              </form>
            </FormProvider>
          </div>
          <div>
            <Title>Historial</Title>
            <div className="flex mt-2">
              {!!tickets.rows && (
                  <select
                      id="filterPage"
                      className="w-[350px] cursor-pointer rounded-lg py-3 pl-4 pr-8 font-medium text-gray-100 outline outline-1 outline-colorBorder"
                      onChange={searcherLogs}
                  >
                    <option value="">Seleccione un ticket</option>
                    {tickets.rows.filter((row) => row.type === "Free").map((_, i) => (
                        <option value={_.id} key={i}>
                          {_.name}
                        </option>
                    ))}
                  </select>
              )}
              {loadingLogs && <LoaderCircle width={28} height={28}/>}
            </div>
            <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
              <thead className=" divide-solid border ">
              <tr className=" pb-4 text-sm font-bold text-gray-100">
                {columnData.map((column, index) => (
                    <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
                ))}
              </tr>
              </thead>
              <tbody>
              {logs.rows.map((_, i) => (
                  <motion.tr
                      key={i}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                      className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                  >
                    <td className="pl-4 font-bold capitalize">
                      {_.user ? getFullNameOrEmail(_.user) : _.email}
                      {_.user?.status == 'deleted' && <small className="font-light"> (eliminado)</small>}
                    </td>
                    <td className="capitalize">{ _.email }</td>
                    <td className="capitalize">{formatDateTime(_.date_send)}</td>
                    <td className="flex h-full items-center gap-2 capitalize">
                      {_.ticket.name}
                    </td>
                    <td className="">{_.qty} ticket(s)</td>
                    <td className="">
                      <DropdownDots options={[
                        { title: 'Eliminar', action: () => handleDeleteLog(_.id), icon: (<FaTrashAlt size={23} />) }
                      ]} />
                    </td>
                  </motion.tr>
              ))}
              {!!logs.rows.length &&
                  <motion.tr
                      key={'total'}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                      className={`h-16 rounded-xl text-start font-extrabold`}
                  >
                    <td className="font-bold text-right pr-4" colSpan={3}>TOTAL: </td>
                    <td className="">{logs.rows[0].total} ticket(s)</td>
                  </motion.tr>
              }
              </tbody>
            </table>
            {logs.rows.length === 0 ? <p className="text-center">No existen registros disponibles</p> : null}
            <div className="flex justify-between pt-10">
              <FilterPage handleFilter={handleFilterLogs} total={logs.total} limit={filtersLogs.limit} currentPage={filtersLogs.page}/>
              <FilterQtyPage handleFilter={handleFilterLogs}/>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
