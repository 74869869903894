const cropperOptions = {
    style: {height: 400, width: "100%"},
    zoomTo: 0.5,
    initialAspectRatio: 1,
    preview: "",
    viewMode: 1,
    minCropBoxHeight: 10,
    minCropBoxWidth: 10,
    background: false,
    responsive: true,
    autoCropArea: 1,
    checkOrientation: false,
    guides: true,
    cropBoxResizable: true,
    dragMode: 'crop',
}


export default cropperOptions;
