import React, { FC, useState } from "react";
import { ISectionResponse } from "../../../types/ISection";
import { Back } from "../../Back";
import { FilterOption } from "../../table/FilterSection";

type typeProps = {
  handleSelectItem: (data: ISectionResponse) => void;
  data: ISectionResponse | undefined;
}

export const SelectedSection: FC<typeProps> = ({ handleSelectItem, data }) => {
  const filterSectionProps = [
    { title: "Sections", id: "sections" }
  ];

  const [formValue, setFormValue] = useState("ordenes");

  const handleFormValue = (event: React.ChangeEvent<HTMLFormElement>) => {
    setFormValue(event.target.id);
  };

  const showFormValue = () => {
    switch (formValue) {
      case "songs":
        return '';
      default:
        return '';
    }
  };

  return (
    <div>
      <Back onClick={handleSelectItem} />
      <div className="pt-6">
        {/* <BannerArtist edit={true} data={data}/> */}
      </div>
      <div className="w-full overflow-auto border-b pt-10">
        <form
          className="flex gap-10 font-medium text-gray-100"
          onChange={handleFormValue}
        >
          {filterSectionProps.map((option) => (
            <FilterOption title={option.title} id={option.id} />
          ))}
        </form>
      </div>
      <div className="pt-10">{showFormValue()}</div>
    </div>
  );
};