import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion"
import {FaEdit, FaRedo, FaTrashAlt} from "react-icons/fa"
import { GoSearch } from "react-icons/go"
import { toast } from "react-toastify";
import UserService from "../../../services/userService";
import { IUsersResponse } from "../../../types/IUser";
import { formatDate, formatDateTime } from "../../../utils/format-date";
import DropdownDots from "../../FormElement/DropdownDots"
import {IReportedsResponse} from "../../../types/IReported";
import {ICommentReportsResponse} from "../../../types/IComment";
import React from "react";
import EventService from "../../../services/eventService";
import NewsService from "../../../services/newsService";

type selectedProps = {
    // id: string;
    data: ICommentReportsResponse;
    updateData: (filter: string) => void;
    from?: string
  };

const columnData = [
    "Fecha",
    "Comentario",
    "Autor",
    "Email",
    "Evento",
    "Reportado por:",
    "Email reporter:",
    "Razón",
    "Acciones",
];

export const CommentsReported = ({data, updateData, from}: selectedProps) => {
    columnData[4] = from && from == 'news' ? 'Novedad' : 'Evento';
    const deleteItem = useMutation((id: string) => {
        // console.log('MMMMMMM', option);
        switch (from) {
            case "event":
                return EventService.removeCommentReport(id);
            case "news":
                return NewsService.removeCommentReport(id);
            default:
                return EventService.removeCommentReport(id);
        }
    });

    const handleDelete = async (id: string) => {
        if (confirm('Está seguro de eliminar este comentario?')) {
            await deleteItem.mutateAsync(id).then(res => {
                updateData(from && from == 'news' ? 'news_comments' : 'event_comments');
            });
        }
    }

    return (
        <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
                <thead className=" divide-solid border ">
                    <tr className=" pb-4 text-sm font-bold text-gray-100">
                        {columnData.map((column, index) => (
                            <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.rows.length ? data.rows.map((_, i) => (
                        <motion.tr
                            key={i}
                            layout
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0.2 }}
                            transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                            className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                        >
                            <td className="pl-4">{formatDateTime(_.createdAt)}</td>
                            <td className="pl-4">{_.comment?.comment}</td>
                            <td className="pl-4">{_.comment?.author ? `${_.comment?.author.first_name} ${_.comment?.author.last_name}` : '(usuario de bombo)'}</td>
                            <td className="pl-4">{_.comment?.author ? _.comment?.author.email : '-'}</td>
                            <td className="pl-4">{from == 'news' ? _.comment?.news?.name : _.comment?.event?.name}</td>
                            <td className="pl-4">{_.user ? `${_.user.first_name} ${_.user.last_name}` : 'Usuario de Bombo'}</td>
                            <td className="pl-4">{_.user ? _.user.email : 'Usuario de Bombo'}</td>
                            <td className="flex h-full items-center gap-2 pl-4">
                                {_.reason || '-'}
                            </td>
                            <td className="pl-4">
                                <DropdownDots options={[
                                    { title: 'Eliminar Comentario', action: () => handleDelete(_.id), icon: (<FaTrashAlt size={23} />) }
                                ]} />
                            </td>
                        </motion.tr>
                    )) : <></>}
                </tbody>
            </table>
    )
}
