import { useState } from "react";

import { INewResponse } from "../../types/INews";
import { NewNews } from "./NewNews/NewNews";
import { TableNews } from "./TableNews";

export const NewsView = () => {
  // Manager of Artist selected
  const [showSelected, setShowSelected] = useState(false);
  const [createNews, setCreateNews] = useState(false);
  const [news, setNewsID] = useState<INewResponse>();

  const handleSelect = (news?: INewResponse) => {
    setShowSelected(!showSelected);
    if (news) setNewsID(news);
  };

  const handleCreateNews = () => {
    setCreateNews(!createNews);
  };

  const newsTableProps = {
    handleCreateNews,
    handleSelect,
  };

  const newNewsProps = {
    handleCreateNews,
  };

  return(
    <div>
      {showSelected
        ? <NewNews {...newNewsProps} />
        : <TableNews {...newsTableProps} />
      }
    </div>
  );
};
