import {FC, useState} from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { FormInputProps } from '../../../types/FormInputsProps';
import Autocomplete from "react-google-autocomplete";
import config from "../../../config/variables";

const styleInput = {
    input:
        "h-24 w-full rounded-lg border border-colorBorder pt-6 pl-8 font-bold text-black-100 focus:outline-none  focus:ring-colorBorder",
};

const FormInput: FC<FormInputProps> = ({ name, type, label, placeholder, disabled = false } ) => {
    const formState = useFormState();

    const handleSelectedPlace = (googlePlace: any, onChange: any) => {
        const newPlaceString = {
            name:googlePlace?.name? googlePlace.name : null,
            address: googlePlace.formatted_address,
            latitude: googlePlace.geometry.location.lat(),
            longitude: googlePlace.geometry.location.lng(),
        };
        onChange(newPlaceString);
    }

    return (
        <Controller
            name={name}
            render={({ field })=> (
                <div className="relative w-full mb-6" id="place-input-div">
                    <p className="absolute left-8 top-4 font-semibold text-gray-100">
                        {label}
                    </p>

                    <input {...field} type="hidden"/>

                    <Autocomplete
                        apiKey={config.api_key_google_maps}
                        onPlaceSelected={(place) => handleSelectedPlace(place, field.onChange)}
                        options={{ types: [], fields: [] }}
                        defaultValue={Array.isArray(field.value) && field.value[0] ? field.value[0].name : field.value.name}
                        placeholder={placeholder}
                        className={styleInput.input}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        language='es-Es'

                    />

                    {formState.errors[name]&&
                        <p className="mt-2 text-sm text-red text-red-600 dark:text-red-500">
                            {String(formState.errors[name] ? formState.errors[name]?.message : '')}
                        </p>
                    }
                </div>
            )}
        />
    );
};

export default FormInput;
