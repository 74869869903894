import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { PageContainer } from "../PageContainer";
import { TitleSection } from "../TitleSection";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { IFilterRequest } from "../../types/IFilter";
import { ICommentReportsResponse } from "../../types/IComment";
import { formatDate } from "../../utils/format-date";
import EventService from "../../services/eventService";
import NewsService from "../../services/newsService";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: []
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  query: '',
};

const columnData = [
  "ID",
  "Fecha",
  "Usuario",
  "Razón",
];

export const CommentReportsView = () => {
  const { comment_id } = useParams();
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  const getEventsCommentReports = useMutation(
    (newFilters: IFilterRequest) => EventService.getCommentReports(newFilters)
  );
  const getNewsCommentReports = useMutation(
    (newFilters: IFilterRequest) => NewsService.getCommentReports(newFilters)
  );

  const [result, setResult] = useState<ICommentReportsResponse>(defaultResult);
  const [search, setSearch] = useState("");

  let typeComments = 'events';
  if (window.location.pathname.includes('news/')) {
    typeComments = 'news';
  }

  useEffect(() => {
    handleFilter({...filters, id: comment_id});
  }, []);

  const searcher = async (e: any) => {
    setSearch(e.target.value)
    handleFilter({ page: 1, query: e.target.value });
  };

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    switch (typeComments) {
      case 'events': await getEventsCommentReports.mutateAsync({ ...filters, ...filter }).then(res => {
        setResult(res);
      });
        break;
      case 'news': await getNewsCommentReports.mutateAsync({ ...filters, ...filter }).then(res => {
        setResult(res);
      });
        break;
    }
  };

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Reportes {`(${comment_id})`}</TitleSection>
      </div>
      <div className="flex justify-end gap-2 py-2">
        <input type="text" placeholder="Search..." className="rounded-full border-[2px] border-black-100 px-4 py-1 font-bold text-black-100  hover:text-black" value={search} onChange={searcher} />
      </div>
      <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4"></th>
          </tr>
        </thead>
        <tbody>
          {result.rows.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">#{row.id}</td>
              <td className="pl-4">{formatDate(row.createdAt)}</td>
              <td className="pl-4">{row.user ? `${row.user.first_name} ${row.user.last_name}` : 'Usuario de Bombo'}</td>
              <td className="pl-4">{row.reason}</td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {result?.rows.length == 0 && <p className="text-center">No existen comentarios disponibles</p>}

      <div className="flex justify-between pt-10">
        <FilterPage handleFilter={handleFilter} total={result.total} limit={filters.limit} status={filters.status} currentPage={filters.page} />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};
