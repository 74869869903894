export const formatMoney = (value: number) => {
    if (value == null) return value;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return formatter.format(value);
}

export const formatMoney2 = (value:number ) =>{
    if (value == null) return value;
    const formatter = new Intl.NumberFormat('es-CO', {
        style: "currency",
        currency: "COP",
    })
    return formatter.format(value);
} 
