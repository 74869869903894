import React, {useEffect, useState} from "react";
import logo from "../../assets/logoFooter.svg";
import arrowDown from "../../assets/img/arrow-down.png";
import asset1 from "../../assets/img/asset-1.svg";
import asset2 from "../../assets/img/asset-2.svg";
import asset3 from "../../assets/img/asset-3.svg";
import asset4 from "../../assets/img/asset-4.svg";
import asset5 from "../../assets/img/asset-5.svg";
import {useMutation} from "@tanstack/react-query";
import UserService from "../../services/userService";
import variables from "../../config/variables";

export const UsersCounter = () => {
    const styleClassPage = {
        container: "h-screen bg-black-100 text-white",
    };

    const [firstCallCounters, setFirstCallCounters] = useState(true);
    const [usersCounter, setUsersCounter] = useState('0');

    const getCounter = useMutation(() => UserService.getCounter({ignore_tickets: true}));

    useEffect(() => {
        if (firstCallCounters) {
            setFirstCallCounters(false);
            getCounter.mutateAsync().then((res) => {
                setUsersCounter(res.users?.toLocaleString('es'))
            });
        }
        const interval = setInterval(() => {
            getCounter.mutateAsync().then((res) => {
                setUsersCounter(res.users?.toLocaleString('es'))
            });
        }, variables.interval_ms_users_counter || 30000);

        return () => clearInterval(interval);
    }, []);

    return (
        <main>
            <div className={styleClassPage.container} style={{fontFamily: "'BaseNeue', Fallback, sans-serif"}}>
                <div className="pt-[6%]">
                    <img src={logo} alt="Logo" className="m-auto w-[105px]"/>
                </div>
                <div className="pt-[6%] text-center">
                    <table className="m-auto">
                        <tr>
                            <td><img src={arrowDown} alt="flecha" className="inline-flex mb-2 mr-2"/></td>
                            <td><span className="inline-flex text-xl font-bold">
                                Ya somos una comunidad con
                            </span></td>
                        </tr>
                    </table>
                </div>
                <div className="text-center">
                    <h2 className="text-[180px] font-bold">
                        {usersCounter}
                    </h2>
                    <p className="text-[#FC5640] text-[62px] font-extralight italic tracking-wide">
                        BOMBERS
                    </p>
                </div>
                <div className="w-full flex fixed bottom-0 p-16">
                    <div className="w-[50%]">
                        <img src={asset1} alt="flecha" className="inline-block mr-8"/>
                        <img src={asset2} alt="flecha" className="inline-block mr-8"/>
                        <img src={asset3} alt="flecha" className="inline-block mr-8"/>
                    </div>
                    <div className="w-[50%] flex justify-end">
                        <img src={asset4} alt="flecha" className="inline-block ml-8"/>
                        <img src={asset5} alt="flecha" className="inline-block ml-8 w-[14px]"/>
                    </div>
                </div>
            </div>
        </main>
    );
};
