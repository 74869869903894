const PERMISSIONS = {
  SUPER_ADMIN: "super_admin",
  TICKETERA: "ticketera",
  SOCIAL_MEDIA: "social_media",
  ADMIN: "admin",
  REPORTS: "reports",
  EVENTS: "events",
};

Object.freeze(PERMISSIONS);

export const whiteList = [
  "ariel@wearebombo.com",
  "kevin.muter@wearebombo.com",
  "quimey.mata@wearebombo.com",
];

export default PERMISSIONS;
