import api from "./api";
import authHeader from "./authHeader";
import { IProducerResponse, IProducersResponse } from "../types/IProducer";
import { IFilterRequest } from "../types/IFilter";
import { IFilterProducerRequest } from "../types/IProducer";

const getAll = async (params: IFilterProducerRequest) => {
  const response = await api().get<IProducersResponse>(`producers/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<IProducerResponse>(
    `producers/detail/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<IProducerResponse>(
    `producers/create`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const update = async (id: string, item: FormData) => {
  const response = await api().put<IProducerResponse>(
    `producers/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<IProducerResponse>(
    `producers/delete/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const ProducerService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default ProducerService;
