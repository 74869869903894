import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { IoMailOpen } from "react-icons/io5";
import { BsCalendar4Range } from "react-icons/bs";
import { Title } from "../../../events/Title";
import { GoSearch } from "react-icons/go";
import UserService from "../../../../services/userService";
import { useMutation } from "@tanstack/react-query";
import { IFilterRequest } from "../../../../types/IFilter";
import { IProducerResponse } from "../../../../types/IProducer";
import { formatDate } from "../../../../utils/format-date";
import { FilterPage } from "../../../table/FilterPage";
import { FilterQtyPage } from "../../../table/FilterQtyPage";
import config from "../../../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows:[]
}
const defaultFilters = {
  limit: config.filter_default_limit,
  page:1,
  status: 'all',
}

export const UsersProducer = () => {
  const [ result, setResult ] = useState<IProducerResponse>(defaultResult);
  const[filters, setFilters] = useState<IFilterRequest>(defaultFilters);
 
  const loadItems = () => {
    getItems.mutateAsync().then(res=>setResult(res));
  }
  const getItems = useMutation(
    () => UserService.getAll(filters)
);
const handleFilter = async(filters: IFilterRequest) => {
  await setFilters(filters);
  getItems.mutateAsync().then(res=>setResult(res));
}
useEffect(() => {
  handleFilter(filters);
},[]);
  const columnData = [
    "Fecha de alta",
    "Nombre",
    "Email",
    "Rol",
    "Ultimo ingreso",
    ""
  ];

  const rowData = {
    fecha_alta: "19 Jun 2021-12:43:22",
    name: "Catalina Cortes",
    email: "catalina.cortes@gmail.com",
    rol: "Administrador",
    ultimoIngreso: "22 Jul 2022-23:15:22",
    status: "Activo"
  };
  return (
    <div>
      <Title>Usuarios</Title>
      <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4">
              <GoSearch className="cursor-pointer text-gray-100 ml-auto" size={16} />
            </th>
          </tr>
        </thead>

        <tbody>
          {result.rows && result.rows.map((_, i) => (
            <motion.tr
                key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{formatDate(_.created_at)}</td>
              <td className="">{_.first_name}</td>
              <td className="flex h-full items-center gap-2">
                {_.email}
              </td>
              <td className="">{_.user_role[0].toUpperCase() + _.user_role.substring(1)}</td>
              <td>{rowData.ultimoIngreso}</td>
              <td>
                <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                  <div className="rounded-full  bg-green p-[.35rem] " />
                  <p className="capitalize">{_.status === 'active' ? 'Activo' : 'Pendiente'}</p>
                </div>
              </td>
              <td className="pl-4">
                <button className="text-gray-100">
                  <BiDotsHorizontalRounded size={23} />
                </button>
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
      <FilterPage handleFilter={handleFilter} total={result.total} limit={filters.limit} status={filters.status} currentPage={filters.page}/>
          <FilterQtyPage handleFilter={handleFilter} status={filters.status}/>
      </div>
    </div>
  );
};
