import React, { useContext, useEffect, useState } from "react";
import { Title } from "../Title";
import { motion } from "framer-motion";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FilterPage } from "../../table/FilterPage";
import { FilterQtyPage } from "../../table/FilterQtyPage";
import { IoMailOpen } from "react-icons/io5";
import { BsCalendar4Range } from "react-icons/bs";
import { ITeamsResponse } from "../../../types/ITeam";
import { useMutation } from "@tanstack/react-query";
import TeamService from "../../../services/teamService";
import { getStatusFromValue } from "../../../utils/status";
import { getTeamRoleFromValue } from "../../../utils/roles";
import { formatDate } from "../../../utils/format-date";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import DropdownDots from "../../FormElement/DropdownDots";
import { FormTeam } from "./teams/FormTeam";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { IFilterRequest } from "../../../types/IFilter";
import { StateContext } from "../../../context/StateProvider";
import RrppService from "../../../services/rrppService";
import config from "../../../config/variables";

type TeamEventProps = {
  producerId?: string;
  eventId?: string;
  reloadEvent?: () => void;
  team_roles?: string | Array<string>;
  title?: string;
  canAssignTeam?: boolean;
  handleCreateEditItem?: (id?: string) => void;
};

const defaultFilters: IFilterRequest = {
  limit: config.filter_default_limit,
  page: 1,
  sortBy: "date_start",
  sortOrder: "asc",
};

export const TeamEvent = ({ producerId, eventId, reloadEvent = () => { }, team_roles = undefined, title = undefined, canAssignTeam = true, handleCreateEditItem = ()=>{} }: TeamEventProps) => {
  const { role, permissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const { event_id } = useParams();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [result, setResult] = useState<ITeamsResponse | null>(null);
  const [idTeam, setIdTeam] = useState<string>("");
  const [filters, setFilters] = useState<IFilterRequest>({
    ...defaultFilters,
    ...{ producer_producer_id: producerId, event_id: event_id },
  });
  // const event_id = eventId || null;
  const { state, dispatch } = useContext(StateContext);
  const getItems = useMutation((finalFilters: any) =>
    TeamService.getAll(finalFilters)
  );
  const deleteItems = useMutation((id: string) => TeamService.remove(id));
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (eventId || event_id) {
      loadItems({team_role: team_roles});
    }
  }, [showForm, team_roles]);

  const handleFormDisplay = async (id = "") => {
    await setIdTeam(id);
    setShowForm(!showForm);
    if (!showForm) loadItems();
  };

  const loadItems = async (newFilters?: any) => {
    let finalFilters = filters;
    if (newFilters) {
      finalFilters = {...filters, ...newFilters};
      await setFilters(finalFilters);
    }
    getItems.mutateAsync(finalFilters).then((res) => {
      setResult(res);
    });
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Está seguro de eliminar este registro?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync(filters).then((res) => setResult(res));
      });
    }
  };

  const handleCreateMember = () => {
    if (window.location.pathname.includes("admin/")) handleFormDisplay();
    if (window.location.pathname.includes("client/"))
      navigate("/client/rrpp/new-seller?event_id=" + event_id);
  };

  const handleMemberDetail = (id: string) => {
    console.log(id);
    if (auth.role == "admin") {
      navigate('/admin/events/'+event_id+'/rrpp/' + id);
    }
    else {
      navigate('/client/events/'+event_id+'/rrpp/' + id);
    }
  }

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({
      ...filters,
      ...filter,
      // ...{ producer_id: producerId, event_id: event_id },
    });
    await getItems.mutateAsync(null).then((res) => {
      console.log(res);
      setResult(res);
    });
  };

  const addTeamMember = () => {
    dispatch({ type: "addTeamMember", payload: { save: true } });
  };

  useEffect(() => {
    if (state.reloadEvent) {
      loadItems({event_id: event_id});
      reloadEvent();
    }
  }, [state.reloadEvent]);

  const columnData = [
    "Orden",
    "Fecha",
    "Nombre",
    "Email",
    "RRPP",
    "Precio",
    "Status",
    "",
  ];

  const rowData = {
    nombre: "Donat Twerski",
    rol: "Team Leader",
    email: "catalina.cortes@gmail.com",
    date: "08 Nov 2021",
    precio: "$3.450",
    status: "Activo",
  };
  return (
    <div>
      {showForm ? (
        <FormTeam
          handleCreateorEdit={handleFormDisplay}
          producerId={producerId}
          id={idTeam}
          event={"event"}
        />
      ) : (
        <>
          <div className="flex justify-between">
            <Title>{ title || 'Equipo'}</Title>
            {(role == "producer" || permissions.events) && canAssignTeam && (
              <button
                onClick={() => addTeamMember()}
                className="rounded-full border-[2px] border-black-100 px-10 py-2 font-bold  text-black-100 hover:bg-black-100 hover:text-white"
              >
                Asignar equipo
              </button>
            )}
            {team_roles?.includes('validator') && (
                <button
                    onClick={() => handleCreateEditItem()}
                    className="rounded-full border-[2px] border-black-100 px-10 py-2 font-bold  text-black-100 hover:bg-black-100 hover:text-white"
                >
                  Agregar Validador
                </button>
            )}
          </div>

          <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left">
            <tbody>
              {result?.rows?.map((_, i) => (
                <motion.tr
                  key={i}
                  layout
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0.2 }}
                  transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                  className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                >
                  <td className="pl-4 cursor-pointer" onClick={() => handleMemberDetail(_.user_id)}>
                    {_.user[0]
                      ? _.user[0].first_name + " " + _.user[0].last_name
                      : ""}
                  </td>
                  <td className="text-[#7B7B7B]">
                    {getTeamRoleFromValue(_.team_role)}
                  </td>
                  <td className="flex h-full items-center gap-2 text-[#7B7B7B]">
                    <IoMailOpen size={20} />
                    {_.user[0] ? _.user[0].email : ""}
                  </td>
                  <td className="text-[#7B7B7B]">
                    <div className="flex items-center gap-2">
                      <BsCalendar4Range />
                      {formatDate(_.createdAt)}
                    </div>
                  </td>

                  <td>
                    <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                      <div
                        className={`rounded-full  ${_.status === "active" ? "bg-green" : "bg-red"
                          } p-[.35rem]`}
                      />
                      <p className="capitalize">
                        {getStatusFromValue(_.status)}
                      </p>
                    </div>
                  </td>
                  <td className="pl-4">
                    {/*<button className="text-gray-100">*/}
                    {/*  <BiDotsHorizontalRounded size={23} />*/}
                    {/*</button>*/}
                    <DropdownDots
                      options={[
                        {
                          title: "Editar",
                          action: () => _.team_role == 'validator' ? handleCreateEditItem(_.user_id) : handleFormDisplay(_.id),
                          icon: <FaEdit size={23} />,
                          restricted: 'only_admin',
                        },
                        {
                          title: "Eliminar",
                          action: () => handleDelete(_.id),
                          icon: <FaTrashAlt size={23} />,
                        },
                      ]}
                    />
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
          {result?.rows.length == 0 && <p className="text-center">No existen miembros disponibles</p>}
          {result?.rows.length ? (
            <div className="flex justify-between pt-10">
              <FilterPage
                handleFilter={loadItems}
                total={result.total}
                limit={filters.limit}
                currentPage={filters.page}
              />
              <FilterQtyPage handleFilter={loadItems} />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
