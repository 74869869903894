export const teamRoles = [
    {value: 'leader', text: 'Líder'},
    // {value: 'manager', text: 'Manager'},
    {value: 'seller', text: 'Vendedor'},
    {value: 'validator', text: 'Validador'},
]

export const roles = [
    {value: 'artist', text: 'Artista'},
    {value: 'client', text: 'Cliente'},
]


export const getTeamRoleFromValue = (role: string) => {
    const result = teamRoles.find(item => item.value === role);
    return result ? result.text : role;
}
