import React, {useContext, useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import { Input } from "../NewEvent/Input";
import { Title } from "../Title";
import circleCheck from "../../../assets/circle-check.png";
import {useMutation} from "@tanstack/react-query";
import EventService from "../../../services/eventService";
import {StateContext} from "../../../context/StateProvider";
import {ISendTicketsRequest} from "../../../types/IEvent";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";
import {toast} from "react-toastify";
import FormInput from "../../FormElement/FormInput";
import FormSelect from '../../FormElement/FormSelect';

const schema = yup.object().shape({
  title: yup.string()
      .required('Campo requerido'),
  body: yup.string()
      .required('Campo requerido'),
/*   link: yup.string()
      .required('Campo requerido'), */
});

const defaultValues = {
  title: '',
  body: '',
  link: '',
  send_to: 'all',
};

const statusOptions: Array<object> = [
  {value: 'all', text: 'Todos'},
  {value: 'seller', text: 'Vendedores'},
  {value: 'leader', text: 'Lideres'},
  {value: 'artist', text: 'Artistas'},
  {value: 'validator', text: 'Validadores'},
  {value: 'user', text: 'Usuarios con tickets comprados'},
  {value: 'attend', text: 'Usuarios que asistirán'},
  {value: 'checkin', text: 'Usuarios que hicieron checkin'},
]
type SendNotificationsProps = {
  eventId: string;
}

export const SendNotificationsEvent = ({ eventId }: SendNotificationsProps) => {
  const [ showSuccess, setShowSuccess ] = useState<boolean>(false);
  const createItems = useMutation(
      (item: FormData) => EventService.sendNotifications(item),
  );

  const form = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const onSubmit = async(data: any) => {
    data.event_id = eventId;
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true
    });
    dispatch({ type: "showLoaderScreen", payload: true });
    createItems.mutateAsync(formData)
        .then(async res=>{
          dispatch({ type: "showLoaderScreen", payload: false });
          await setShowSuccess(!showSuccess);
          form.reset(defaultValues);
        })
        .catch((reason)=>{
          dispatch({ type: "showLoaderScreen", payload: false });
          if (Array.isArray(reason.response.data.message)) {
            for (let message of reason.response.data.message) {
              toast.error(message);
            }
          } else {
            toast.error(reason.response.data.message);
          }
        });
  };

  const onError = (error:any) => {
    console.log("error ",error);
  }

  const { state, dispatch} = useContext(StateContext);

  return (
    <div>
      {
        showSuccess ?
            <div className="flex flex-col gap-10 xl:px-[23rem]">
              <div>
                <img src={circleCheck} alt="check" className="text-center m-auto mb-4"/>
                <h1 className="text-center text-[20px] font-bold mb-4">Felicitaciones</h1>
                <p className="text-center">Notificaciones enviadas</p>
              </div>

              <div className="mb-16 flex justify-center gap-10 pt-10">
                <button
                    onClick={() => {
                      setShowSuccess(!showSuccess);
                    }}
                    className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium text-white"
                >
                  Enviar mas notificaciones
                </button>
              </div>
            </div>
            :
            <>
              <Title>Envío de mensajes</Title>
              <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit, onError)}  className="flex flex-col justify-center gap-10 pt-12 pb-20 xl:px-96 px-56">
                <FormSelect
                    name='send_to'
                    options={statusOptions}
                    label="Enviar notificación"
                />
                <FormInput
                    name="title"
                    type="text"
                    label="Titulo del mensaje"
                />
                <FormInput
                    name="body"
                    type="text"
                    label="Descripción del mensaje"
                />
               {/*  <FormInput
                    name="link"
                    type="text"
                    label="Link al que lleva ese mensaje"
                /> */}
                {/*<Input name="title" title="Título del mensaje"/>*/}
                {/*<Input name="body" title="Descripción del mensaje"/>*/}
                {/*<Input name="link" title="Link al que lleva ese mensaje"/>*/}
                {/*<div className="relative w-full">*/}
                {/*  <p className="absolute left-8 top-4 font-semibold text-gray-100">*/}
                {/*    Receptores*/}
                {/*  </p>*/}
                {/*  <input*/}
                {/*      type="text"*/}
                {/*      spellCheck={false}*/}
                {/*      className="h-56 w-full rounded-lg border border-colorBorder pt-6 pl-8 font-bold text-black-100  focus:border-none focus:outline-none"*/}
                {/*  />*/}
                {/*</div>*/}
                <button
                    type="submit"
                    className="m-auto mt-10 w-60 rounded-full border-[2px] border-black-100 bg-black-100 px-10 py-2  font-bold text-white ">
                  Enviar mensaje
                </button>
              </form>
              </FormProvider>
            </>
      }
    </div>
  );
};
