import React, { useState, useEffect, FC, useContext } from "react";
import { TitleSection } from "../TitleSection";
import { useNavigate } from "react-router-dom";
import { PictureAndName } from "../rrpp/components/PictureAndName/PictureAndName";
import { motion } from "framer-motion";
import Dropdown from "../FormElement/DropDown/index";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import UserService from "../../services/userService";
import { toast } from "react-toastify";
import { IUsersResponse } from "../../types/IUser";
import { IFilterRequest } from "../../types/IFilter";
import { formatDateTime } from "../../utils/format-date";
import { AuthContext } from "../../context/AuthContext";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { PageContainer } from "../PageContainer";
import { StateContext } from '../../context/StateProvider';
import Restricted from "../../context/Restricted";
import useComponentVisible from "../../hooks/use-component-visible.hook";
import ImagenIcono from '../../assets/iconoEventos.svg';
import DropdownDots from "../FormElement/DropdownDots";
import config from "../../config/variables";


const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};
const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  sortBy: "createdAt",
  sortOrder: "desc",
  user_role: ["coproducer"],
};

export const UserViewProducer = () => {
  const navigate = useNavigate();
  const { clientProducerId } = useContext(AuthContext);
  const getItems = useMutation(() => UserService.getAll(filters));
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const deleteItems = useMutation((id: string) => UserService.remove(id), {
    onSuccess(data: any) {
      toast.success(data.message);
    },
  });

  const [result, setResult] = useState<IUsersResponse>(defaultResult);
  const concatDefaultFilters = clientProducerId
    ? { ...defaultFilters, producer_id: clientProducerId }
    : defaultFilters;
  const [filters, setFilters] = useState<IFilterRequest>(concatDefaultFilters);
  const [search, setSearch] = useState("");

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Está seguro de eliminar al usuario?')) {
      await deleteItems.mutateAsync(id).then(res => handleFilter(filters)
      );
    }
  };

  const { state, dispatch } = useContext(StateContext);

  const handleEdit = (id: string, row: any) => {
    navigate(`/client/users/${id}/edit`);
    dispatch({ type: 'selectedProducers', payload: row.permitted_producers })
  };

  const columnData = [
    "Nombre",
    "Email",
    "Productoras",
    "Admin",
    "Reportes",
    "Eventos",
    "Última conexión",
    "",
  ];

  // const result = [
  //     { id: '1', name:"User 1", email:"marcos_foglia@gmail.com", admin:true, reportes:true , eventos:true, date:"12-10-2022 18:30hs."},
  //     { id: '2', name:"User 2", email:"marcos_foglia@gmail.com", admin:false, reportes:true , eventos:false, date:"12-10-2022 18:30hs."},
  //     { id: '3', name:"User 3", email:"marcos_foglia@gmail.com", admin:false, reportes:true , eventos:true, date:"12-10-2022 18:30hs."}
  //   ];

  // const handleDelete = (id: string) => { }

  return (
    <PageContainer>
      <div className="relative">
        <TitleSection>Permisos de acceso</TitleSection>
        <div className="absolute -top-0 right-0 ">
          <button
            onClick={() => navigate("/client/users/new-user")}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
          >
            Crear usuario
          </button>
        </div>
      </div>

      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-10 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th className="pb-4" key={column + index}>
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {result.rows.map((row) => (
            <motion.tr
              key={row.id}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">
                <PictureAndName
                  profilePicture={row.profile_picture}
                  firstName={row.first_name}
                  lastName={row.last_name}
                />
              </td>

              <td className="pl-4 text-sm text-[#7B7B7B]">{row.email}</td>
              <td className="pl-4 text-sm text-[#7B7B7B]">
                <div className="flex items-center">
                  <p className="pr-[10px]">{row.permitted_producers.length}</p>
                  <Dropdown options={row.permitted_producers} />
                </div>
              </td>
              {row.permissions?.admin ? (
                <td className="pl-4 text-sm">Si</td>
              ) : (
                <td className="pl-4 text-sm text-[#989898]">No</td>
              )}
              {row.permissions?.reports ? (
                <td className="pl-4 text-sm">Si</td>
              ) : (
                <td className="pl-4 text-sm text-[#989898]">No</td>
              )}
              {row.permissions?.events ? (
                <td className="pl-4 text-sm">Si</td>
              ) : (
                <td className="pl-4 text-sm text-[#989898]">No</td>
              )}
              <td className="pl-4 text-sm text-[#7B7B7B]">
                {formatDateTime(row.updatedAt)}
              </td>
              <td>
                <DropdownDots
                  options={[
                    {
                      title: "Editar",
                      action: () => handleEdit(row.id, row),
                      icon: <FaEdit size={23} />,
                    },
                    {
                      title: "Eliminar",
                      action: () => handleDelete(row.id),
                      icon: <FaTrashAlt size={23} />,
                    },
                  ]}
                />
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>

      {result.rows.length ? <div className="flex justify-between py-10">
        <FilterPage handleFilter={handleFilter} total={result.total} limit={filters.limit} status={filters.status} currentPage={filters.page} />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div> : <p className="text-center">No existen usuarios disponibles</p>}

    </PageContainer>
  );
};
