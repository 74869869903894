import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import ClientService from '../../services/clientService';
import { Back } from '../Back';
import FormInput from '../FormElement/FormInput';
import { useEffect, useContext} from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { IClientRequest } from '../../types/IClient';
import {AuthContext} from "../../context/AuthContext";
import account from "../../assets/account.png";

const schema = yup.object().shape({
    first_name: yup.string()
        .required('El nombre es requerido'),
    last_name: yup.string(),
    email: yup.string()
        .required('El email es requerido')
        .email('El correo electrónico no es válido'),
    password: yup.string()
        .required('El password es requerido'),

});

const schemaEdit = yup.object().shape({
    first_name: yup.string()
        .required('El nombre es requerido'),
    last_name: yup.string()
        .required('El apellido es requerido'),
    email: yup.string()
        .required('El email es requerido')
        .email('El correo electrónico no es válido'),
    password: yup.string(),

});

const defaultValues = {
    first_name: '',
    last_name: '',
    nickname: '',
    email: '',
    password: '',
    picture: '',
    user_role: 'producer'
};

const statusOptions: Array<object> = [
    {value: 'pending', text: 'Pendiente'},
    {value: 'active', text: 'Activo'},
    {value: 'paused', text: 'Pausado'},
]

type typeProps = {
    handleCreateorEdit?: (id:string) => void;
    id?: string;
};

export const ClientProfile = () => {
    const {role, user} = useContext(AuthContext);
    const {client_id} = useParams();
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const getUserApi = useMutation(
        () => ClientService.get(user.id)
    );

    const form = useForm<IClientRequest>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });
    useEffect(() => {
        getUserApi.mutateAsync().then(res=>form.reset(res));
    },[user]);

    const onSubmit = async(data: IClientRequest) => {
    }
    const onError = (error:any) => {
        console.log("error ",error);
    }

    return (
        <div className="">
            <Back onClick={() => navigate("/client/producers")}/>
            <div className="flex flex-col gap-10 xl:px-[23rem]">
                <h1 className="text-center text-3xl font-bold">Perfil</h1>
                <div>
                    <img
                        src={user.profile_picture ? user.profile_picture : account}
                        alt="profile-picture" className="m-auto rounded-full"
                        style={{width: 128, height: 128, objectFit: 'cover'}}
                    />
                </div>
                <FormProvider {...form}>
                    <form  onSubmit={form.handleSubmit(onSubmit, onError)}>
                        <FormInput
                            name="first_name"
                            type="text"
                            label="Nombre"
                            disabled={true}
                        />
                        <FormInput
                            name="last_name"
                            type="text"
                            label="Apellidos"
                            disabled={true}
                        />
                        <FormInput
                            name="email"
                            type="text"
                            label="Correo"
                            disabled={true}
                        />
                    </form>
                </FormProvider>
            </div>
        </div>
    );
}
