const regex = /\b(dev|stg)\b/;
export default {
  sentry_dns: import.meta.env.VITE_SENTRY_DNS,
  api_admin_url: import.meta.env.VITE_API_ADMIN_URL,
  api_url: import.meta.env.VITE_API_URL,
  store_url: import.meta.env.VITE_URL_STORE,
  api_key_google_maps: "AIzaSyA9URe6ee2ipLL4qsk7hXflj5PB_6BAWB0",
  reports_url: import.meta.env.VITE_BACKOFFICE_REPORTS_URL,
  session: {
    tokenName: "admin_cms_tkn",
    clientProducerID: "client_producer_id",
  },
  // awsS3: {
  //     accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
  //     secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
  //     bucket: import.meta.env.VITE_AWS_S3_BUCKET,
  //     region: import.meta.env.VITE_AWS_REGION,
  //     proxy: import.meta.env.VITE_S3_PROXY,
  // }
  interval_ms_users_counter: import.meta.env.VITE_INTERVAL_MS_USERS_COUNTER,
  filter_default_limit: 20,
  getValues() {
    return {
      api_admin_url: this.api_admin_url,
      api_url: this.api_admin_url,
      store_url: this.store_url,
      api_key_google_maps: "AIzaSyA9URe6ee2ipLL4qsk7hXflj5PB_6BAWB0",
      session: {
        tokenName: "admin_cms_tkn",
        clientProducerID: "client_producer_id",
      },
      // awsS3: {
      //     accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
      //     secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
      //     bucket: import.meta.env.VITE_AWS_S3_BUCKET,
      //     region: import.meta.env.VITE_AWS_REGION,
      //     proxy: import.meta.env.VITE_S3_PROXY,
      // }
      interval_ms_users_counter: this.interval_ms_users_counter,
      filter_default_limit: 20,
    };
  },
  switch(key: string) {
    const keyTo = key === "dev" ? "stg" : "dev";
    this.api_admin_url = this.api_admin_url.replace(key, keyTo);
    this.api_url = this.api_url.replace(key, keyTo);
  },
  appEnv() {
    const match = this.api_url.match(regex);
    const key = match ? match[0] : null;
    if (!key) return;
    const label = key === "dev" ? "DEVELOPMENT" : "STAGING";
    const color = key === "dev" ? "orange" : "red";
    return { key, label, color };
  },
};
