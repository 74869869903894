import React, { useEffect, useState, useContext, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoDeviceCameraVideo } from "react-icons/go";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";

import { Back } from "../../Back";
import { INewsRequest } from "../../../types/INews";
import NewsService from "../../../services/newsService";
import FormCropperImage from "../../FormElement/FormCropperImage";
import FormInput from "../../FormElement/FormInput";
import FormSelect from "../../FormElement/FormSelect";
import FormCheck from "../../FormElement/FormCheck";
import FormRadio from "../../FormElement/FormRadio";
import { StateContext } from "../../../context/StateProvider";
import { generateImageDefaultFromUrlYoutube } from "../../../utils/form";
import { ModalLink } from "../../events/selectedEvent/multimedia/ModalLink";
import './styles.css';

const imageValidation = yup
  .mixed()
  .test("size", "Tamaño permitido menor a 20MB", (value) => {
    if (value instanceof File) {
      return value.size <= 20000000;
    }
    return true;
  });

const imageRequired = yup
  .mixed()
  .test("required", "Campo requerido", (value) => {
    return value ? true : false;
  });

const schema = yup.object().shape({
  title: yup.string().required("El titulo es requerido"),
  status: yup.string().oneOf(['active','paused'],'Debe ser Activo o Pausado').required("El estado es requerido"),
  type: yup.string().oneOf(['Novedad','Rumor'], 'Debe ser Novedad o Rumor').required("El tipo es requerido"),
  picture: imageValidation,
  home_banner: imageValidation.concat(imageRequired),
});

const schemaEdit = yup.object().shape({
  title: yup.string().required("El titulo es requerido"),
  status: yup.string().oneOf(['active','paused'],'Debe ser Activo o Pausado').required("El estado es requerido"),
  type: yup.string().oneOf(['Novedad','Rumor'], 'Debe ser Novedad o Rumor').required("El tipo es requerido"),
  picture: imageValidation,
  home_banner: imageValidation.concat(imageRequired),
});

const statusOptions: Array<object> = [
  {value: '', text: 'Elegir una opción'},
  {value: 'active', text: 'Activo'},
  {value: 'paused', text: 'Pausado'},
];

const defaultValues = {
  title: '',
  description: '',
  status: '',
  type: '',
  picture: '',
  home_banner: '',
  hide_title: "false",
  is_hidden: "false"
};

const newsOptions =[
  {value: "Novedad", label: "Novedad"},
  {value: "Rumor", label: "Rumor"},
];

export const FormNews = ({}) => {
  const navigate = useNavigate()
  const {id} = useParams();
  const { state, dispatch} = useContext(StateContext);
  
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [description, setDescription] = useState('');
  const [multimedia, setMultimedia] = useState<Array<any>>([]);
  const [showModalLink, setShowModalLink] = useState(false);
  
  const createItems = useMutation(
      (item: FormData) => NewsService.create(item),
      );
  const updateItems = useMutation(
      (item: FormData) => NewsService.update(id, item),
  );
  const getUserApi = useMutation(
      (id: string) => NewsService.get(id)
  );
  const createLink = useMutation(
      (item: FormData) => NewsService.uploadFile(item),
  );

  const handleBody = (e: React.SetStateAction<string>) =>{
    setDescription(e);
  };

  const form = useForm<INewsRequest>({
    resolver: yupResolver(id ? schemaEdit : schema),
    defaultValues: defaultValues,
    mode: 'all'
  });

  useEffect(() => {
    if (id) {
      getUserApi.mutateAsync(id)
      .then((res) => {
        setDescription(res.description.replace(/<[^>]+>\t/g , '').replace(/["']/g, ""));
        form.reset(res);
        if (res.medias) setMultimedia(res.medias);
        });
    }
  }, [form.reset]);


  const onSubmit = async(data: INewsRequest) => {
    const newData = {
      ...data,
      hide_title: data.hide_title === "true" || data.hide_title === true,
      is_hidden: data.is_hidden === "true" || data.is_hidden === true,
      description
    }

    if (!id && multimedia.length) newData.multimedia = multimedia;
    setDisabledSubmit(true);

    let formData = jsonToFormData(newData, {
      arrayIndexes: true,
      excludeNull: true
    });

    dispatch({ type: "showLoaderScreen", payload: true });
    if(id){
      await updateItems.mutateAsync(formData)
        .then(res=>{
          setDisabledSubmit(false);
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
        })
        .catch((reason) => {
          setDisabledSubmit(false)
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "toastError", payload: "Error en los datos ingresados" });
        });
    } else {
      await createItems.mutateAsync(formData).then(res=>{
        setDisabledSubmit(false);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Registro creado correctamente" });
      })
      .catch((reason) => {
        setDisabledSubmit(false)
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastError", payload: "Error en los datos ingresados" });
      });
    }

    navigate("/admin/news");
  };

  const deleteItems = useMutation((id: string) => NewsService.removePicture(id));
  const handleDeleteMedia = (multimediaId: string) => {
      if (window.confirm('Está seguro de eliminar este archivo?')) {
          dispatch({ type: "showLoaderScreen", payload: true });
          if (id) {
              deleteItems.mutateAsync(multimediaId).then((r: any) => {
                  setMultimedia(removeObjectWithId(multimedia, multimediaId));
                  dispatch({type: "toastSuccess", payload: "Archivo eliminado correctamente"});
                  dispatch({type: "showLoaderScreen", payload: false});
              });
          } else {
              setMultimedia(removeObjectWithId(multimedia, multimediaId));
              dispatch({type: "showLoaderScreen", payload: false});
          }
      }
  }

  function removeObjectWithId(arr: Array<any>, id: string) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  const onError = (error: any) => {
    console.error("error: ", error);
  }

  const handleCloseModalLink = () => {
    setShowModalLink(false);
  }

  const handleUploadLink = (link: string) => {
    setShowModalLink(false);
    if(link){
      if (id) {
        const req = {
            picture: link,
            news_id: id,
            media_type: "link"
        }

        let formData = jsonToFormData(req, {
            arrayIndexes: true,
            excludeNull: true
        });

        dispatch({type: "showLoaderScreen", payload: true});
        createLink.mutateAsync(formData).then((res: any) => {
          const newItem = {
            picture_url: res.picture_url,
            id: res._id,
            media_type: "link"
          }
          setMultimedia([...multimedia, newItem]);
          dispatch({type: "toastSuccess", payload: "Link subido correctamente"});
          dispatch({type: "showLoaderScreen", payload: false});
        })
          .catch((reason: any) => {
              dispatch({type: "showLoaderScreen", payload: false});
              dispatch({type: "toastError", payload: "El link no pudo subirse correctamente"});
          });
    } else {
        const req = {
          id: `${Date.now()}`,
          picture_url: link,
          media_type: "link"
        }
        console.info('req', req)
        setMultimedia([...multimedia, req]);
      }
    } else {
      dispatch({ type: "toastError", payload: "El link esta vacío" });
    }
  }

  return (
    <div className="">
      {showModalLink && <ModalLink handleCloseModal={handleCloseModalLink} handleUpload={handleUploadLink} />}
      <Back onClick={() => navigate("/admin/news")} />

      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">{ id ? 'Editar' : 'Crear nueva' } Novedad</h1>
        <FormProvider {...form}>
          <form  onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormInput
              name="title"
              type="text"
              label="Titulo"
            />

            <FormCheck name="hide_title" label="" options={[{ value: "true", label: 'Ocultar título' }]} />
            <FormCheck name="is_hidden" label="" options={[{ value: "true", label: 'Ocultar novedad' }]} />

            <div id= 'quill-custom' className="mb-6 pt-3">
              <ReactQuill
                placeholder="Ingrese su descripción..."
                theme="snow"
                value={description}
                onChange={handleBody}
              />
            </div>

            <div className=" w-full py-4 space-x-2 flex" >
              <div className="w-1/2">
                <FormInput name="order" type="number" label="Número de orden"/>
              </div>
              <div className="w-1/2">
                <FormSelect
                  name='status'
                  options={statusOptions}
                  label="Estado de la novedad"
                />
              </div>
            </div>

            <div className=" w-full py-4">
              <FormRadio name="type" label="Tipo" options={newsOptions}/>
            </div>

            <div className="flex justify-center gap-10 ">
              <FormCropperImage
                name="picture"
                label="Imagen (Tamaño: 1000 x 1000)"
                accept="image/png, image/gif, image/jpeg"
                initialAspectRatio={1}
                cropBoxResizable={false}
                dragMode="move"
                maxWidth={1000}
                maxHeight={1000}
              />
              <FormCropperImage
                name="home_banner"
                label="Banner home (Tamaño: 920 x 480)"
                accept="image/png, image/gif, image/jpeg"
                initialAspectRatio={920 / 480}
                cropBoxResizable={true}
                dragMode="move"
                maxWidth={920}
                maxHeight={480}
              />
            </div>

            <div className="w-full py-4 mt-4">
              <p className="left-8 top-4 font-semibold text-gray-100">
                Links
                <button className="flex justify-center items-center text-[#3E7EFF]" type="button" onClick={() => setShowModalLink(true)}>
                  <GoDeviceCameraVideo className="mr-1" />
                  <strong>Cargar Link de Youtube</strong>
                </button>
              </p>
              <div className="flex w-full flex-wrap gap-5 pt-10">
                {multimedia.map((_, i) =>
                  <div
                    key={i}
                    className="relative w-36 h-56 rounded-xl"
                  >
                    <div className="cursor-pointer flex items-center justify-center py-2 font-bold bg-black-100 text-white  hover:bg-white hover:text-black-100 hover:border-[2px] hover:border-black-100" onClick={()=>handleDeleteMedia(_.id)}>
                        Eliminar
                    </div>
                    {
                      _.media_type.includes('link') ?
                          (
                            <div
                              className="flex w-36 h-full justify-center items-center bg-gray-200"
                              style={{ backgroundImage: `url(${generateImageDefaultFromUrlYoutube(_.picture_url) || 'https://img.youtube.com/vi/%3Cyoutube-video-id%3E/mqdefault.jpg' })`, backgroundSize: "cover" , backgroundPosition: "top center", backgroundRepeat: "no-repeat", cursor: 'pointer' }}
                              onClick={()=>{window.open(String(_.picture_url))}}
                            >
                              <p className="text-2xl font-medium text-gray-100 text-center">link</p>
                            </div>
                          )
                          : <Fragment/>
                  }
                  </div>
                )}
              </div>
            </div>

            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type= "submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                disabled={disabledSubmit}
              >
                {id ? 'Guardar Novedad' : 'Publicar Novedad'}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};