import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { StateProvider } from "./context/StateProvider";
import Router from "./router";
import "./styles/input.css";
import AuthProvider from "./context/AuthProvider";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import PermissionProvider from "./context/PermissionProvider";
import * as Sentry from "@sentry/react";
import variables from "./config/variables";

Sentry.init({
  dsn: variables.sentry_dns,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^\/\/wearebombo\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
});
const EnvLayout = ({ children }: any) => {
  const [env, setEnv] = useState<any>(variables.appEnv());
  if (!env) return <>{children}</>;
  return (
    <>
      <div
        className={`flex w-full items-center justify-evenly bg-${env.color} p-2 text-center font-bold text-white`}
      >
        <div className="flex justify-center">{env.label}</div>
        {
          <span
            className="cursor-pointer rounded-full bg-black-100 px-4 py-2 text-white"
            onClick={() => {
              variables.switch(env.key);
              setEnv(variables.appEnv());
            }}
          >
            SWITCH ENVIRONMENT
          </span>
        }
      </div>
      <div>{children}</div>
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <EnvLayout>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <PermissionProvider>
          <StateProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </StateProvider>
        </PermissionProvider>
      </QueryClientProvider>
    </AuthProvider>
  </EnvLayout>
);
