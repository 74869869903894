import axios from "axios";
import config from "../config/variables";

export const staticApi = axios.create({
  baseURL: config.getValues().api_admin_url,
  headers: {
    "Content-type": "application/json",
  },
});

const api = () =>
  axios.create({
    baseURL: config.getValues().api_admin_url,
    headers: {
      "Content-type": "application/json",
    },
  });

export default api;
