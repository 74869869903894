import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FaTrashAlt } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { TitleSection } from "../TitleSection";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { INotificationResponse, INotificationsResponse } from "../../types/INotification";
import { IFilterRequest } from "../../types/IFilter";
import { AuthContext } from "../../context/AuthContext";
import NotificationService from "../../services/notificationService";
import { formatDate } from "../../utils/format-date";
import { getStatusFromValue } from "../../utils/status";
import { PageContainer } from "../PageContainer";
import config from "../../config/variables";

type TableProducersProps = {
  handleCreateClient: ()=>void;
  handleSelect: (data?: INotificationResponse)=>void;
}

type rowProps = {
  rowData: {
    date: string;
    name: string;
    email: string;
    status: string;
  };
  qty?: number;
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows:[]
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page:1,
  status: 'all',
  query: '',
  sortBy: 'createdAt'
};

const columnData = [
  "Fecha",
  "Título",
  "Mensaje",
  "Status",
  "Acciones"
];

export const NotificationsView = () => {
  const navigate = useNavigate()
  const { role } = useContext(AuthContext);
  
  const getItems = useMutation(
    () => NotificationService.getAll(filters)
  );
  const deleteItems = useMutation(
    (id: string) => NotificationService.remove(id)
  );

  const[result, setResult] = useState<INotificationsResponse>(defaultResult);
  const[filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const[artistColumns, setArtistColumns] = useState<Array<string>>([]);
  const[search, setSearch] = useState("")
  
  const searcher = async (e: any) => {
    setSearch(e.target.value);
    await setFilters({ ...filters, page: 1, query: e.target.value });
    await getItems.mutateAsync().then(res => {
      setResult(res);
    });
  }

  let resultado = [];
  resultado = result.rows;

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({...filters, ...filter});
    await getItems.mutateAsync().then(res=>setResult(res));
  }

  const handleDelete = async (id: string) => {
    if (window.confirm('Está seguro de eliminar esta notificación?')) {
      await deleteItems.mutateAsync(id).then(res=>{
        getItems.mutateAsync().then(res=>setResult(res));
      });
    }
  }

  const handleNavigate = () => {
    navigate("/admin/notifications/add");
  }

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Notificaciones</TitleSection>
        <div className="flex gap-2">
          <input type="text" placeholder="Search..."  className="rounded-full border-[2px] border-black-100 px-4 py-1 font-bold text-black-100  hover:text-black" value={search} onChange={searcher} />
          <button
            onClick={handleNavigate}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
          >
            Crear nueva notificación
          </button>
        </div>
      </div>
      <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
        <thead className=" divide-solid border ">
        <tr className=" pb-4 text-sm font-bold text-gray-100">
          {columnData.map((column, index) => (
            <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
          ))}
          {artistColumns.map((column, index) => (
            <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {resultado.map((row, i) => (
          <motion.tr
            key={i}
            layout
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0.2 }}
            transition={{ type: "spring", stiffness: 100, bounce: 0 }}
            className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
          >
            <td className="pl-4">{formatDate(row.createdAt)}</td>
            <td className="pl-4">{row.title}</td>
            <td className="pl-4">{row.message}</td>
            <td className="pl-4">
              <div className="flex w-32 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                <div className={`rounded-full  ${row.status === 'sent' || row.status === 'send' ? 'bg-green' : 'bg-red'} p-[.35rem]`} />
                <p className="capitalize">{getStatusFromValue(row.status)}</p>
              </div>
            </td>
            <td className="pl-4">
              {
                role=="admin" && (
                  <DropdownDots options={[
                    {title: 'Eliminar', action: ()=>handleDelete(row.id), icon: (<FaTrashAlt size={23} />)}
                  ]}/>
                )
              }
            </td>
          </motion.tr>
        ))}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage handleFilter={handleFilter} total={result.total} limit={filters.limit} status={filters.status} currentPage={filters.page}/>
        <FilterQtyPage handleFilter={handleFilter} status={filters.status}/>
      </div>
    </PageContainer>
  );
};
