import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion"
import { FaRedo, FaTrashAlt } from "react-icons/fa"
import { GoSearch } from "react-icons/go"
import { toast } from "react-toastify";
import TicketService from "../../../services/ticketService";
import { ITicketsResponse } from "../../../types/ITicket";
import DropdownDots from "../../FormElement/DropdownDots"

type selectedProps = {
    // id: string;
    data: ITicketsResponse;
    updateData: (id: string) => void;
  };

const columnData = [
    "ID",
    "Nombre",
    "Descripcion",
    "Nombre del Evento",
    "Stock",
];

export const TicketRecycler = ({data, updateData}: selectedProps) => {

    const deleteItems = useMutation(
        (id: string) => TicketService.remove(id), {
            onSuccess(data: any) {
                // toast.success(data.message);
                toast.success("Entrada eliminado exitosamente");
            }
        }
    );

    const handleDelete = async (id: string) => {
        if (window.confirm('Esta seguro de eliminar este usuario?')) {
            await deleteItems.mutateAsync(id).then(res => {
                // getItems.mutateAsync().then(res => setResult(res));
                updateData('tickets');
            })
        }
    }

    const handleRestoreUser = async (id: any) => {

        const formData = new FormData();
        formData.append('is_deleted', 'false');
        formData.append('id', id);

        await TicketService.update(id, formData)
            .then(res => {
                // getItems.mutateAsync().then(res => setResult(res));
                updateData('tickets');
            })
    }

    return (
        <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
                <thead className=" divide-solid border ">
                    <tr className=" pb-4 text-sm font-bold text-gray-100">
                        {columnData.map((column, index) => (
                            <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
                        ))}
                        <th className="border-b-[1px] border-colorBorder pb-4">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.rows.length ? data.rows.map((item, i) => (
                        <motion.tr
                            key={item.id}
                            layout
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0.2 }}
                            transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                            className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                        >
                            <td className="pl-4">{`#${item.id}`}</td>
                            <td className="pl-4">{item.name}</td>
                            <td className="pl-4">{item.description}</td>
                            <td className="pl-4">{item.name_event}</td>
                            <td className="pl-4">{item.stock}</td>
                            <td className="pl-4">
                                <DropdownDots options={[
                                    { title: 'Eliminar', action: () => handleDelete(item.id), icon: (<FaTrashAlt size={23} className="mx-2" key={item.id} />) },
                                    { title: 'Restaurar', action: () => handleRestoreUser(item.id), icon: (<FaRedo size={23} className="mx-2" key={item.id} />) }
                                ]} />
                            </td>
                        </motion.tr>
                    )) : <></>}
                </tbody>
            </table>
    )
}
