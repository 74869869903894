import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion"
import { FaRedo, FaTrashAlt } from "react-icons/fa"
import { GoSearch } from "react-icons/go"
import { toast } from "react-toastify";
import UserService from "../../../services/userService";
import { IUsersResponse } from "../../../types/IUser";
import { formatDate, formatDateTime } from "../../../utils/format-date";
import DropdownDots from "../../FormElement/DropdownDots"

type selectedProps = {
    // id: string;
    data: IUsersResponse;
    updateData: (filter: string) => void;
  };

const columnData = [
    "ID",
    "Nombre",
    "Apodo",
    "Email",
    "Entradas",
    "Cliente Desde",
    "Eliminado el",
];

export const UserRecycler = ({data, updateData}: selectedProps) => {

    const deleteItems = useMutation(
        (id: string) => UserService.remove(id), {
            onSuccess(data: any) {
                // toast.success(data.message);
                toast.success("Usuario eliminado exitosamente");
            }
        }
    );

    const handleDelete = async (id: string) => {
        if (window.confirm('Esta seguro de eliminar este usuario?')) {
            await deleteItems.mutateAsync(id).then(res => {
                // getItems.mutateAsync().then(res => setResult(res));
                updateData('users');
            })
        }
    }

    const handleRestoreUser = async (id: any, user_role: any) => {

        const formData = new FormData();
        formData.append('is_deleted', 'false');
        formData.append('isUserRestore', 'true')
        formData.append('id', id);
        formData.append('user_role', user_role);

        await UserService.update(id, formData)
            .then(res => {
                // getItems.mutateAsync().then(res => setResult(res));
                updateData('users');
            })
    }

    return (
        <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
                <thead className=" divide-solid border ">
                    <tr className=" pb-4 text-sm font-bold text-gray-100">
                        {columnData.map((column, index) => (
                            <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
                        ))}
                        <th className="border-b-[1px] border-colorBorder pb-4">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.rows.length ? data.rows.map((_, i) => (
                        <motion.tr
                            key={_.id}
                            layout
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0.2 }}
                            transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                            className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                        >
                            <td className="pl-4">{`#${_.id}`}</td>
                            <td className="pl-4">{`${_.first_name} ${_.last_name}`}</td>
                            <td className="pl-4">{`${_.nickname ? _.nickname : ""}`}</td>
                            <td className="pl-4">
                                {_.email}
                            </td>
                            <td className="pl-4">
                                {/* <div className="cursor-pointer flex items-center gap-2">{_.order_tickets?.map(e=>e?.qty)}</div> */}
                                <div className="cursor-pointer flex items-center gap-2">{_.order_tickets?.length}</div>
                            </td>
                            <td className="pl-4">{formatDate(_.created_at)}</td>
                            <td className="pl-4">{formatDateTime(_.updatedAt, "-03:00")}</td>
                            <td className="pl-4">
                                <DropdownDots options={[
                                    { title: 'Eliminar', action: () => handleDelete(_.id), icon: (<FaTrashAlt size={23} className="mx-2" key={_.id} />) },
                                    { title: 'Restaurar', action: () => handleRestoreUser(_.id, _.user_role), icon: (<FaRedo size={23} className="mx-2" key={_.id} />) }
                                ]} />
                            </td>
                        </motion.tr>
                    )) : <></>}
                </tbody>
            </table>
    )
}
