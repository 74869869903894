import React from 'react';
import { Navigate } from "react-router-dom";

import useRoutePermission from "../hooks/use-route-permission";

type Props = {
  to: string;
  children: React.ReactNode;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const ProtectedRoute: React.FunctionComponent<Props> = ({ to, children }) => {

  // We "connect" to the provider thanks to the permission hook
  const allowed = useRoutePermission(to);

  // If the user has that permission, render the children
  if (allowed) {
      return <>{children}</>;
  }

  console.log('Not allowed');

  // Otherwise, redirects to "/"
  return <Navigate to="/" />;
};

export default ProtectedRoute;
