import React from "react";
import { status } from "../interfaces/Table";
import {getStatusFromValue} from "../utils/status";

type statusProps = {
  status: string;
};

export const Status = ({ status }: statusProps) => {
  return (
    <div className="flex w-30 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder px-10 py-2 text-center ">
      <div className={`rounded-full ${['activo','active','finished'].includes(status) ? 'bg-green' : 'bg-red'} p-[.35rem] `} />
      <p className="capitalize">{getStatusFromValue(status)}</p>
    </div>
  );
};
