import { FC, useContext } from "react";
import { NavLink } from "react-router-dom";
import editIcon from "../../../../assets/editIcon.svg";
import alarm from "../../../../assets/alarm.svg";
import duplicateTicket from "../../../../assets/duplicate-ticket.svg";
import deleteIcon from "../../../../assets/trash.svg";
import { FaTrashAlt } from "react-icons/fa";
import { ITicketResponse } from "../../../../types/ITicket";
import { useMutation } from "@tanstack/react-query";
import TicketService from "../../../../services/ticketService";
import { formatMoney2 } from "../../../../utils/format-money";
import { getPaymentTypeFromValue } from "../../../../utils/payments";
import { AuthContext } from "../../../../context/AuthContext";
import { StateContext } from "../../../../context/StateProvider";
import { Status } from "../../../Status";

type typeProps = {
  handleFormDisplay: (id: string) => void;
  loadItems: () => void;
  data: ITicketResponse;
};

export const CardTicket: FC<typeProps> = ({
  handleFormDisplay,
  loadItems,
  data,
}) => {
  const styles = {
    h3: "font-bold text-lg",
    p: "text-gray-100",
  };
  const { role } = useContext(AuthContext);

  const deleteItem = useMutation((id: string) => TicketService.remove(id));

  const handleDelete = (id: string) => {
    deleteItem.mutateAsync(id).then((res) => loadItems());
    dispatch({
      type: "toastSuccess",
      payload: "El registro ha sido eliminado",
    });
  };

  const handleSendRecycler = (id: string) => {
    recyclerItems.mutateAsync(id).then((res) => loadItems());
    // dispatch({ type: "toastSuccess", payload: "El registro ha sido enviado a la papelera de reciclage" });
  };

  const recyclerItems = useMutation(
    (id: string) => TicketService.sendRecycler(id),
    {
      onSuccess(data: any) {
        // toast.success(data.message);
        dispatch({
          type: "toastSuccess",
          payload: "El Ticket fue enviado a la papelera de reciclaje.",
        });
      },
    }
  );

  const { state, dispatch } = useContext(StateContext);

  const handleValidation = (
    id: string,
    stock: number,
    total: number,
    type: boolean
  ) => {
    console.log("llego: ", id, "/", stock);
    if (stock < total) {
      dispatch({
        type: "toastError",
        payload:
          "Existen tickets vendidos !!! Usted no puede editar o eliminar los datos.",
      });
    } else {
      if (type) {
        handleFormDisplay(data.id);
      } else {
        if (
          window.confirm("Esta seguro de enviar a la papelera este ticket?")
        ) {
          // handleDelete(data.id)
          handleSendRecycler(data.id);
        }
      }
    }
  };

  return (
    <div className="flex justify-between rounded-xl px-5 pb-7 pt-8 outline outline-2 outline-colorBorder">
      <section className="flex w-[70%] flex-col gap-5">
        <article className="">
          <h3 className={styles.h3}>{data.name}</h3>
          <p className={styles.p}> {data.description} </p>
        </article>
        <article className="flex gap-20">
          <div>
            <h3 className={styles.h3}>{formatMoney2(data.price)} </h3>
            <p className={styles.p}>Precio</p>
          </div>
          <div>
            <h3 className={styles.h3}>
              {data.available_stock < 0 ? 0 : data.available_stock} /{" "}
              {data.stock}
            </h3>
            {/* <p className={styles.p}>Disponible/Stock</p> */}
          </div>
        </article>
        {data.type && data.type !== "Free" && (
          <div>
            <h3 className={styles.h3}>
              {getPaymentTypeFromValue(data.payment_type || "all")}
            </h3>
            <p className={styles.p}>Medio de pago</p>
          </div>
        )}
        {data?.status === "pending_approval" && (
          <div className="mr-2 pt-2">
            <Status status={data.status} />
          </div>
        )}
      </section>
      <div className="flex items-baseline gap-1">
        <NavLink to={`?section=tickets&ticket_id=${data.id}`}>
          <img
            className="h-[20px] w-[20px] cursor-pointer"
            src={
              data?.status === "pending_approval" && role === "admin"
                ? alarm
                : editIcon
            }
            onClick={() => handleFormDisplay(data.id)}
          />
        </NavLink>
        <NavLink to={`?section=tickets&ticket_id=${data.id}&action=duplicate`}>
          <img
            className="h-[20px] w-[20px] cursor-pointer"
            src={duplicateTicket}
            onClick={() => handleFormDisplay(data.id)}
          />
        </NavLink>
        <img
          className="h-[20px] w-[20px] cursor-pointer"
          src={deleteIcon}
          onClick={() =>
            handleValidation(data.id, data.available_stock, data.stock, false)
          }
        />
      </div>
    </div>
  );
};
