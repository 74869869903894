import { Fragment, useEffect, useRef, useState, KeyboardEvent } from "react"
import {AiOutlineSearch} from "react-icons/ai"

const getOptionClass = (index:number, length: number, cursor: number) => {
    let className = "hover:bg-gray-200 px-4 ";
    if(index === 0) className += "py-2 rounded-t-lg";
    else if (index === -1) className += "py-2 rounded-b-lg";
    else if (index ===0 && length === 1) className += "py-2 rounded-b-lg";
    else className += "py-2 rounded-b-lg";
    if(index === cursor) className += " bg-gray-200";
    return className;
  }

  type typeProps = {
    value: string,
    onChange: (option: string) => void,
    handleChange: (data: any) => void,
    content:string
  }

export const InputSearch = ({handleChange, content}: typeProps) => {

    return (
        <Fragment>
              <div className="w-full mx-auto relative">
                <div className="w-full flex h-[62px] overflow-hidden border-[1px] border-colorBorder" style={{borderRadius: '8px'}}>
                    <input
                        type="text"
                        placeholder={content}
                        className="pl-[23px] placeholder-gray-100 border-none w-[80%]"
                        onChange={handleChange}
                    />
                    <button type="submit" className="flex justify-around items-center bg-black-100 text-white font-sans leading-[18px] w-[20%]"><p>Buscar</p> <AiOutlineSearch/></button>
                </div>
            </div>
        </Fragment>
    );
}
