import { IFilterRequest } from "../types/IFilter";
import { IPaginationResponse } from "../types/IPagination";
import api from "./api";
import authHeader from "./authHeader";

const getAll = async (params = {}) => {
  const response = await api().get<IPaginationResponse>(`checkin`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getCsv = async (params: object = {}) => {
  const response = await api().get<any>(`csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const ArtistService = {
  getAll,
  getCsv,
};

export default ArtistService;
