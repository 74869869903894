interface UpdateUrlProps {
  page?: number;
  limit?: number;
  query?: string;
}

export const updateUrl = ({ page, limit, query }: UpdateUrlProps) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (page) urlParams.set("page", page.toString());
  if (limit) urlParams.set("limit", limit.toString());
  if (query) urlParams.set("query", query);
  else urlParams.delete("query");

  const newUrl = `${document.location.pathname}?${urlParams}`;

  window.history.pushState({ path: newUrl }, "", newUrl);
};
