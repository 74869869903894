import { useMutation } from "@tanstack/react-query";
import React, { useEffect, FC, useState, useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ArtistService from "../../services/artistService";
import { IArtistRequest } from "../../types/IArtist";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { Back } from "../Back";
import FormInput from "../FormElement/FormInput";
import FormUploadImage from "../FormElement/FormUploadImage";
import { ICountriesResponse } from "../../types/ICountry";
import CountryService from "../../services/countryService";
import FormSelectFull from "../FormElement/FormSelectFull";
import FormCheck from "../FormElement/FormCheck";
import LabelService from "../../services/labelService";
import { ILabelsResponse } from "../../types/ILabel";
import { StateContext } from "../../context/StateProvider";
import { validateDimensionsFile } from "../../utils/form";
import FormCropperImage from "../FormElement/FormCropperImage";
import FormRadio from "../FormElement/FormRadio";
import './styles.css';

let verifyAddForm = {
    first_name: yup.string()
        .required('El nombre es requerido'),
    nickname: yup.string()
        .required('El nickname es requerido'),
    email: yup.string()
        .email('El correo electrónico no es válido'),
    password: yup.string(),
    picture: yup.mixed(),
    name: yup.string(),
    url: yup.string(),
    profile_picture: yup.mixed()
        .test("size", "Tamaño permitido menor a 20MB", (value) => {
            if (value instanceof File) {
                return value.size <= 20000000;
            }
            return true;
        }),
    biography_picture: yup.mixed()
        .test("size", "Tamaño permitido menor a 20MB", (value) => {
            if (value instanceof File) {
                return value.size <= 20000000;
            }
            return true;
        }),
    gender: yup
        .string()
        .required("Campo requerido"),
    country_id: yup.string()
        .required('El país es requerido'),
};

let verifyEditForm = {
    first_name: yup.string()
        .required('El nombre es requerido'),
    nickname: yup.string()
        .required('El nickname es requerido'),
    email: yup.string()
        .email('El correo electrónico no es válido'),
    picture: yup.mixed(),
    name: yup.string(),
    url: yup.string(),
    profile_picture: yup.mixed()
        .test("size", "Tamaño permitido menor a 20MB", (value) => {
            if (value instanceof File) {
                return value.size <= 20000000;
            }
            return true;
        }),
    biography_picture: yup.mixed()
        .test("size", "Tamaño permitido menor a 20MB", (value) => {
            if (value instanceof File) {
                return value.size <= 20000000;
            }
            return true;
        }),
    country_id: yup.string()
        .required('El país es requerido'),
};

const defaultValues = {
    first_name: '',
    last_name: '',
    nickname: '',
    email: '',
    password: '',
    picture: '',
    status: 'active',
    labels: [],
    profile_picture: '',
    biography_picture: ''
};

const statusOptions: Array<object> = [
    /*{value: 'pending', text: 'Pendiente'}*/
    { value: 'active', text: 'Activo' },
    { value: 'paused', text: 'Pausado' },
]

type typeProps = {
    handleCreateorEdit: (id: string) => void;
    id: string;
};

export const FormArtist: FC<typeProps> = ({ handleCreateorEdit, id }) => {
    let schema;
    if (id) {
        schema = yup.object().shape(verifyEditForm);
    } else {
        schema = yup.object().shape(verifyAddForm);
    }

    const [countryOptions, setCountryOptions] = useState<ICountriesResponse>({ rows: [], pages: 0, total: 0 });
    const [labelsOptions, setLabelsOptions] = useState<ILabelsResponse>({ rows: [], pages: 0, total: 0 });
    const [isBomboArtist, setIsBomboArtist] = useState(true);
    const [biographyDescription, setBiographyDescription] = useState('');
    const [AboutDescription, setAboutDescription] = useState('');

    const createItems = useMutation(
        (item: FormData) => ArtistService.create(item),
    );
    const updateItems = useMutation(
        (item: FormData) => ArtistService.update(id, item),
    );
    const getUserApi = useMutation(
        (id: string) => ArtistService.get(id)
    );

    const getCountries = useMutation(
        () => CountryService.getAll(),
    );

    const getLabels = useMutation(
        () => LabelService.getAll(),
    );

    const form = useForm<IArtistRequest>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
        mode: 'all'
    });

    const genderOptions = [
        { value: 'Male', label: 'Hombre' },
        { value: 'Female', label: 'Mujer' },
        { value: 'Both', label: 'Otro' },
    ];

    const handleBody = (e: React.SetStateAction<string>) => {
        setBiographyDescription(e)
    }

    const handleBodyAbout = (e: React.SetStateAction<string>) => {
        setAboutDescription(e)
    }
    useEffect(() => {
        getCountries.mutateAsync().then(res => setCountryOptions(res));
        getLabels.mutateAsync().then(res => setLabelsOptions(res));
        if (id) {
            getUserApi.mutateAsync(id)
                .then((res) => {
                    setIsBomboArtist(!!res.is_bombo_artist);
                    setAboutDescription(res.about_description)
                    if (Array.isArray(res.labels))
                        res.labels = res.labels.map((l) => l.label_id);
                    form.reset(res);
                    if (res.song) {
                        form.setValue("name", res.song.name);
                        form.setValue("description", res.song.description);
                        form.setValue("url", res.song.url);
                        form.setValue("picture", res.song.picture);
                    }
                    if (res.biography) {
                        console.log("entro en el biograhpy");
                        form.setValue("biography_title", res.biography.title);
                        const description = res.biography.description.replace(/<[^>]+>\t/g, '').replace(/["']/g, "");
                        setBiographyDescription(description)
                        form.setValue("biography_description", description);
                        form.setValue("biography_picture", res.biography.picture);
                    }
                });
        }
    }, [form.reset]);

    const { state, dispatch } = useContext(StateContext);
    const onSubmit = async (data: IArtistRequest) => {
        data = { ...data, biography_description: biographyDescription };
        let formData = jsonToFormData(data, {
            arrayIndexes: true,
            excludeNull: true
        });
        dispatch({ type: "showLoaderScreen", payload: true });
        if (id) {
            await updateItems.mutateAsync(formData);
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
        } else {
            await createItems.mutateAsync(formData);
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({ type: "toastSuccess", payload: "Registro creado correctamente" });
        }
        handleCreateorEdit("");
    };

    const onError = (error: any) => {
        console.log("error ", error);
    }

    return (
        <div className="">
            <Back onClick={() => handleCreateorEdit("")} />
            <div className="flex flex-col gap-10 xl:px-[23rem]">
                <h1 className="text-center text-3xl font-bold">{id ? 'Editar Artista' : 'Crear nuevo Artista'}</h1>
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit, onError)}>
                        <FormInput
                            name="first_name"
                            type="text"
                            label="Nombre"
                        />
                        <FormInput
                            name="last_name"
                            type="text"
                            label="Apellido"
                        />
                        <FormInput
                            name="nickname"
                            type="text"
                            label="Nickname"
                        />
                        {!isBomboArtist &&
                            <FormInput
                                name="email"
                                type="text"
                                label="Correo"
                            />
                        }
                        {!isBomboArtist &&
                            <FormInput
                                name="password"
                                type="password"
                                label="Contraseña"
                            />
                        }
                        <FormSelectFull
                            name="status"
                            options={statusOptions}
                            label="Estado del Artista"
                        />
                        {!isBomboArtist &&
                            <div className="flex w-full justify-between pb-10 ">
                                <div className="w-full ">
                                    <FormCheck name="is_verified" label="Verificado"
                                        options={[{ value: 'true', label: 'Verificado' }]} />
                                </div>
                            </div>
                        }
                        <FormSelectFull
                            name="country_id"
                            options={
                                [
                                    { text: `Seleccionar país...`, value: '' }
                                ].concat(countryOptions.rows.map(c => { return { value: c.id, text: c.name } }))
                            }
                            label="País de origen"
                            placeholder="Seleccionar país..."
                        />
                        <FormRadio
                            name='gender'
                            label='Género'
                            options={genderOptions}
                            align='start'
                        />
                        <div className="flex justify-center gap-10 my-6 ">
                            <FormCropperImage
                                name="profile_picture"
                                label="Foto del artista (Tamaño: 154 x 154)"
                                accept="image/png, image/gif, image/jpeg"
                                initialAspectRatio={1}
                                cropBoxResizable={false}
                                dragMode="move"
                                rounded={true}
                                maxWidth={154}
                                maxHeight={154}
                            />
                        </div>
                        <h1 className="pb-3 font-medium text-gray-100">Información del Artista</h1>
                        {isBomboArtist &&
                            <FormInput
                                name="booking"
                                type="text"
                                label="Reserva"
                            />
                        }
                        {!isBomboArtist &&
                            <FormInput
                                name="managment"
                                type="text"
                                label="Gestion"
                            />
                        }
                        <FormInput
                            name="sound_cloud_link"
                            type="text"
                            label="Sound Cloud Link"
                        />
                        <FormInput
                            name="spotify_link"
                            type="text"
                            label="Spotify Link"
                        />
                        <FormInput
                            name="instagram_link"
                            type="text"
                            label="Instagram Link"
                        />
                        <FormInput
                            name="youtube_link"
                            type="text"
                            label="YouTube Link"
                        />
                        <FormInput
                            name="tiktok_link"
                            type="text"
                            label="TikTok Link"
                        />
                        {isBomboArtist &&
                            <div>
                                <h1 className="pb-3 font-medium text-gray-100">Biografía</h1>
                                <FormInput
                                    name="biography_title"
                                    type="text"
                                    label="Título"
                                />
                            </div>
                        }


                        {/* <FormInput
                            name="biography_description"
                            type="text"
                            label="Descripcion"
                        /> */}
                        {!isBomboArtist ?
                            <div>
                                <h1 className="pb-3 font-medium text-gray-100">About</h1>
                                <div id='quill-custom' className="mb-6 pt-3">
                                    <ReactQuill
                                        placeholder="Ingrese su descripción..."
                                        theme="snow"
                                        value={AboutDescription}
                                        onChange={handleBodyAbout}
                                    />
                                </div>
                            </div>
                            :
                            <div id='quill-custom' className="mb-6 pt-3">
                                <ReactQuill
                                    placeholder="Ingrese su descripción..."
                                    theme="snow"
                                    value={biographyDescription}
                                    onChange={handleBody}
                                />
                            </div>
                        }

                        {isBomboArtist &&
                            <div className="flex justify-center gap-10 mb-6">
                                <FormCropperImage
                                    name="biography_picture"
                                    label="Foto de biografía (Tamaño: 500 x 500)"
                                    accept="image/png, image/gif, image/jpeg"
                                    initialAspectRatio={1}
                                    cropBoxResizable={false}
                                    dragMode="move"
                                    maxWidth={500}
                                    maxHeight={500}
                                />
                            </div>
                        }

                        {!isBomboArtist &&
                            <>
                                <h1 className="pb-5 font-medium text-gray-100">Cancion del artista</h1>
                                <FormInput
                                    name="name"
                                    type="text"
                                    label="Nombre"
                                />
                                {/* <ReactQuill placeholder="Ingrese su descripción..." theme="snow" value={description} onChange={handleBody} /> */}

                                <FormInput
                                    name="description"
                                    type="text"
                                    label="Descripcion"
                                />
                                <FormInput
                                    name="url"
                                    type="text"
                                    label="Url"
                                />
                                <div className="flex justify-center gap-10 mb-6">
                                    <FormUploadImage
                                        name="picture"
                                        label="Imagen de la cancion"
                                    />
                                </div>
                            </>
                        }
                        {!isBomboArtist &&
                            <div className="flex w-full justify-between pb-10 ">
                                <div className="w-full ">
                                    <FormCheck name="labels[]" label="Labels" options={labelsOptions.rows.map(l => {
                                        return { value: l.id, label: l.name }
                                    })} />
                                </div>
                            </div>
                        }
                        <div className="mb-16 flex justify-center gap-10 pt-10">
                            <button
                                type="submit"
                                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                            >
                                {id ? 'Guardar Artista' : 'Publicar Artista'}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
}
