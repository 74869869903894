import api from "./api";
import authHeader from "./authHeader";
import { IEventResponse, ISendTicketsLogs } from "../types/IEvent";
import { IPaginationResponse } from "../types/IPagination";
import {
  ICommentReportsResponse,
  ICommentResponse,
  ICommentsResponse,
} from "../types/IComment";
import { IFilterRequest } from "../types/IFilter";
import { ExportCsvRequest } from "./eventService.types";

const getAll = async (params = {}) => {
  const response = await api().get<IPaginationResponse>(`events/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getAllSimple = async (params = {}) => {
  const response = await api().get<IPaginationResponse>(`events/list_simple`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<IEventResponse>(`events/detail/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<IEventResponse>(`events/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<IEventResponse>(
    `events/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<IEventResponse>(`events/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const sendRecycler = async (id: string) => {
  const response = await api().post<IEventResponse>(
    `events/send_recycler/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const getLastByOrder = async (params: object = {}) => {
  const response = await api().get<IEventResponse>(`events/last_by_order`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const sendTicketsToSeller = async (item: FormData) => {
  const response = await api().post<IEventResponse>(
    `events/send_tickets_to_seller`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const cancelSend = async (item: any) => {
  const response = await api().post<IEventResponse>(
    `events/remove_invitation`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const sendInvitationsToSeller = async (item: FormData) => {
  const response = await api().post<IEventResponse>(
    `events/send_tickets_invitations`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const sendNotifications = async (item: FormData) => {
  const response = await api().post<IEventResponse>(
    `events/send_notifications`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const getCsv = async (params: object = {}) => {
  const response = await api().get<any>(`events/csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getCsvOrderticketsPurchase = async (body: ExportCsvRequest) => {
  const response = await api().post<any>(`events/export/csv`, body, {
    headers: authHeader(),
  });
  return response.data;
};

const getCsvOrders = async (params: object = {}) => {
  const response = await api().get<any>(`events/csvOrderticketsPurchase`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const uploadFile = async (item: FormData) => {
  const response = await api().post<any>(`events/picture`, item, {
    headers: {
      // 'Content-Type': 'multipart/form-data',
      ...authHeader(),
    },
  });
  return response.data;
};

const removePicture = async (id: string) => {
  const response = await api().delete<any>(`events/picture/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const getSendTicketsLogs = async (params = {}) => {
  const response = await api().get<ISendTicketsLogs>(
    `events/send_tickets_logs`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const removeSendTicketsLogs = async (id: string) => {
  const response = await api().delete<IEventResponse>(
    `events/delete_tickets_invitations/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const reSendReminder = async (item: any) => {
  const response = await api().post<any>(`events/re_send_reminder`, item, {
    headers: authHeader(),
  });
  return response.data;
};

const getComments = async (params: IFilterRequest) => {
  const response = await api().get<ICommentsResponse>(
    `events/comments/${params.id}`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const removeComment = async (id: string) => {
  const response = await api().delete<ICommentResponse>(
    `events/comments/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const getCommentReports = async (params: IFilterRequest) => {
  const response = await api().get<ICommentReportsResponse>(
    `events/commentreports/${params.id}`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const getPictureReports = async (params: IFilterRequest) => {
  const response = await api().get<ICommentReportsResponse>(
    `events/picturereports/${params.id}`,
    {
      headers: authHeader(),
      params: params,
    }
  );
  return response.data;
};

const removeCommentReport = async (id: string) => {
  const response = await api().delete<ICommentResponse>(
    `events/delete_comment_report/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const updateOrderTickets = async (event_id: string, body: any) => {
  const response = await api().put<any>(`events/order/${event_id}`, body, {
    headers: authHeader(),
  });
  return response.data;
};

const sendTicketLogsCsv = async (params: object = {}) => {
  const response = await api().get<any>(`events/send_tickets_logs_csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const emptyTrash = async () => {
  const response = await api().delete<IEventResponse>(`events/empty_trash`, {
    headers: authHeader(),
  });
  return response.data;
};

const updateOrder = async (event_id: string | undefined, order: number) => {
  const response = await api().put<IEventResponse>(
    `events/update_order/${event_id}`,
    { order },
    {
      headers: authHeader(),
    }
  );
  console.log(response.data);
  return response.data;
};

const updateVisibility = async (
  event_id: string | undefined,
  hidden: boolean
) => {
  const response = await api().put<IEventResponse>(
    `events/update_visibility/${event_id}`,
    { hidden },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const updateStatus = async (event_id: string | undefined, status: string) => {
  const response = await api().put<IEventResponse>(
    `events/update_status/${event_id}`,
    { status },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const EventService = {
  getAll,
  getAllSimple,
  get,
  create,
  update,
  remove,
  getLastByOrder,
  sendTicketsToSeller,
  sendInvitationsToSeller,
  sendNotifications,
  getCsv,
  getCsvOrderticketsPurchase,
  getCsvOrders,
  uploadFile,
  removePicture,
  getSendTicketsLogs,
  cancelSend,
  sendRecycler,
  reSendReminder,
  getComments,
  removeComment,
  getPictureReports,
  getCommentReports,
  removeSendTicketsLogs,
  sendTicketLogsCsv,
  removeCommentReport,
  emptyTrash,
  updateOrderTickets,
  updateOrder,
  updateStatus,
  updateVisibility,
};

export default EventService;
