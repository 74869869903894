import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import React, {useEffect, useState, FC, useContext} from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { toast, ToastContainer } from "react-toastify";
import ScheduleService from "../../services/scheduleService";
import { IFilterRequest } from "../../types/IFilter";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { TitleSection } from "../TitleSection";
import { ISchedulesResponse } from "../../types/ISchedule";
import { formatDateTime } from "../../utils/format-date";
import { PageContainer } from "../PageContainer";
import moment from "moment/moment";
import {FilterOrder} from "../table/FilterOrder";
import ISortOption from "../../types/ISortOption";
import {StateContext} from "../../context/StateProvider";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows:[]
}
const defaultFilters = {
  limit: config.filter_default_limit,
  page:1,
  sortBy: 'date',
  sortOrder: 'desc',
    query: '',
}

type propsTable = {
  handleCreateorEdit: (id: string) => void;
  handleSelectItem: (data: any) => void;
}

export const TableSchedule: FC<propsTable> = ({ handleCreateorEdit, handleSelectItem }) => {

    const[result, setResult] = useState<ISchedulesResponse>(defaultResult);
    const[filters, setFilters] = useState<IFilterRequest>(defaultFilters);
    const [search, setSearch] = useState("");
    const [stateDiary, setState] = useState("desc");
    const { state, dispatch } = useContext(StateContext);
    const handleChangeState = async (state: string) => {
        setState(state)
        handleFilter({ ...filters, sortOrder: state });
    }

    const searcher = async (e: any) => {
        setSearch(e.target.value)
        setFilters({ ...filters, query: e.target.value });
    }

    const getItems = useMutation(
        () => ScheduleService.getAll(filters)
    );

    const deleteItems = useMutation(
        (id: string) => ScheduleService.remove(id), {
          onSuccess(data: any) {
            toast.success(data.message);
          }
        }
    );

    const handleFilter = async(newFilters: IFilterRequest) => {
        await setFilters({...filters, ...newFilters});
        await getItems.mutateAsync().then(res=>setResult(res));
    }

    const sortOptions: Array<ISortOption> = [
        { value: 'date', text: 'Fecha', sortOrder: 'desc' },
        { value: 'title', text: 'Título', sortOrder: 'asc' },
    ];

    const handleSortSection = async (sortBy: string) => {
        const itemSort = sortOptions.find((s) => s.value == sortBy);
        const paramsFilters = { ...filters, page: 1, sortBy: sortBy };
        if (itemSort) {
            paramsFilters['sortOrder'] = itemSort.sortOrder;
            setState(itemSort.sortOrder);
        }
        await setFilters(paramsFilters);
        await getItems.mutateAsync().then(res => setResult(res));
    }

    const handleDelete = async (id: string) => {
        if(window.confirm('Esta seguro de eliminar est seccion?')) {
            await deleteItems.mutateAsync(id).then(res=>{
                getItems.mutateAsync().then(res=>setResult(res));
            });
        }
    }

    useEffect(() => {
      handleFilter(filters);
    },[])

    useEffect(() => {
        if (search != "") {
            const delayDebounceFn = setTimeout(() => {
                loadData()
            }, 600)
            return () => clearTimeout(delayDebounceFn)
        }
        else {
            loadData()
        }
    }, [search])

    const loadData = () => {
        dispatch({ type: "showLoaderScreen", payload: true });
        getItems.mutateAsync().then((res) => {
            dispatch({ type: "showLoaderScreen", payload: false });
            setResult(res);
        });
    }

    const columnData = [
        "Fecha",
        "Titulo",
        "Subtitulo",
        "Location"
    ];

    return (
        <PageContainer>
          <div className="relative">
            <TitleSection>Agenda</TitleSection>
            <div className="absolute -top-0 right-0 ">
              <button
                onClick={()=>handleCreateorEdit("")}
                className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
              >
                Crear evento en agenda
              </button>
            </div>
          </div>

          <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
              <div></div>
            <div className="flex items-center gap-5 ">
                <input type="text" placeholder="Buscar..." className="rounded-full border-[2px] border-black-100 px-4 py-1 font-bold text-black-100  hover:text-black" value={search} onChange={searcher} />
                <FilterOrder setSort={handleSortSection} options={sortOptions} handleChangeState={handleChangeState} state={stateDiary} />
            </div>
          </div>

          <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
            <thead className=" divide-solid border ">
              <tr className=" pb-4 text-sm font-bold text-gray-100">
                {columnData.map((column, index) => (
                  <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
                ))}
                <th className="border-b-[1px] border-colorBorder pb-4">
                </th>
              </tr>
            </thead>
            <tbody>
              { result.rows.length ? result.rows.map((_, i) => (
                <motion.tr
                  key={_.id}
                  layout
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0.2 }}
                  transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                  className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                >
                  <td className="pl-4">{ _.date ? moment(_.date).format('D MMM YYYY') : '' }</td>
                  <td className="pl-4">{`${_.title}`}</td>
                  <td className="pl-4">{`${_.subtitle}`}</td>
                  <td className="pl-4">{`${_.location}`}</td>
                  <td className="pl-4">
                    <DropdownDots options={[
                      {title: 'Editar', action: ()=>handleCreateorEdit(_.id), icon: (<FaEdit size={23} />)},
                      {title: 'Eliminar', action: ()=>handleDelete(_.id), icon: (<FaTrashAlt size={23} />)}
                    ]}/>
                  </td>

                </motion.tr>
              )):<></>}
            </tbody>
          </table>
          <div className="flex justify-between pt-10">
            <FilterPage handleFilter={handleFilter} total={result.total} limit={filters.limit} status={filters.status} currentPage={filters.page}/>
            <FilterQtyPage handleFilter={handleFilter} status={filters.status}/>
          </div>
        </PageContainer>
    );
};
