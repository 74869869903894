import React, { useState, useEffect } from 'react'
import { IProducerResponse } from '../../../types/IProducer';

import img from "../../../assets/profile-default.png";
import { AiFillCloseCircle } from 'react-icons/ai';

type ProducerSelectedProps = {
    data: IProducerResponse;
    handleRemoveId: ( id: string ) => void;
}

export const ProducerSelected = ({ data, handleRemoveId }: ProducerSelectedProps) => {
    const [producer, setProducer] = useState<IProducerResponse>();
    
    useEffect(() => {
        if( data ){
            setProducer(data);
        }
    }, [data.id]);
    

    return (
        <div className='flex w-72'>
            <div className='flex items-center gap-5'>
                <img 
                    src={ producer?.picture ? producer?.picture : img } 
                    alt="image producer"
                    className='w-[64px] h-[64px] rounded-full bg-[#D6D6D6] object-cover' 
                />
                <h3 className='text-lg font-medium'>{ producer ? `${ producer.name }` : '' }</h3>
            </div>
            <div className='mt-2'>
                <button
                    type='button'
                    onClick={() => handleRemoveId(data.id)}
                >
                    <AiFillCloseCircle size={18}/>
                </button>
            </div>
        </div>
    )
}
