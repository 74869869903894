import React, { useState, useContext, useEffect } from "react";
import { FormProducer } from "./Form";
import { ListProducers } from "./ListProducers";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import { IProducerResponse } from "../../types/IProducer";

export const ProducerCardView = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [createItem, setCreateItem] = useState(false);

  const [id, setId] = useState<string>("");

  const handleCreateorEdit = async(id:string) => {
    await setId(id);
    await setCreateItem(!createItem);
  }

  const handleSelectItem = (data: IProducerResponse) => {
    auth.changeProducer(data);
    if (auth.permissions.reports)
      navigate(`/client/reports`);
    else
      navigate(`/client/producers/${data.id}/events`);
  };

  const itemsTableProps = {
    handleCreateorEdit,
    handleSelectItem,
  };

  const formUserProps = {
    handleCreateorEdit,
  };

  return (
    <div>
      {!createItem ? (
        <ListProducers {...itemsTableProps} />
      ) : (
        <FormProducer {...formUserProps} id={id}/>
      )}
    </div>
  );
};
