import { useMutation } from "@tanstack/react-query";
import TicketService from "../../../../services/ticketService";
import { Fragment, useContext, useEffect, useState } from "react";
import { Title } from "../../Title";
import { StateContext } from "../../../../context/StateProvider";
import { ITicketsResponse } from "../../../../types/ITicket";
import { PopUpInviteCSV } from "./PopupInviteCSV";

type SendTicketsProps = {
  eventId: string;
};

export const SendInvitationsCSV = ({ eventId }: SendTicketsProps) => {
  const getTickets = useMutation(() =>
    TicketService.getAll({ event_id: eventId, type: "Free" })
  );

  const { state, dispatch } = useContext(StateContext);
  const [tickets, setTickets] = useState<ITicketsResponse>({
    rows: [],
    total: 0,
    pages: 0,
  });
  const [selectedTicket, setSelectedTicket] = useState("");
  const [selectedTicketName, setSelectedTicketName] = useState("");

  useEffect(() => {
    getTickets.mutateAsync().then((res) => {
      setTickets(res);
    });
  }, []);

  const handleInvite = () => {
    if (selectedTicket) {
      dispatch({ type: "inviteViaCSV", payload: { ticket_id: selectedTicket, ticket_name: selectedTicketName, event_id: eventId } });
    } else {
      dispatch({ type: "toastError", payload: "Seleccione un ticket" });
    }
  };

  const handleSelectTicket = (selectedTicket: string, ticketName: string) => {
    setSelectedTicket(selectedTicket);
    setSelectedTicketName(ticketName);
  };

  return (
    <Fragment>
      <Title>Envío de invitaciones por CSV</Title>
      <div className="pt-5">
      <select
        name="tickets"
        id="tickets"
        className="rounded-l border-none bg-gray-300 px-10 py-5 font-medium text-gray-100"
        onChange={(t) => {
          handleSelectTicket(
            t.target.value,
            t.target.options[t.target.selectedIndex].text
          );
        }}
      >
        <option value={""} key={""}>
          Seleccione un ticket
        </option>
        {tickets.rows.map((_, i) => (
          <option value={_.id} key={i}>
            {_.name}
          </option>
        ))}
      </select>
      </div>
      <div className="pt-5">
        <button
          onClick={handleInvite}
          type="button"
          className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
        >
          Cargar CSV
        </button>
      </div>
    </Fragment>
  );
};
