import api from "./api";
import authHeader from "./authHeader";
import { IOrderRequest, IOrderResponse } from "../types/IOrder";
import { IPaginationResponse } from "../types/IPagination";
import apiFront from "./apiFront";

const getAll = async (params: object = {}) => {
  const response = await api().get<IPaginationResponse>(`orders/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getAllOrders = async (params: any) => {
  const response = await api().get<IPaginationResponse>(`orders/listOrders`, {
    headers: authHeader(),
    params: params,
    signal: params.signal,
  });
  return response.data;
};

const getAllORdersInvitation = async (params: object = {}) => {
  const response = await api().get<IPaginationResponse>(`orders/listSends`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string, params: object = {}) => {
  const response = await api().get<IOrderResponse>(`orders/detail/${id}`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const createInvoice = async (item: FormData) => {
  const response = await api().put<IOrderResponse>(
    `orders/create_invoice`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const getInvoice = async (params: object = {}) => {
  const response = await api().get<any>(`orders/invoice_pdf`, {
    headers: {
      Accept: "application/pdf",
      responseType: "blob",
      ...authHeader(),
    },
    params: params,
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<IOrderResponse>(`orders/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<IOrderResponse>(
    `orders/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const bulkUpdate = async (item: FormData) => {
  const response = await api().put<IOrderResponse>(`orders/bulk_update`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const updateOrderTicketSend = async (id: string | undefined, item: any) => {
  const response = await api().put<IOrderResponse>(
    `orders/updateOrderTicketSend/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const updateOrderTicketIsMain = async (id: string | undefined, item: any) => {
  const response = await api().put<IOrderResponse>(
    `orders/updateOrderTicketIsMain/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const updateOrderTicketSendGroup = async (item: any) => {
  const response = await api().put<IOrderResponse>(
    `orders/updateOrderTicketSendGroup`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<IOrderResponse>(`orders/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const sendRecycler = async (id: string) => {
  const response = await api().post<IOrderResponse>(
    `orders/send_recycler/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const getMpOrderURL = async (id: string) => {
  const response = await api().get<IOrderResponse>(
    `orders/get_mp_order_url/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};
const refundOrder = async (
  external_reference: string,
  amount?: number,
  detail?: Array<any>
) => {
  const response = await api().post<IOrderResponse>(
    `refund/create/${external_reference}`,
    {
      amount,
      detail,
      headers: authHeader(),
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};
const getRefunds = async (external_reference: string) => {
  const response = await api().get<IOrderResponse>(
    `refund/refund/${external_reference}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};
const getDataOrder = async (id: string) => {
  const response = await api().get<any>(`orders/status/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const purchase = async (item: IOrderRequest) => {
  const response = await apiFront().post<IOrderResponse>(
    `purchase/mercado_pago`,
    item,
    {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const emptyTrash = async () => {
  const response = await api().delete<IOrderResponse>(`orders/empty_trash`, {
    headers: authHeader(),
  });
  return response.data;
};

const getCreateOrderHistory = async (
  event_id: string,
  limit: number,
  page: number
) => {
  const response = await api().get<any>(
    `orders/history/${event_id}?page=${page}&limit=${limit}`,
    {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    }
  );
  console.log(response.data);
  return response.data;
};

const assignSeller = async (
  order_id: string,
  seller_id: string | undefined
) => {
  const response = await api.put<IOrderResponse>(
    `orders/assign_seller/${order_id}`,
    { seller_id },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const cleanRejectedOrders = async (event_id: string) => {
  const response = await api().put<number>(
    `orders/clean_orders/${event_id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const OrderService = {
  getAll,
  getAllOrders,
  get,
  getInvoice,
  getDataOrder,
  create,
  update,
  remove,
  getAllORdersInvitation,
  updateOrderTicketSend,
  updateOrderTicketSendGroup,
  createInvoice,
  bulkUpdate,
  getMpOrderURL,
  refundOrder,
  getRefunds,
  sendRecycler,
  updateOrderTicketIsMain,
  purchase,
  emptyTrash,
  getCreateOrderHistory,
  cleanRejectedOrders,
  assignSeller,
};

export default OrderService;
