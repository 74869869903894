import { IClientResponse, IClientsResponse } from "../types/IClient";
import { IFilterClientRequest, IFilterRequest } from "../types/IFilter";
import {
  IOrderTicketResponse,
  IOrderTicketsResponse,
} from "../types/IOrderTicket";
import api from "./api";
import authHeader from "./authHeader";

const getAll = async (params: IFilterClientRequest) => {
  const response = await api().get<IClientsResponse>(`clients/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<IClientResponse>(`clients/detail/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<IClientResponse>(`clients/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<IClientResponse>(
    `clients/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<IClientResponse>(`clients/delete/${id}`, {
    headers: authHeader(),
  });
  return response.data;
};

const deleteAccount = async (id: string) => {
  const response = await api().post<IClientResponse>(
    `clients/delete_account/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const getCsv = async (params: object = {}) => {
  const response = await api().get<any>(`clients/csv`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const getOrderTickets = async (id: string) => {
  const response = await api().get<IOrderTicketsResponse>(
    `clients/tickets/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const ClientService = {
  getAll,
  get,
  create,
  update,
  remove,
  deleteAccount,
  getCsv,
  getOrderTickets,
};

export default ClientService;
