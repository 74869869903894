
import ReactTooltip from "react-tooltip";

export const Tooltip = ({ ...props }) => {
    return (
      <div> 
        <button className="text-white inline-flex" data-tip={props.text}>
            <svg className="w-6 h-6" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">                
                <path 
                    d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"
                />
            </svg>
        </button>
        <ReactTooltip
            className="tooltipDesign leading-1"
            backgroundColor="#fff"
            textColor="#000"
            borderColor="#ffffffff"
            arrowColor="#fff"
            multiline={true}
            place="top"
        />
      </div>
    );
  };