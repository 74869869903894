import React, { useEffect, useState } from "react";
import { useContext } from "react"; 
import { GrClose } from "react-icons/gr";
import { StateContext } from "../../../../context/StateProvider";
import { Footer } from "../../../footer/Footer";
import circleCheck from '../../../../assets/circle-check.png';

// import { Artists } from "./Artists";

export const PopUpMessage = ({ }) => {
  const { state, dispatch } = useContext(StateContext);
  
  const message = () => {
    dispatch({ type: "message", payload: null });
  };
  return (
    <div className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md">
        <div className="absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white h-96 mx-6"
            >
            <div className="grid grid-cols-1 gap-5 border-y border-colorBorder px-8 pb-4 pt-5">
                <img src={circleCheck} alt="check" className="text-center m-auto "/>
                <h1 className=" text-center text-lg">Evento creado correctamente.</h1>
                <p className="">Nota Importante: </p>
                <p>Los eventos deben ser aprobados por los administradores de bombo antes de aparecer en la aplicación.</p>
                <button
                    onClick={message}
                    type="submit"
                    className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
                >
                    Ok
                </button>
            </div>
        </div>
    </div>
  );
};
