import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

import { getOrdersColumns } from "./Columns";
import { DataTable } from "./DataTable";
import SkeletonTable from "./SkeletonTable";
import { Input } from "../../components/ui/input";
import { PageContainer } from "../PageContainer";
import { TitleSection } from "../TitleSection";
import { FilterPage } from "../table/FilterPageWithShadcn";
import { FilterQuantity } from "../table/FilterQuantityPage";
import OrderService from "../../services/orderService";
import { IFilterRequest } from "../../types/IFilter";
import { updateUrl } from "@/utils/updateUrl";
import config from "../../config/variables";

export const OrdersView = () => {
  const [searchParams] = useSearchParams();
  const page =
    searchParams.get("page") !== null ? +searchParams.get("page")! : 1;

  const limit =
    searchParams.get("limit") !== null
      ? +searchParams.get("limit")!
      : config.filter_default_limit;

  const query =
    searchParams.get("query") !== null ? searchParams.get("query")! : "";

  const defaultFilters = {
    limit,
    page,
    sortBy: "createdAt",
    sortOrder: "desc",
  };

  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState<string>(query);

  const [debouncedSearch] = useDebounce(search, 500);

  const { data: ordersData, isFetching } = useQuery({
    queryKey: ["orders", filters, debouncedSearch],
    queryFn: ({ signal }) =>
      OrderService.getAllOrders({ ...filters, query: debouncedSearch, signal }),
  });

  const handleChangeSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newSearch = evt.target.value;
    updateUrl({ query: newSearch, page: 1 });
    setSearch(newSearch);
  };

  const pageContent = isFetching ? (
    <SkeletonTable />
  ) : (
    <>
      <DataTable columns={getOrdersColumns()} data={ordersData?.rows || []} />

      <div className="flex justify-between pt-10">
        <FilterPage
          filters={{ ...filters, query: debouncedSearch }}
          setFilters={setFilters}
          totalPages={ordersData!.pages}
        />
        <FilterQuantity
          filters={{ ...filters, query: debouncedSearch }}
          setFilters={setFilters}
        />
      </div>
    </>
  );

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Órdenes</TitleSection>
        <Input
          type="text"
          placeholder="Search..."
          value={search}
          onChange={handleChangeSearch}
        />
      </div>

      {pageContent}
    </PageContainer>
  );
};
