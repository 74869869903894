import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from '../../../types/FormInputsProps';


const FormSwitch: FC<FormInputProps> = ({ name, label } ) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
        name={name}
        control={control}
        render={({ field: { name, value, onBlur, ref, onChange } })=> (
        <div className="flex w-full flex-col">
            <h2 className="pb-2 text-base font-medium text-gray-100">{label}</h2>
            <div className="flex w-full flex-wrap gap-4 ">
                <div className="flex flex-row">
                    <span className="text-xs font-medium">No</span>
                    <label htmlFor={name} className="inline-flex relative items-center mb-5 cursor-pointer mx-1">
                        <input
                            id={name}
                            type="checkbox"
                            value={value}
                            className="sr-only peer"
                            onChange={onChange}
                            checked={!!value}
                        />
                        <div className="w-10 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green  rounded-full  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[-2px] after:left-[1px] after:bg-[#171721] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  "></div>
                    </label>
                    <span className="text-xs font-medium">Si</span>
                </div>
            </div>
            {errors[name]&&
              <p className="mt-2 text-sm text-red text-red-600 dark:text-red-500">
                {String(errors[name] ? errors[name]?.message : '')}
              </p>
            }
        </div>
      )}
    />
  );
};

export default FormSwitch;
