import { useState, FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { IUserResponse} from "../../../types/IUser";
import { Back } from "../../Back";
import { FilterOption } from "../../table/FilterSection";
import { BannerUser } from "../BannerUser";
import { OrdersUser } from "./tickets/OrdersUser";
import { TicketsUser } from "./tickets/TicketsUser";
import UserService from "../../../services/userService";

type typeProps = {
  handleSelectItem: (data: IUserResponse) => void;
}

const filterSectionProps = [
  { title: "Órdenes", id: "orders" },
  { title: "Tickets Disponibles", id: "tickets_available" },
  { title: "Tickets Enviados", id: "tickets_sent" },
];

export const SelectedUser: FC<typeProps> = ({ handleSelectItem }) => {
  const [data, setData] = useState<IUserResponse>();
  const navigate = useNavigate();
  const {user_id} = useParams();

  const getItem = useMutation(
      () => UserService.get(user_id || '')
  );

  useEffect(() => {
    getItem.mutateAsync().then(res=>setData(res));
  }, [user_id]);

  const [formValue, setFormValue] = useState("ordenes");

  const handleFormValue = (event: React.ChangeEvent<HTMLFormElement>) => {
    setFormValue(event.target.id);
  };

  const handleBack = () => {
    navigate('/admin/users');
  };

  const showFormValue = () => {
    switch (formValue) {
      case "orders":
        return <OrdersUser />;
      case "tickets_available":
        return <TicketsUser key={formValue} userOwner={true} />;
      case "tickets_sent":
        return <TicketsUser key={formValue} userOwner={false} />;
      default:
        return <OrdersUser />;
    }
  };

  return (
    <div>
      <Back onClick={handleBack} />
      <div className="pt-6">
        <BannerUser edit={true} data={data} />
      </div>
      <div className="w-full overflow-auto border-b pt-10">
        <form
          className="flex gap-10 font-medium text-gray-100"
          onChange={handleFormValue}
        >
          {filterSectionProps.map((option) => (
            <FilterOption key={option.id} title={option.title} id={option.id} />
          ))}
        </form>
      </div>
      <div className="pt-10">{showFormValue()}</div>
    </div>
  );
};
