import { ChangeEvent, useContext, useEffect, useState } from "react";
import { GoGraph } from "react-icons/go";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { TitleSection } from "../TitleSection";
import { PageContainer } from "../PageContainer";
import { formatDate } from "../../utils/format-date";
import { IFilterEventRequest, IFilterRequest } from "../../types/IFilter";
import { IPaginationResponse } from "../../types/IPagination";
import { IProducersResponse } from "../../types/IProducer";
import { IEventsResponse } from "../../types/IEvent";
import acces from "../../services/accessControlService";
import ProducerService from "../../services/producerService";
import EventService from "../../services/eventService";
import { StateContext } from "../../context/StateProvider";
import config from '../../config/variables';

const defaultFilters = {
  limit: config.filter_default_limit,
  page:1,
  sortBy: 'date_start',
  sortOrder: 'asc',
  query: '',
};

const defaultResult = {
  total: 0,
  pages: 0,
  rows:[]
};

const defaultFiltersEvents = {
  status: 'all',
  sortBy: 'name',
  sortOrder: 'asc',
  query: '',
};

const defaultFiltersProducers = {
  status: 'all',
  sortBy: 'name',
  sortOrder: 'asc',
  query: '',
};

const columnData = [
  "Fecha",
  "Evento",
  "Nombre",
  "Email",
];

export const AccessControlView = () => {
  const { dispatch } = useContext(StateContext);

  const[filters, setFilters] = useState<IFilterRequest>({...defaultFilters, producer_id: '', event_id: ''});
  const [filtersEvents, setFiltersEvents] = useState<IFilterEventRequest>({ ...defaultFiltersEvents, producer_id: ''});
  const [data, setData] = useState<IPaginationResponse>(defaultResult);
  const [production, setProduction] = useState<IProducersResponse>(defaultResult);
  const [events, setEvents] = useState<IEventsResponse>(defaultResult);

  const getItems = useMutation(() => acces.getAll(filters));
  const getProductions = useMutation( () => ProducerService.getAll(defaultFiltersProducers));
  const getEvents = useMutation(() => EventService.getAll(filtersEvents));
  const getCsv = useMutation(() => acces.getCsv({}));

  useEffect(() => {
    getItems.mutateAsync().then(res => setData(res));
    getProductions.mutateAsync().then(res => setProduction(res));
  }, []);

  useEffect(() => {
    if(filtersEvents.producer_id){
      loadEvents();
    }
  }, [filtersEvents.producer_id]);
  
  useEffect(() => {
    loadData();
  }, [filters.event_id, filters.producer_id]);

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({...filters, ...filter});
    await getItems.mutateAsync().then(res=>setData(res));
  };

  const loadEvents= async () => {
    getEvents.mutateAsync().then(res => {
      setEvents(res);
    });
  };
  
  const handleChangeProducer = async (e: ChangeEvent<HTMLSelectElement>) => {
    await setFilters({ ...defaultFilters, producer_id:e.target.value, event_id: '' });
    await setFiltersEvents({ ...defaultFiltersEvents, producer_id:e.target.value });
  };

  const handleChangeEvent = async (e: ChangeEvent<HTMLSelectElement>) => {
    await setFilters({ ...filters, page: 1, event_id:e.target.value });
  };

  const loadData = async () =>{
    dispatch({ type: "showLoaderScreen", payload: true });
    getItems.mutateAsync().then(res => {
      setData(res);
      dispatch({ type: "showLoaderScreen", payload: false });
    });
  }
  
  const handleCsv = async () => {
    await getCsv.mutateAsync().then(response=>{
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'access.csv');
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <PageContainer>
      <div className="pr-12">
        <TitleSection>Control de acceso</TitleSection>
      </div>
      <div className="flex w-full min-w-max max-w-full justify-between">
        <div></div>
        <div className="flex gap-5">
          <form className="flex w-full gap-5">
            <button className="flex justify-center items-center text-[#3E7EFF]" type="button" onClick={handleCsv}>
              <GoGraph className="mr-1" />
              <strong>Exportar a CSV</strong>
            </button>
            <select
              name="prod"
              id="prod"
              className="rounded-xl border-none bg-gray-300 py-5 px-10  font-medium text-gray-100"
              onChange={(e) => handleChangeProducer(e)}
            >
              <option  value=''>Todas las productoras</option>
              {production?.rows.map((_, i) => (
                <option  value={_.id} key={i}>{_.name}</option>
              ))}
            </select>
            <select
              name="event"
              id="event"
              className="rounded-xl border-none bg-gray-300 py-5 px-10 font-medium text-gray-100 max-w-lg"
              onChange={(e) => handleChangeEvent(e)}
            >
              <option  value=''>Todos los eventos </option>
              {filtersEvents.producer_id && events.rows.map((_, i) => (
                <option  value={_.id} key={i}>{_.name}</option>
              ))}
            </select>
          </form>
        </div>
      </div>

      <table className="my-table-spacing h-full w-full  border-separate gap-5 overflow-x-auto pt-10 text-left border-spacing-y-2">
        <thead className="  ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4"></th>
          </tr>
        </thead>
        <tbody>
          {data.rows.length>0 ? data.rows.map((_, i) => (
            <motion.tr
              layout
              key={_.id}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{ formatDate(_.ticket.event.date_start) }</td>
              <td className="pl-4">{_.ticket.event.name}</td>
              <td className="pl-4">{_.user ? `${_.user?.first_name} ${_.user?.last_name}` : 'usuario de bombo'}</td>
              <td className="pl-4">{_.user?.email}</td>
            </motion.tr>
          ))
          : <></>}
        </tbody>
      </table>
      { data?.rows?.length ?
        <div className="flex justify-between pt-10">
          <FilterPage handleFilter={handleFilter} total={data.total} limit={filters.limit}  currentPage={filters.page}/>
          <FilterQtyPage handleFilter={handleFilter} />
        </div>
        : <p className="text-center">No existen registros</p>
      }
    </PageContainer>
  );
};
