import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion"
import { FaRedo, FaTrashAlt } from "react-icons/fa"
import { GoSearch } from "react-icons/go"
import { toast } from "react-toastify";
import UserService from "../../../services/userService";
import { IUsersResponse } from "../../../types/IUser";
import { formatDate, formatDateTime } from "../../../utils/format-date";
import DropdownDots from "../../FormElement/DropdownDots"
import {IReportedsResponse} from "../../../types/IReported";

type selectedProps = {
    // id: string;
    data: IReportedsResponse;
    updateData: (filter: string) => void;
  };

const columnData = [
    "Fecha",
    "Reportado",
    "E-mail",
    "Reportado por:",
    "Email reporter:",
    "Razón",
];

export const UserReported = ({data, updateData}: selectedProps) => {

    return (
        <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
                <thead className=" divide-solid border ">
                    <tr className=" pb-4 text-sm font-bold text-gray-100">
                        {columnData.map((column, index) => (
                            <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
                        ))}
                        <th className="border-b-[1px] border-colorBorder pb-4">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.rows.length ? data.rows.map((_, i) => (
                        <motion.tr
                            key={i}
                            layout
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0.2 }}
                            transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                            className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                        >
                            <td className="pl-4">{formatDateTime(_.createdAt)}</td>
                            <td className="pl-4">{_.receiver ? `${_.receiver.first_name} ${_.receiver.last_name}` : '(usuario de bombo)'}</td>
                            <td className="pl-4">{_.receiver ? _.receiver.email : '(usuario de bombo)'}</td>
                            <td className="pl-4">{_.sender ? `${_.sender.first_name} ${_.sender.last_name}` : '(usuario de bombo)'}</td>
                            <td className="pl-4">{_.sender ? _.sender.email : '(usuario de bombo)'}</td>
                            <td className="flex h-full items-center gap-2 pl-4">
                                {_.reason || '-'}
                            </td>
                        </motion.tr>
                    )) : <></>}
                </tbody>
            </table>
    )
}
