import { Skeleton } from "../ui/skeleton";
import { getOrdersColumns } from "./Columns";
import DataTableHeader from "./DataTableHeader";

const SkeletonTable = () => {
  return (
    <div className="mt-8">
      <DataTableHeader columns={getOrdersColumns()} />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
      <Skeleton className="mt-3 h-12 rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder" />
    </div>
  );
};

export default SkeletonTable;
