import React, {Fragment, useContext, useEffect, useState} from "react";
import { Back } from "../../Back";
import { useNavigate } from "react-router";
import {IRrppResponse} from "../../../types/IRrpp";
import {SelectTeamLeader} from "../components/SelectTeamLeader/SelectTeamLeader";
import {InputSearch} from "../components/InputSearch/InputSearch";
import {useMutation} from "@tanstack/react-query";
import teamService from "../../../services/teamService";
import {AuthContext} from "../../../context/AuthContext";
import {ITeamResponse} from "../../../types/ITeam";
import {IUserResponse} from "../../../types/IUser";
import RrppService from "../../../services/rrppService";
import {useParams} from "react-router-dom";

const styles = {
    titleLi: "font-bold pb-1 text-xs leading-[21px] ",
    pLi: "font-medium text-xs leading-[18px] ",
    wrapperLi: "flex justify-between gap-28 ",
  };

type addSellerViewProps = {
    selected: IRrppResponse;
    handleSetSection: (section: string) => void;
    handleSetLeader: (item: IUserResponse) => void;
}


export const SelectLeaderView = ({selected, handleSetSection, handleSetLeader}: addSellerViewProps) => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { producer_producer_id } = useParams();
    const [query, setQuery] = useState('');
    const [state, setState] = useState<Array<ITeamResponse>>([]);
    const [leaders, setLeaders] = useState<Array<ITeamResponse>>();
    let leader: Array<ITeamResponse> = [];

    const getItems = useMutation(
        () => RrppService.getAll({sortBy: 'first_name', sortOrder: 'asc', team_role: 'leader', producer_producer_id: producer_producer_id || auth.producer?.id})
      );

    const getMembers = useMutation(() => teamService.getAll({from_producer: true, team_role: 'leader'}));
    useEffect(() => {
        getItems.mutateAsync().then(res=>{
            setLeaders(res.rows);
            setState(res.rows);
        });
    },[]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        if (leaders) {
            const results = leaders.filter(leader => {
                if (e.target.value === "") return leaders;
                const fullName = leader?.first_name+' '+leader?.last_name;
                return fullName.toLowerCase().includes(e.target.value.toLowerCase())
            })
            setState(results);
        }
    }

    const handleChangeLeader = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);

        if (leaders) {
            const existLeader = leader.find(leader => {
                return leader.id == e.target.value
            });

            let result: any = [];
            if (existLeader) {
                result = leader.filter((l) => {
                    return l.id != e.target.value
                });
            } else {
                result = leaders.find(l => {
                    return l.id == e.target.value
                });
                result = [result, ...leader]
            }
            console.log(result);

            if (result) {
                leader = result;
                console.log("result",result);
            };
        }
    }

    const saveLeader = () => {
        console.log(leader);

        if (leader) handleSetLeader(leader);
    }

    return (
        <Fragment>
            <Back onClick={() => handleSetSection('detail')} />

            <h1 className="text-center text-[20px] font-[700px] font-bold leading-[32px] tracking-[-0.04em]">Asignación de Team Leader</h1>
            <div className="my-5 w-[75%] mx-auto">
                <InputSearch value={query} onChange={() => {}} handleChange={handleChange} content="Buscar un team leader"/>

                <div className="pt-6">
                    <div className={`flex flex-wrap gap-3 ${state.length >= 3 ? 'justify-between' : ''}`}>
                        {state.map((item, index) => (
                            <SelectTeamLeader
                                key={index}
                                profilePicture={item?.profile_picture}
                                firstName={item?.first_name}
                                lastName={item?.last_name}
                                value={item?.id}
                                handleOnChange={handleChangeLeader}
                            />
                        ))}
                    </div>
                    {state.length == 0 && <p className="text-center">No existen Team Leaders disponibles</p>}
                </div>
            </div>

            <div className="mb-16 flex justify-center gap-10 mt-80">
                <button
                    type="submit"
                    onClick={() => saveLeader()}
                    className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                >
                    Guardar
                </button>
            </div>
        </Fragment>
    );
}
