import React, { useContext } from 'react';

import PermissionContext from "./PermissionContext";
import { AuthContext } from "./AuthContext";

type Props = {
  children: React.ReactNode;
}

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: React.FunctionComponent<Props> = ({ children }) => {
  const { role, permissions } = useContext(AuthContext);

  // Creates a method that returns whether the requested permission is available in the list of permissions
  // passed as parameter
  const isAllowedTo = (permission: string) => {
    if (permission == 'only_admin' && role != 'admin') return false;
    if (permission == 'only_producer' && role != 'producer') return false;
    if (permissions.admin || ['admin', 'producer'].includes(role)) return true;
    if (permissions.events) {
        if (['events', 'any'].includes(permission)) return true;
    }
    if (permissions.reports) {
        if (['reports', 'any'].includes(permission)) return true;
    }
    return false;
  }

  // Creates a method that returns whether the requested route is accesible according to permission
  // passed as parameter
  const canAccessRoute = (permission: string) => {
    if (role !== 'admin') {
      if (permissions.admin || role === 'producer') return true;
      if (permissions.events && permission === 'events')  return true;
      if (permissions.reports && permission === 'reports')  return true;
    }

    // if we get here, then role === 'admin'. In this case we have to check permissions.admin_access,
    // which has the possible values 'super_admin', 'ticketera' and 'social_media'
    if (permissions.admin_access === 'super_admin') return true;

    // if permissions.admin_access is 'ticketera' or 'social_media',
    // we have to check if it matches permission argument
    if (permissions.admin_access === permission) return true;

    return false;
  }

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return <PermissionContext.Provider value={{isAllowedTo, canAccessRoute}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
