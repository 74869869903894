import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import jsonToFormData from "@ajoelp/json-to-formdata";

import { PageContainer } from "../PageContainer";
import { Back } from "../Back";
import FormInput from "../FormElement/FormInput";
import FormRadioButton from "../FormElement/FormRadioButton";
import { ProducerSelected } from "./producers/ProducerSelected";
import { IUserRequest } from "../../types/IUser";
import { IProducersResponse, IFilterProducerRequest } from '../../types/IProducer';
import { AuthContext } from "../../context/AuthContext";
import { StateContext } from "../../context/StateProvider";
import UserService from "../../services/userService";
import ProducerService from '../../services/producerService';
import FormCheck from "../FormElement/FormCheck";
import FormToggle from "../FormToggle";

let verifyAddForm = {
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string(),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  password: yup.string().required("El password es requerido"),
  permissions: yup.string().required("Seleccione un permiso"),
};

let verifyEditForm = {
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string().required("El apellido es requerido"),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  permissions: yup.string().required("Seleccione un permiso"),
};

let verifyAddFormAdmin = {
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string(),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  password: yup.string().required("El password es requerido"),
  admin_access: yup.string().required("Seleccione un permiso"),
};

let verifyEditFormAdmin = {
  first_name: yup.string().required("El nombre es requerido"),
  last_name: yup.string().required("El apellido es requerido"),
  email: yup
    .string()
    .required("El email es requerido")
    .email("El correo electrónico no es válido"),
  admin_access: yup.string().required("Seleccione un permiso"),
};

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  picture: "",
  can_see_balance: true
};

type NewUserProps = {
  roleGiven: string;
};

const defaultResultProducers = {
  total: 0,
  pages: 0,
  rows: []
};

const defaultFiltersProducers = {
  page: 1,
  status: 'all',
  client_id: '',
};

export const NewUser = ({roleGiven = ''}: NewUserProps) => {
  const { clientProducerId  } = useContext(AuthContext);
  const { state, dispatch} = useContext(StateContext);
  const navigate = useNavigate()
  const { user_id } = useParams();

  let schema;
  if (user_id) {
    schema = yup.object().shape(roleGiven == 'admin' ? verifyEditFormAdmin : verifyEditForm);
  } else {
    schema = yup.object().shape(roleGiven == 'admin' ? verifyAddFormAdmin : verifyAddForm);
  }

  const createItems = useMutation((item: FormData) => UserService.create(item));
  const updateItems = useMutation((item: FormData) =>
    UserService.update(user_id, item)
  );
  const getUserApi = useMutation((id: string) => UserService.get(id));
  const getItems = useMutation(
    ( filters: IFilterProducerRequest ) => ProducerService.getAll(filters)
  );

  const form = useForm<IUserRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  //? Obtencion de productoras pertenecientes al usuario que ha iniciado sesion
  const [result, setResult] = useState<IProducersResponse>(defaultResultProducers);
  const [filtersProducer, setFiltersProducer] = useState<IFilterProducerRequest>(defaultFiltersProducers);

  useEffect(() => {
    handleFilter(filtersProducer);
  }, []);
  
  const handleFilter = async (filtersRequest: IFilterProducerRequest) => {
    let fil = {
      ...filtersRequest,
      client_id: clientProducerId,
    }

    await setFiltersProducer(fil);
    getItems.mutateAsync(fil).then( res => {
      setResult(res);
      dispatch({ type: 'listProducersByUser', payload: res.rows });
    });
  };

  useEffect(() => {
    if (user_id) {
      getUserApi.mutateAsync(user_id).then((res) => {
        form.reset(res);
        if (res.permissions?.admin) {form.setValue("permissions", "admin")};
        if (res.permissions?.events) {form.setValue("permissions", "events")};
        if (res.permissions?.reports) {form.setValue("permissions", "reports")};
        if (res.permissions?.admin_access) {form.setValue("admin_access", res.permissions.admin_access)};
        if (res.permissions?.can_see_balance) {form.setValue("can_see_balance", res.permissions?.can_see_balance)};
      });
    }
  }, [form.reset]);

  const onSubmit = async (data: IUserRequest) => {
    data.user_role = roleGiven || "coproducer";
    data.status = "active";
    if (clientProducerId){
      data.producer_id = clientProducerId;
      data.permitted_producers = state.selectedProducers;
    } 
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });

    dispatch({ type: "showLoaderScreen", payload: true });
    if (user_id) {
      updateItems
        .mutateAsync(formData)
        .then((r) =>{
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "toastSuccess", payload: "Registro de usuario actualizado" });
          roleGiven == 'admin' ? navigate("/admin/admins") : navigate("/client/users")

          dispatch({ type: 'selectedProducers', payload: [] });
          dispatch({ type: 'showProducers', payload: null })
        })
        .catch((reason) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "toastError", payload: "Cuenta de Correo duplicada" });
        });
    } else {
      createItems
        .mutateAsync(formData)
        .then((r) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "toastSuccess", payload: "Registro de usuario creado correctamente" });
          roleGiven == 'admin' ? navigate("/admin/admins") : navigate("/client/users")

          dispatch({ type: 'selectedProducers', payload: [] });
        })
        .catch((reason) => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({ type: "toastError", payload: "Cuenta de Correo duplicada" });
        });
    }
  };

  const onError = (error: any) => {
    console.log("error ", error);
  };

  const newsOptions = () => {
    if (roleGiven == 'admin')
      return [
        {value: "ticketera", label: "Ticketera"},
        {value: "social_media", label: "Red Social"},
        {value: "super_admin", label: "Super Admin"},
      ];
    else
      return [
        {value: "admin", label: "Administración"},
        {value: "reports", label: "Reportes"},
        {value: "events", label: "Eventos"},
      ];
  }

  const addProducer = () => {
    dispatch({type: 'addProducerEvent', payload: null})
  };

  const handleRemoveProducer = ( id: string ) => {
    dispatch({ type: 'removeSelectedProducer', payload: id });
  };

  const roleSelected = form.watch("admin_access");

  return (
    <PageContainer>
      <div className="">
        <Back onClick={() => roleGiven == 'admin' ? navigate("/admin/admins") : navigate("/client/users") } />
        <div className="flex flex-col gap-10 xl:px-[23rem]">
          <h1 className="text-center text-3xl font-bold">
            { user_id ? 'Editar ' : 'Crear nuevo ' } {roleGiven == 'admin' ? 'administrador' : 'usuario'}
          </h1>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit, onError)}>
              <FormInput
                name="first_name"
                type="text"
                label="Nombre de usuario"
              />
              <FormInput
                name="last_name"
                type="text"
                label="Apellido del usuario"
              />
              <FormInput name="email" type="email" label="Email" />
              <FormInput name="password" type="password" label="Contraseña" />

              {
                state.showProducer || user_id  ?  (
                  <div className="min-h-52 relative flex items-center justify-center rounded-lg border border-colorBorder p-10 mb-5">
                    <p className="absolute left-8 top-4 font-semibold text-gray-100">
                      Productora
                    </p>
                    <button
                      type="button"
                      className="absolute right-8 top-4 font-bold"
                      onClick={ addProducer }
                    >
                      + Asignar productora
                    </button>
                    <div className="flex flex-wrap justify-center gap-4 pt-5">
                      {
                        state.selectedProducers?.map(( _, i ) => (
                          <ProducerSelected
                            key={i}
                            data={ _ }
                            handleRemoveId={ handleRemoveProducer }
                          />
                        ))
                      }
                    </div>
                  </div>
                ) : (
                  <div className="min-h-52 relative flex items-center justify-center rounded-lg border border-colorBorder p-10 mb-5">
                    <p className="absolute left-8 top-4 font-semibold text-gray-100">
                      Productora
                    </p>

                    <button
                      className="font-bold"
                      type='button'
                      onClick={ addProducer }
                    >
                      + Asignar Productora
                    </button>
                  </div>
                )
              }

              <FormRadioButton
                name={roleGiven == 'admin' ? 'admin_access' : 'permissions'}
                label="Permisos"
                options={newsOptions()}
              />
              {roleSelected === 'ticketera' && (
                <FormCheck
                  name="can_see_balance"
                  options={[{ value: true, label: 'Puede ver balances' }]}
                />
              )}
              <div className="flex justify-center gap-10 my-10 ">
                <FormToggle
                  name="two_factor_auth_required"
                  label="Requiere 2FA?"
                  color="green"
                />
              </div>
              <div className="flex justify-center gap-10 my-10 ">
                <FormToggle
                  name="two_factor_qr_code_scanned"
                  label="Código QR escaneado?"
                  color="green"
                />
              </div>
              <div className="mb-16 flex justify-center gap-10 pt-16">
                <button
                  onClick={() => navigate("/client/users")}
                  type="button"
                  className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                >
                  {user_id ? `Editar ${roleGiven == 'admin' ? 'administrador' : 'usuario'}` : `Crear ${roleGiven == 'admin' ? 'administrador' : 'usuario'}`}
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </PageContainer>
  );
};
