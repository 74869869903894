import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from '../../../types/FormInputsProps';


const FormRadioButton: FC<FormInputProps> = ({ name, label, options } ) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
        name={name}
        control={control}
        render={({ field: { name, value, onBlur, ref, onChange } })=> (
        <div className="flex w-96 flex-col">
            <h2 className="pb-2 text-base font-medium text-gray-100">{label}</h2>
            <div className="flex w-full gap-20 ">
                { options?.map(({label: optionLabel, value: optionValue}, index) => (
                <label htmlFor={optionLabel} className="flex w-32 items-center gap-2 " key={index}>
                    <input
                      type="radio"
                      name={name}
                      id={optionLabel}
                      onBlur={onBlur}
                      value={optionValue}
                      ref={ref}
                      checked={ String(value) === String(optionValue)}
                      onChange={onChange}
                      className="rounded-full p-[.60rem] checked:bg-black-100 focus:ring-transparent active:bg-black-100"
                    />
                    <p className="font-bold">{optionLabel}</p>
                </label>
                ))}
            </div>
            {errors[name]&&
              <p className="mt-2 text-sm text-red text-red-600 dark:text-red-500">
                {String(errors[name] ? errors[name]?.message : '')}
              </p>
            }
        </div>
      )}
    />
  );
};

export default FormRadioButton;
