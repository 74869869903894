import { useNavigate } from "react-router-dom";

import { Back } from "../../Back";
import circleCheck from '../../../assets/circle-check.png';

export const CongratulationsNotification = ({}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/admin/notifications/add`);
  };

  const handleBackToList = () => {
    navigate(`/admin/notifications`);
  };

  return (
    <div>
      <Back onClick={handleBack} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <div>
          <img src={circleCheck} alt="check" className="text-center m-auto mb-4"/>
          <h1 className="text-center text-[20px] font-bold mb-4">Felicitaciones</h1>
          <p className="text-center">Mensaje enviado exitosamente</p>
        </div>

        <div className="mb-16 flex justify-center gap-10 pt-10">
          <button
            onClick={handleBack}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
          >
            Enviar Otro
          </button>
          <button
            onClick={handleBackToList}
            className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
          >
            Volver al listado
          </button>
        </div>
      </div>
    </div>
  );
};
