import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import React, { useEffect, useState, FC, useContext, useLayoutEffect } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import { toast, ToastContainer } from "react-toastify";
import SectionService from "../../services/sectionService";
import jsonToFormData from "@ajoelp/json-to-formdata";

import { IFilterRequest } from "../../types/IFilter";
import {
  ISectionRequest,
  ISectionResponse,
  ISectionsResponse,
} from "../../types/ISection";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterDate } from "../table/FilterDate";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { FilterSection } from "../table/FilterSection";
import { TitleSection } from "../TitleSection";
import { FilterOrder } from "../table/FilterOrder";
import ISortOption from "../../types/ISortOption";
import { formatDateTime } from "../../utils/format-date";
import { CardAlert } from "../AlertToastify/CardAlert";
import { PageContainer } from "../PageContainer";
import { StateContext } from "../../context/StateProvider";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};
const defaultFilters = {
  limit: config.filter_default_limit,
  page:1,
  status: 'all',
    sortBy: 'createdAt',
    sortOrder: 'desc',
}

const defaultDataFilter = { status: "", sortBy: "" };

type propsTable = {
  handleCreateorEdit: (id: string) => void;
  handleSelectItem: (data: ISectionResponse) => void;
};

export const TableSections: FC<propsTable> = ({
  handleCreateorEdit,
  handleSelectItem,
}) => {
  const { state, dispatch } = useContext(StateContext);

  const [result, setResult] = useState<ISectionsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  const getActiveSections = (res: any) => {
    let aux: string[] = [];
    {
      res.forEach((section: any) => {
        if (section.isActive) {
          aux.push(section.id);
        }
      });
    }
    dispatch({ type: "selectedSections", payload: aux });
  };

  const [paramsFilters, setParamsFilters] =
    useState<IFilterRequest>(defaultDataFilter);
  let paramFilters: any = { status: "", sortBy: "" };

  const getItems = useMutation(() => SectionService.getAll(filters));

  // let paramsFilters = { status: '', sortBy: ''};

  const updateItems = useMutation(
    ({ item, id }: { item: FormData; id: string }) =>
      SectionService.update(id, item)
  );

  const deleteItems = useMutation((id: string) => SectionService.remove(id), {
    onSuccess(data: any) {
      toast.success(data.message);
    },
  });

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    await getItems.mutateAsync().then((res) => {
      setResult(res);
      getActiveSections(res.rows);
    });
  };

  const handleFilterSection = async (filter: string) => {
    setParamsFilters({ ...filters, status: filter });
    await setFilters({ ...filters, page: 1, status: filter });
    await getItems.mutateAsync().then((res) => setResult(res));
  };

  const handleSortSection = async (sortBy: any) => {
    const itemSort = sortOptions.find((s) => s.value == sortBy);
    paramFilters = { ...filters, page: 1, sortBy: sortBy };
    if (itemSort) paramFilters["sortOrder"] = itemSort.sortOrder;
    await setFilters(paramFilters);
    await getItems.mutateAsync().then((res) => {
      setResult(res);
    });
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Esta seguro de eliminar est seccion?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const columnData = [
    "Fecha de alta",
    "Nombre",
    "Activo",
    "Tipo",
    "Número Eventos",
    "Número Noticias",
  ];

  const sortOptions: Array<ISortOption> = [
    { value: "createdAt", text: "Fecha", sortOrder: "desc" },
    { value: "name", text: "Nombre", sortOrder: "asc" },
  ];

  const filterSectionProps = [{ title: "Todos las secciones", id: "all" }];

  const getFilterSection = () => {
    return filterSectionProps;
  };

  const buildBody = (item: ISectionRequest, isActive: boolean, id?: string) => {
    const data: Omit<ISectionRequest, "events" | "news"> = {
      id: id ? id : item.id,
      name: item.name,
      isNews: item.news && item.news?.length !== 0 ? true : false,
      isActive: isActive,
    };
    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });
    return formData;
  };

  const handleActiveSection = async (item: ISectionResponse) => {
    updateItems.mutate(
      { id: item.id, item: buildBody(item, !item.isActive) },
      {
        onSuccess: () => {
          dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
        },
        onError: () => {
          dispatch({ type: "showLoaderScreen", payload: false });
          dispatch({
            type: "toastError",
            payload: "Registro no se pudo actualizar",
          });
        },
      }
    );
    const isNews = item.news && item.news.length !== 0;
    // Buscar un evento del mismo tipo (evento o novedad) que esté activo
    const checked = result.rows.find((x) => {
      return (
        x.isActive &&
        (isNews ? x.news.length > 0 : x.events.length > 0) &&
        x.id !== item.id
      );
    });

    if (checked)
      updateItems.mutate(
        { id: checked.id, item: buildBody(checked, false) },
        {
          onSuccess: () => {
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
           // updateSelected(checked.id, item.id);
            getItems.mutateAsync().then((res) => {
            setResult(res);
            getActiveSections(res.rows);
          });
          },
          onError: () => {
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({
              type: "toastError",
              payload: "Registro no se pudo actualizar",
            });
          },
        }
      );

  };

  return (
    <PageContainer>
      <div className="relative">
        <TitleSection>Secciones</TitleSection>
        <div className="absolute -top-0 right-0 ">
          <button
            onClick={() => handleCreateorEdit("")}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
          >
            Crear nueva Seccion
          </button>
        </div>
      </div>

      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
        <FilterSection
          filterSectionProps={getFilterSection()}
          handleFilter={handleFilterSection}
        />
        <div className="flex items-center gap-5 ">
          <GoSearch className="cursor-pointer text-gray-100" />
          <FilterOrder setSort={handleSortSection} options={sortOptions} />
        </div>
      </div>

      <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4">
              <GoSearch
                className="ml-auto cursor-pointer text-gray-100"
                size={16}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {result.rows.length ? (
            result.rows.map((_, i) => (
              <motion.tr
                key={_.id}
                layout
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0.2 }}
                transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
              >
                <td className="pl-4">{formatDateTime(_.createdAt)}</td>
                <td className="pl-4">{`${_.name}`}</td>
                <td className="pl-4">
                  <input
                    id="checkbox"
                    onClick={() => handleActiveSection(_)}
                    type="checkbox"
                    checked={_.isActive}
                    className="text-black-600 focus:ring-black-500 rounded border-gray-200"
                  />
                </td>
                <td className="pl-4">
                  {!_.news || _.news.length === 0 ? "Eventos" : "Noticias"}
                </td>
                <td className="pl-4">{`${_.events?.length}`}</td>
                <td className="pl-4">{_.news ? `${_.news?.length}` : "0"}</td>
                <td className="pl-4">
                  <DropdownDots
                    options={[
                      {
                        title: "Editar",
                        action: () => handleCreateorEdit(_.id),
                        icon: <FaEdit size={23} />,
                      },
                      {
                        title: "Eliminar",
                        action: () => handleDelete(_.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                </td>
              </motion.tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};
