import { FC } from "react";
import { Status } from "../Status";
import { IUserResponse } from "../../types/IUser";
import { formatDate } from "../../utils/format-date";
import config from "../../config/variables";

const styles = {
  titleLi: "font-bold pb-1 text-lg",
  pLi: "text-gray-100",
  wrapperLi: "flex justify-between gap-28 ",
};

type typeProps = {
  edit: boolean,
  data: IUserResponse | undefined;
}
const defaultResult = {
  total: 0,
  pages: 0,
  rows: []
}
const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: 'all',
}

const statuses = [
  {value: 'active', label: 'Activo'},
  {value: 'activo', label: 'Activo'},
  {value: 'pending', label: 'pendiente'},
  {value: 'pendiente', label: 'Pendiente aprobación'},
  {value: 'paused', label: 'pausado'},
  {value: 'send', label: 'enviado'},
  {value: 'sent', label: 'enviado'},
  {value: 'pending_approval', label: 'requiere aprobación'},
  {value: 'finished', label: 'finalizado'},
]

const getStatusFromValue = (status: string) => {
  let result = statuses.find(i => i.value === status);
  if (!result) {
      result = {value: status, label: 'Esperando aprobación'};
  }
  return result.label;
}
export const BannerUser: FC<typeProps> = ({ edit, data }) => {
  return (
    <div className="flex justify-between h-[10rem] w-full overflow-auto rounded-xl px-5 py-5 outline outline-2 outline-colorBorder">
      <div className="flex xl:gap-5 ">
        <div className="flex h-full w-24 overflow-hidden rounded-lg bg-[#D6D6D6]">
          <img src={data?.profile_picture} width="100%" height="100%" className="object-cover" />
        </div>
        <div className="flex flex-col ">
          {
            data?.full_name?.trim() === 'undefined' ? '' : data?.full_name && <h1 className="text-2xl font-bold">{`${data?.full_name}`}</h1>
          }
          <p className="font-medium text-gray-100">#{ data?.id }</p>
        </div>
      </div>

          <div className="">
            <h3 className={`${styles.titleLi}`}>Fecha de alta</h3>
            {
              data?.created_at && <p className={`${styles.pLi} w-[119px]`}>{formatDate(data?.created_at)}</p>
            }
          </div>
          <div>
            <h3 className={`${styles.titleLi}`}>Email</h3>
            <p className={`${styles.pLi}`}>{data?.email}</p>
          </div>
          <div>
            {/* <h3 className={`${styles.titleLi}`}>Reward</h3>
            <p className={`${styles.pLi}`}>{data?.points} puntos</p> */}
          </div>

          <div className=" flex items-center ">
            {
              data?.status && 
              <div className="flex w-30 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder px-10 py-2 text-center ">
              <div className={`rounded-full ${['activo','active','finished'].includes(data?.status) ? 'bg-green' : 'bg-red'} p-[.35rem] `} />
              <p className="capitalize">{getStatusFromValue(data?.status)}</p>
            </div>
            }
          </div>
    </div>
  );
};
