import { useState, useEffect } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { TitleSection } from "../TitleSection";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { IFilterRequest } from "../../types/IFilter";
import {IFeedbacksResponse} from "../../types/IFeedback";
import { formatDate } from "../../utils/format-date";
import { PageContainer } from "../PageContainer";
import HelpService from "../../services/helpService";
import FeedbackService from "../../services/feedbackService";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: []
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  query: '',
};

const columnData = [
  "ID",
  "Fecha",
  "Usuario",
  "Email",
  "Asunto",
  "Mensaje",
  "Acciones"
];

export const FeedbackView = ({ typeMessages = 'feedback' }) => {
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  const getFeedback = useMutation(
    (newFilters: IFilterRequest) => FeedbackService.getAll(newFilters)
  );
  const getHelp = useMutation(
    (newFilters: IFilterRequest) => HelpService.getAll(newFilters)
  );
  const deleteFeedback = useMutation(
    (id: string) => FeedbackService.remove(id)
  );
  const deleteHelp = useMutation(
    (id: string) => HelpService.remove(id)
  );

  const [result, setResult] = useState<IFeedbacksResponse>(defaultResult);
  const [search, setSearch] = useState("");

  let typeComments = 'feedback';
  if (window.location.pathname.includes('/user_help')) {
    typeComments = 'help'
  }

  useEffect(() => {
    handleFilter(filters);
  }, []);

  useEffect(() => {
    if (typeMessages != 'feedback') typeComments = 'help';
    else typeComments = 'feedback';
    handleFilter(defaultFilters);
  }, [typeMessages]);

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    handleFilter({ page: 1, query: e.target.value });
  };

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    switch (typeComments) {
      case 'feedback': await getFeedback.mutateAsync({ ...filters, ...filter }).then(res => {
        setResult(res)
      });
        break;
      case 'help': await getHelp.mutateAsync({ ...filters, ...filter }).then(res => {
        setResult(res)
      });
        break;
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Está seguro de eliminar este ítem?')) {
      switch (typeComments) {
        case 'feedback': await deleteFeedback.mutateAsync(id).then(res => {
          getFeedback.mutateAsync(filters).then(res => setResult(res));
        });
          break;
        case 'help': await deleteHelp.mutateAsync(id).then(res => {
          getHelp.mutateAsync(filters).then(res => setResult(res));
        });
          break;
        }
    }
  };

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>{typeComments == 'feedback' ? 'Feedback' : 'Ayuda Usuarios'}</TitleSection>
      </div>
      <div className="flex justify-end gap-2 py-2">
        <input type="text" placeholder="Search..." className="rounded-full border-[2px] border-black-100 px-4 py-1 font-bold text-black-100  hover:text-black" value={search} onChange={searcher} />
      </div>
      <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {result.rows.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">#{row.id}</td>
              <td className="pl-4">{formatDate(row.createdAt)}</td>
              <td className="pl-4">{row.user ? `${row.user.first_name} ${row.user.last_name}` : 'Usuario de Bombo'}</td>
              <td className="pl-4">{row.user ? row.user.email : 'Usuario de Bombo'}</td>
              <td className="pl-4"><strong>{row.subject}</strong></td>
              <td className="pl-4">{row.message}</td>
              <td className="pl-4">
                <DropdownDots options={[
                  { title: 'Eliminar', action: () => handleDelete(row.id), icon: (<FaTrashAlt size={23} />) }
                ]} />
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {result?.rows.length == 0 && <p className="text-center">No existen mensajes disponibles</p>}

      <div className="flex justify-between pt-10">
        <FilterPage handleFilter={handleFilter} total={result.total} limit={filters.limit} status={filters.status} currentPage={filters.page} />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};
