import { useState, useEffect } from "react";
import {useNavigate, useParams} from 'react-router-dom';
import { FaTrashAlt } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { TitleSection } from "../TitleSection";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import { IFilterRequest } from "../../types/IFilter";
import { formatDate } from "../../utils/format-date";
import { PageContainer } from "../PageContainer";
import EventService from "../../services/eventService";
import NewsService from "../../services/newsService";
import { ICommentsResponse } from "../../types/IComment";
import config from "../../config/variables";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: []
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  query: '',
};

const columnData = [
  "ID",
  "Fecha",
  "Usuario",
  "Comentario",
  "Reportado",
  "Acciones"
];

export const CommentsView = () => {
  const {event_id, new_id} = useParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);

  const getEventsComments = useMutation(
    (newFilters: IFilterRequest) => EventService.getComments(newFilters)
  );
  const getNewsComments = useMutation(
    (newFilters: IFilterRequest) => NewsService.getComments(newFilters)
  );
  const deleteEventComments = useMutation(
    (id: string) => EventService.removeComment(id)
  );
  const deleteNewComments = useMutation(
    (id: string) => NewsService.removeComment(id)
  );

  const [result, setResult] = useState<ICommentsResponse>(defaultResult);
  const [search, setSearch] = useState("");

  let typeComments = 'events'
  if (window.location.pathname.includes('news/')) {
    typeComments = 'news'
  }

  useEffect(() => {
    let newFilters: any;
    switch (typeComments) {
      case 'events': newFilters = {...filters, id: event_id};
      break;
      case 'news': newFilters = {...filters, id: new_id};
      break;
      default: newFilters = filters;
    }
    handleFilter(newFilters);
  }, []);

  const searcher = async (e: any) => {
    setSearch(e.target.value)
    handleFilter({ page: 1, query: e.target.value });
  }

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
    switch (typeComments) {
      case 'events': await getEventsComments.mutateAsync({ ...filters, ...filter }).then(res => {
        setResult(res)
      });
        break;
      case 'news': await getNewsComments.mutateAsync({ ...filters, ...filter }).then(res => {
        setResult(res)
      });
        break;
    }
  }

  const handleDelete = async (id: string) => {
    if (window.confirm('Está seguro de eliminar esta notificación?')) {
      switch (typeComments) {
        case 'events': await deleteEventComments.mutateAsync(id).then(res => {
          getEventsComments.mutateAsync(filters).then(res => setResult(res));
        });
          break;
        case 'news': await deleteNewComments.mutateAsync(id).then(res => {
          getNewsComments.mutateAsync(filters).then(res => setResult(res));
        });
          break;
      }
    }
  }

  const handleReports = async (id: string) => {
    let prefix = '/admin';
    switch (typeComments) {
      case 'events': prefix += '/events/' + event_id;
      break;
      case 'news': prefix += '/news/' + new_id;
      break;
    }
    navigate(`${prefix}/comments/${id}/reports`);
  }

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Comentarios {result.rows[0]?.from ? `(${result.rows[0]?.from})` : `(${event_id || new_id})`}</TitleSection>
      </div>
      <div className="flex justify-end gap-2 py-2">
        <input type="text" placeholder="Search..." className="rounded-full border-[2px] border-black-100 px-4 py-1 font-bold text-black-100  hover:text-black" value={search} onChange={searcher} />
      </div>
      <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4"></th>
          </tr>
        </thead>
        <tbody>
          {result.rows.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">#{row.id}</td>
              <td className="pl-4">{formatDate(row.createdAt)}</td>
              <td className="pl-4">{row.user ? `${row.user.first_name} ${row.user.last_name}` : 'Usuario de Bombo'}</td>
              <td className="pl-4">{row.comment}</td>
              <td className="pl-4">{row.reports}</td>
              <td className="pl-4">
                <DropdownDots options={[
                  { title: 'Ver Reportes', action: () => handleReports(row.id) },
                  { title: 'Eliminar', action: () => handleDelete(row.id), icon: (<FaTrashAlt size={23} />) }
                ]} />
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {result?.rows.length == 0 && <p className="text-center">No existen comentarios disponibles</p>}

      <div className="flex justify-between pt-10">
        <FilterPage handleFilter={handleFilter} total={result.total} limit={filters.limit} status={filters.status} currentPage={filters.page} />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};
