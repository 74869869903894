import { Fragment, useContext } from "react";
import { IRrppResponse } from "../../../../../types/IRrpp";
import "./CardSeller.css"
import {PictureAndName} from "../../../components/PictureAndName/PictureAndName";
import {useParams} from "react-router";
import {useMutation} from "@tanstack/react-query";
import TeamService from "../../../../../services/teamService";
import jsonToFormData from "@ajoelp/json-to-formdata";
import {useNavigate} from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext";

type propsType = {
    producer_producer_id?: string
    data: IRrppResponse;
    handleSelectRrpp: (item: IRrppResponse) => void;
}

export const CardSeller = ({data, handleSelectRrpp}: propsType) => {

    const auth = useContext(AuthContext);

    const navigate = useNavigate();
    const {leader_id, producer_id, producer_producer_id} = useParams();
    const updateItems = useMutation(
        (item: FormData) => TeamService.update('cms', item),
    );

    const handleSubmit = () => {
        const dataBuilder: any = {
            user_id: data.id,
            leader_id: leader_id,
            producer_producer_id: producer_producer_id || auth.producer?.id,
            team_role: 'seller',
        };

        let formData = jsonToFormData(dataBuilder, {
            arrayIndexes: true,
            excludeNull: true
        });
        const redirectSuffix = '?tab=sellers'
        updateItems.mutateAsync(formData).then(res => {
            if (auth.user.user_role == 'admin') {
                navigate(`/admin/producers/${producer_id}/producer_producer/${producer_producer_id}/rrpp/` + leader_id + redirectSuffix)
            } else {
                navigate('/client/rrpp/' + leader_id + redirectSuffix)
            }
            // navigate('/client/rrpp/'+leader_id+redirectSuffix)
        }).catch((reason)=>{});
    }

    return (
        <Fragment>
            <table id="SellerContainer" className="table-auto w-full">
                <thead>
                    <tr>
                        <th align="left"><p className="font-sans font-[500] text-[14px] leading-[21px] text-[#8083A3] ml-2">Nombre</p></th>
                        <th align="left"><p className="font-sans font-[500] text-[14px] leading-[21px] text-[#8083A3]">Email</p></th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="ml-2">
                                <PictureAndName profilePicture={data.profile_picture} firstName={data.first_name} lastName={data.last_name} />
                            </div>
                        </td>
                        <td><span className="text-[#7B7B7B]">{data.email}</span> </td>
                        <td>
                            {
                                leader_id ?
                                    <button onClick={() => handleSubmit()} className="flex justify-center items-center bg-black-100 text-white rounded-full border-[2px] py-2 px-3 ml-auto text-xs pl-5 pr-5 mr-2">Agregar</button>
                                    :
                                    <button onClick={() => handleSelectRrpp(data)} className="flex justify-center items-center bg-black-100 text-white rounded-full border-[2px] py-2 px-3 ml-auto text-xs pl-5 pr-5 mr-2">Crear Vendedor</button>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
}
