import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { BannerRrpp } from './BannerRrpp';
import { FilterSection } from "../../table/FilterSection";
import { Back } from "../../Back";
import { AuthContext } from "../../../context/AuthContext";
import { EventsSeller } from "./events/EventsSeller";
import { LeaderSeller } from "./leaderSellers/LeaderSeller";
import RrppService from "../../../services/rrppService";
import { IRrppResponse } from "../../../types/IRrpp";
import { PageContainer } from "../../PageContainer";

type selectedProps = {
  handleSelect?: React.Dispatch<React.SetStateAction<null>>;
};

export const SelectedRrpp = ({ handleSelect }: selectedProps) => {
  const { user, role, producer } = useContext(AuthContext);
  const navigate = useNavigate();
  const {seller_id, producer_id, producer_producer_id, event_id} = useParams();
  const queryParams = new URLSearchParams(window.location.search)
  const tab = queryParams.get("tab");

  const [filterSectionProps, setFilterSectionProps] = useState<Array<any>>([]);
  const [data, setData] = useState<IRrppResponse>({} as IRrppResponse);
  const [teamRole, setTeamRole] = useState<string>();

  const getItem = useMutation(
      () => RrppService.get(seller_id || '', {producer_producer_id: producer?.id})
  );

  useEffect(() => {
    getItem.mutateAsync().then((res)=>{
      const teamrole = getTeamRoleForProducer(res);
      setTeamRole(teamrole)
      res.user_role = teamrole;
      setData(res);
      if (teamrole == 'leader'){
        setFilterSectionProps(filterSectionPropsLeader);
      }
      else{
        setFilterSectionProps(filterSectionPropsSeller);
      }
    });
    setFormValue(tab || 'events');
  },[seller_id]);

  const getTeamRoleForProducer = (row: IRrppResponse) => {
    if (row.teams && row.teams.length) {
      const team = row.teams.find((i) => i.producer_producer_id == producer?.id);
      if (team) {
        return team.team_role;
      }
    }
    return row.user_role;
  }

  const filterSectionPropsSeller = [
    { title: "Eventos en venta", id: "events" },
  ];

  const filterSectionPropsLeader = [
      ...filterSectionPropsSeller,
    { title: "Vendedores", id: "sellers" },
  ];

  const [formValue, setFormValue] = useState(tab || 'events');

  const handleFormValue = (e: any) => {
    setFormValue(e.target.id);
  };

  const handleBack = () => {
    let prefix;
    if (user.user_role != 'admin') {
      prefix = '/client'
    } else {
      if(producer_id && producer_producer_id){
        prefix = `/admin/producers/${producer_id}/producer_producer/${producer_producer_id}`;
      }else{
        prefix = "/admin";
      }
    }

    if(window.location.href.indexOf("/validators/") != -1){
      navigate(prefix + '/validators');
    } else {
      if (event_id) navigate(prefix + '/events/' + event_id);
      else navigate(prefix + '/rrpp');
    }
  };

  const handleNewSeller = () => {
    let prefix = '';
    if (role != 'admin') {
      prefix += '/client';
    } else {
      prefix += `/admin/producers/${producer_id}/producer_producer/${producer_producer_id}`;
    }
    navigate(prefix + '/rrpp/new-seller/' + seller_id);
  };

  const showFormValue = () => {
    switch (formValue) {
      case "events":
        return <EventsSeller />;
      case "sellers":
        return <LeaderSeller />;
      default:
        return <EventsSeller />;
    }
  };

  return (
    <PageContainer>
      <Back onClick={handleBack}/>
      <div className="pt-6">
        <BannerRrpp selected={data}/>
      </div>
      <div className="w-full overflow-auto border-b pt-10">
        <form
          className="flex gap-10 font-medium text-gray-100"
          onChange={(e) => handleFormValue(e)}
        >
          <FilterSection filterSectionProps={filterSectionProps} handleFilter={()=>{}} defaultSelected={formValue}/>
          {teamRole == 'leader' && (role != 'admin' || !!producer_producer_id) &&
            <button
              role="button"
              onClick={handleNewSeller}
              className={`rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white ml-auto mb-1 ${formValue == 'events'?'hidden':''}`}
            >
              Agregar Vendedor
            </button>
          }
        </form>
      </div>
      <div className="pt-10">{showFormValue()}</div>
    </PageContainer>
  );
};
