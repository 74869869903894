/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState } from "react"
import { GrClose } from "react-icons/gr";

type propsType = {
    handleCloseModal: () => void,
    handleUpload: (text: string) => void,
}

export const ModalLink = ({ handleCloseModal, handleUpload }: propsType) => {
    const [text, setText]= useState("");
    return (
        <Fragment>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div
                    className="fixed inset-0 w-full h-full backdrop-blur-md" style={{ backgroundColor: 'rgba(0, 0, 0, 0.10)' }}
                    onClick={handleCloseModal}
                ></div>
                <div className="flex items-center min-h-screen px-[20px] py-8">
                    {/*container */}
                    <div className="relative w-[800px] my-6 mx-auto">
                        {/*content*/}
                        
                        <div className="border-0 rounded-[12px] shadow-lg py-12 px-7 relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between rounded-t mb-2">
                            <h3 className="text-[22px] font-black leading-10">
                            Ingresar link
                            </h3>
                            <GrClose size={20} className="cursor-pointer" onClick={handleCloseModal}/>
                        </div>
                            <div className="mx-0 my-[20px] mb-[40px]">
                                <input 
                                    className="w-full rounded-lg border border-colorBorder p-3 pl-8 font-bold text-black-100  " 
                                    value={text}
                                    placeholder="https://www.youtube.com/"
                                    onChange={e => setText(e.target.value)}
                                />
                            </div>

                            <button onClick={()=>handleUpload(text)}  className="py-[15px] mx-[6.500%] rounded-[25px] bg-[#000000] shadow-[0px 4px 12px rgba(248, 176, 240, 0.14)] text-white text-[16px] font-[900] leading-[20px] tracking-[-0.04em]">Confirmar</button>
                        </div>
                        {/*content*/}
                    </div>
                    {/*container*/}
                </div>
            </div>
        </Fragment>
    )
}