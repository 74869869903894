import React, { useState, useContext, useEffect, Fragment } from 'react'
import { GrClose } from 'react-icons/gr';
import { IProducerResponse } from '../../../types/IProducer';
import { Artists } from '../../events/NewEvent/artist/Artists';
import { StateContext } from '../../../context/StateProvider';

export const PopUpProducers = () => {
    const { state, dispatch } = useContext(StateContext);
    const [selectedProducersIds, setSelectedProducersIds] = useState<Array<string>>([]);

    const [producers, setProducers] = useState<Array<IProducerResponse>>(
        state.listProducersByUser
    );

    const isSelected = ( value: string ) => {
        return selectedProducersIds?.includes(value);
    }

    useEffect(() => {
        setSelectedProducersIds(
            state.selectedProducers.map(( producer ) => {
                return producer.id;
            })
        );
    }, [])
    

    const handleOnChange = async ( event: React.ChangeEvent<HTMLInputElement> ) => {
        const value = event.target.value;

        if( selectedProducersIds?.includes( value ) ){
            const idx = selectedProducersIds?.indexOf(value);
            selectedProducersIds?.splice(idx,1);
            setSelectedProducersIds([...selectedProducersIds]);
        }else{
            setSelectedProducersIds([...selectedProducersIds, ...[value]]);
        }
    };

    const addProducerEvent = () => {
        dispatch({type: 'addProducerEvent', payload: null})

    }

    const showProducers = () => {
        dispatch({ type: 'showProducers', payload: null})
    }

    const setSelectedProducers = () => {
        const selectedProducers: any[] = [];
        state.listProducersByUser.forEach(( producer ) => {
            if( selectedProducersIds.includes(producer.id) ){
                selectedProducers.push( producer );
            }
        });

        dispatch({ type: 'selectedProducers', payload: selectedProducers });
    }

    const handleChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if( e.target.checked ) setSelectedProducersIds( producers.map(( producer ) => producer.id) );
        else setSelectedProducersIds([]);
    }

    return (
        <div
            className='fixed z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md'
            style={{ minHeight: 'calc(100vh)' }}
        >
            <div
                className='absolute top-20 m-auto flex flex-col justify-center rounded-2xl bg-white'
                style={{ minHeight: 'calc(60vh - 160px)' }}
            >
                <div className='flex w-full justify-between border-b border-color-Border px-8 pb-4 pt-5 text-center'>
                    <div/>
                    <h3 className='text-lg font-bold'>
                        Asignación de productora para el usuario
                    </h3>
                    <button onClick={ addProducerEvent }>
                        <GrClose />
                    </button>
                </div>
                <div className='w-[47rem] flex-col flex-wrap overflow-auto px-10 pt-4'>
                    { producers.length > 0 ? 
                        <Fragment>
                            <label htmlFor={ "all" } className='w-[20rem]' >
                                <div
                                    className='flex w-full items-center gap-5 rounded-2xl py-5 pl-4 font-medium'
                                >
                                    <input 
                                    type="checkbox"
                                    name="producer_ids[]"
                                    id={ 'all' } 
                                    onChange={ handleChangeSelectAll }
                                    className='ml-auto rounded-full p-2 checked:bg-black-100 focus:ring-transparent active:bg-black-100'
                                    />
                                    Seleccionar todos
                                </div>
                            </label>
                        </Fragment> 
                        :
                        ''
                    }
                    <p className='pb-4 font-bold'>
                        Productoras
                    </p>
                    <form
                        className='flex flex-col items-center justify-center gap-5'
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <div className='flex flex-wrap justify-between gap-3'>
                            {
                                producers.map(( item, i ) => (
                                    <Artists
                                        key={i}
                                        name={ item.name }
                                        value={ item.id }
                                        handleOnChange={ handleOnChange }
                                        isSelected={ isSelected( item.id ) }
                                    />
                                ))
                            }
                        </div>
                        {
                            state.listProducersByUser.length === 0 && (
                                <p className='text-center'>No existen productoras disponibles</p>
                            )
                        }
                    </form>
                </div>
                <div className='flex w-full justify-between border-b border-colorBorder px-8 pb-4 pt-5 text-center'>
                    <button
                        type='submit'
                        className='m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white'
                        onClick={ () => {
                            if( !state.showProducer ) showProducers();
                            addProducerEvent();
                            setSelectedProducers();

                        } }
                    >
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    )
}
