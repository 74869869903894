import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion"
import { FaRedo, FaTrashAlt, FaSearch } from "react-icons/fa"
import { GoSearch } from "react-icons/go"
import { toast } from "react-toastify";
import UserService from "../../../services/userService";
import { IUsersResponse } from "../../../types/IUser";
import { formatDate, formatDateTime } from "../../../utils/format-date";
import DropdownDots from "../../FormElement/DropdownDots"
import {IReportedsResponse} from "../../../types/IReported";
import icon from "../../../assets/imgIcon.svg";
import React from "react";
import EventService from "../../../services/eventService";
import PictureReportsService from "../../../services/pictureReportsService";

type selectedProps = {
    // id: string;
    data: IReportedsResponse;
    updateData: (filter: string) => void;
  };

const columnData = [
    "Fecha",
    "Imagen",
    "Autor",
    "Email",
    "Reportado por:",
    "Email reporter:",
    "Razón",
    "Acciones",
];

export const PicturesReported = ({data, updateData}: selectedProps) => {
    const deletePicture = useMutation((id: string) => PictureReportsService.deletePicture(id));

    const handleViewPicture = (url: string) => {
        window.open(url, "_blank");
    }

    const handleDelete = async (id: string) => {
        if (confirm('Está seguro de eliminar esta imagen?')) {
            deletePicture.mutateAsync(id).then((r: any)=>{
                updateData('pictures');
            });
        }
    }
    return (
        <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
                <thead className=" divide-solid border ">
                    <tr className=" pb-4 text-sm font-bold text-gray-100">
                        {columnData.map((column, index) => (
                            <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.rows.length ? data.rows.map((_, i) => (
                        <motion.tr
                            key={i}
                            layout
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0.2 }}
                            transition={{ type: "spring", stiffness: 100, bounce: 0 }}
                            className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
                        >
                            <td className="pl-4">{formatDateTime(_.createdAt)}</td>
                            <td className="pl-4">
                                <div className="flex h-32 items-center justify-center border border-[#E4E6E8] bg-[#F5F5FA]">
                                    <img src={_.picture?.picture_url} width="auto" style={{maxWidth: '100%', maxHeight: '100%'}}/>
                                </div>
                            </td>
                            <td className="pl-4">{_.picture?.author ? `${_.picture?.author.first_name} ${_.picture?.author.last_name}` : '(usuario de bombo)'}</td>
                            <td className="pl-4">{_.picture?.author ? _.picture?.author.email : '(usuario de bombo)'}</td>
                            <td className="pl-4">{_.user ? `${_.user.first_name} ${_.user.last_name}` : 'Usuario de Bombo'}</td>
                            <td className="pl-4">{_.user ? _.user.email : 'Usuario de Bombo'}</td>
                            <td className="pl-4">
                                {_.reason || '-'}
                            </td>
                            <td className="pl-4">
                                <td className="pl-4">
                                    <DropdownDots options={[
                                        { title: 'Ver Imagen', action: () => handleViewPicture(_.picture.picture_url), icon: (<FaSearch size={23} />) },
                                        { title: 'Eliminar Imagen', action: () => handleDelete(_.picture_id), icon: (<FaTrashAlt size={23} />) }
                                    ]} />
                                </td>
                            </td>
                        </motion.tr>
                    )) : <></>}
                </tbody>
            </table>
    )
}
