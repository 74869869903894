import api from "./api";
import authHeader from "./authHeader";
import { IPaginationResponse } from "../types/IPagination";
import { IScheduleResponse } from "../types/ISchedule";

const getAll = async (params: object = {}) => {
  const response = await api().get<IPaginationResponse>(`calendar_event`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const get = async (id: string) => {
  const response = await api().get<IScheduleResponse>(
    `calendar_event/detail/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const create = async (item: FormData) => {
  const response = await api().post<any>(`calendar_event/create`, item, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  });
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<IScheduleResponse>(
    `calendar_event/update/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const remove = async (id: string) => {
  const response = await api().delete<IScheduleResponse>(
    `calendar_event/delete/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const ScheduleService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default ScheduleService;
