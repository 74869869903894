import moment from "moment/moment";
import 'moment/locale/es';
moment.locale('es');

export const formatDate = (date?: string) => {

    if (!date) return date;
    else {
        // const newDate = date.substr(0,date.length-2)
        var dato = moment(date).utcOffset(3, true).format('DD MMM YYYY');
        const convertDate = dato.replace(". ", " ")
        return convertDate;
    }

}

export const formatDateTime = (date: string, utc= "-00:00") => {
    if (!date) return date;
    else {
        // const newDate = date.substr(0,date.length-2)
        var dato = moment(date).utcOffset(3, true).format('DD MMM YYYY HH:mm');
        const convertDate = dato.replace(". ", " ")
        return convertDate;
    }
}

export const formatHumanDateTime = (date: string) => {
    if (!date) return date;
    else {
        return moment(date).format('dddd DD [de] MMMM - HH:mm');
    }
}

export const formatHumanDate= (date: string) => {
    if (!date) return date;
    else {
        return moment(date).format('DD [de] MMMM YYYY');
    }
}

export const buildISODateTimeString = (date: string, time: string): string => {
    if (date && time) {
        const expDate = moment(date);
        const expTime = moment(time, 'HH:mm');
        expDate.set({
            hour: expTime.get('hour'),
            minute: expTime.get('minute'),
            second: expTime.get('second'),
        });
        // console.log(expDate.format())
        console.log(expDate.toISOString())
        return expDate.toISOString();
    } else {
        return date;
    }
}


export const formatDateHours = (date: string) => {
    // const newDate = date.substr(0,date.length-2)
    const dateReturn = moment(date).utcOffset(3, true).format('DD MM yy hh:mm:ss');
    return dateReturn
};