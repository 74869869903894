import React, {Fragment, useEffect, useRef, useState} from "react";
import { useContext } from "react";
import {StateContext} from "../../../context/StateProvider";
import star from "../../../assets/star.svg"
import starEmpty from "../../../assets/star-empty.svg"
import {useMutation} from "@tanstack/react-query";
import EventReviewService from "../../../services/eventReviewService";
import {useForm} from "react-hook-form";
import {IEventRequest} from "../../../types/IEvent";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {IEventReviewRequest} from "../../../types/IEventReview";
import jsonToFormData from "@ajoelp/json-to-formdata";
import {setFormErrorsFromServer} from "../../../utils/form";
import useComponentVisible from "../../../hooks/use-component-visible.hook";

const initialEventReviews = {
  // organization_review: 0,
  // sound_review: 0,
  // lighting_review: 0,
  personal_experience: 0,
  event_rating: 0,
}

const initialArtistsReviews = {
  artist_1: 0,
  artist_2: 0,
}

export const PopUpReview = ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [reviews, setReviews] = useState<any>(initialEventReviews);
  const [artistsReviews, setArtistsReviews] = useState<any>(initialArtistsReviews);
  const ref = useRef<HTMLDivElement>(null);

  const updateItem = useMutation(
      (item: FormData) => EventReviewService.update(state.reviewData.id, item)
  );
  const form = useForm<IEventReviewRequest>({
    defaultValues: {...initialEventReviews}
  });
  const saveReview = () => {
    let formData = jsonToFormData(reviews, {
      arrayIndexes: true,
      excludeNull: true
    });
    updateItem.mutateAsync(formData).then(res=>{
      dispatch({ type: "showReview", payload: null });
    })
        .catch((reason)=>{
          setFormErrorsFromServer(reason, form.setError)
        });
  };

  const handleChangeEventReview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id.includes('organization_review'))
      setReviews({...reviews, organization_review: e.target.value});
    if (e.target.id.includes('sound_review'))
      setReviews({...reviews, sound_review: e.target.value});
    if (e.target.id.includes('lighting_review'))
      setReviews({...reviews, lighting_review: e.target.value});
    if (e.target.id.includes('personal_experience'))
      setReviews({...reviews, personal_experience: e.target.value});
    if (e.target.id.includes('event_rating'))
      setReviews({...reviews, event_rating: e.target.value});
  }

  const handleChangeArtistReview = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.id.split('-')[0];
    const newValues = {...artistsReviews};
    newValues[key] = e.target.value;
    setArtistsReviews(newValues);
  }

  useEffect(() => {
    setReviews({
      organization_review: state.reviewData.organization_review,
      sound_review: state.reviewData.sound_review,
      lighting_review: state.reviewData.lighting_review,
      personal_experience: state.reviewData.personal_experience,
      event_rating: state.reviewData.event_rating,
    });
  },[]);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      dispatch({ type: "showReview", payload: null });
    }
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      dispatch({ type: "showReview", payload: null });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const eventLabelsLeft = [
    // {key: 'organization_review', label: '¿Cómo estuvo la organización?'},
    // {key: 'sound_review', label: '¿Cómo estuvo el sonido?'},
    // {key: 'lighting_review', label: '¿Cómo estuvo la iluminación?'},
    {key: 'personal_experience', label: '¿Cómo la pasaste?'},
  ]

  const eventLabelsRight = [
    {key: 'event_rating', label: '¿Cómo estuvo el evento?'},
  ]
  const getName = () => {
    let user = state.reviewData.user[0];
    let fullname = "Desconocido";
    if(user){
        fullname = `${user.first_name} ${user.last_name}`;
    }
    return fullname;
  }

  return (
    <div className="fixed z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md">
      <div className="absolute max-w-[928px] max-h-[611px] top-20 m-auto flex flex-col justify-center rounded-2xl bg-white" ref={ref}>
        <div className="w-full justify-between border-b border-colorBorder px-8  pb-4 pt-5 text-center bg-[#00000008]">
          {
            state.reviewData.event &&
            <Fragment>
              <img src={state.reviewData.event.banner} alt="" className="w-auto h-[104px] m-auto object-cover"/>
              <h3 className="text-lg font-bold">
                {state.reviewData.event.name}
              </h3>
              <p>{state.reviewData.event.about}</p>
            </Fragment>
          }
        
        </div>
        <div className=" h-[40rem] w-[47rem] flex-col flex-wrap m-auto  overflow-auto  px-10 pt-4">
          <div className="text-center mb-6">
            <p className="text-lg font-bold">¿Cómo estuvo tu experiencia?</p>
            <p className="text-sm font-bold ">{getName()}</p>
            <p className="text-sm pb-6">Esta revisión se utilizará para mejorar las próximas experiencias juntos.</p>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="w-full flex items-center justify-center divide-x gap-2">
              <div className="w-1/2 text-center">

                {eventLabelsLeft.map((item, index) => (
                    <div key={index} className="mb-6">
                      <p className="text-sm font-bold mb-2">{item.label}</p>
                      <div className="flex justify-between">
                        {[...Array(5)].map((_, i) => (
                            <label htmlFor={item.key+i} key={i} className="w-12 h-12 bg-[#00000008] rounded-full flex items-center justify-center">
                              <input type="radio" name={item.key} id={item.key+i} value={i + 1} className="hidden peer" />
                              {(i+1) <= reviews[item.key] ?
                                  <img src={star} alt=""/> :
                                  <img src={starEmpty} alt=""/>
                              }
                            </label>
                        ))}
                      </div>
                    </div>
                ))}
              </div>
              <div className="w-1/2 text-center pl-2">

                {eventLabelsRight.map((item, index) => (
                    <div key={index} className="mb-6">
                      <p className="text-sm font-bold mb-2">{item.label}</p>
                      <div className="flex justify-between">
                        {[...Array(5)].map((_, i) => (
                            <label htmlFor={item.key+i} key={i} className="w-12 h-12 bg-[#00000008] rounded-full flex items-center justify-center">
                              <input type="radio" name={item.key} id={item.key+i} value={i + 1} className="hidden peer"/>
                              {(i+1) <= reviews[item.key] ?
                                  <img src={star} alt=""/> :
                                  <img src={starEmpty} alt=""/>
                              }
                            </label>
                        ))}
                      </div>
                    </div>
                ))}
              </div>
            </div>
            <div className=" pt-5">
             {/*  <button
                onClick={saveReview}
                type="submit"
                className="m-auto w-72 rounded-full border-[2px] border-black-100 bg-black-100 px-12 py-2 font-medium text-white"
              >
                Enviar
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
