import React, { useEffect, FC } from "react";
import { IFilterRequest } from "../../types/IFilter";
import config from "../../config/variables";

type filterPage = {
  handleFilter: (filter: IFilterRequest) => void;
  status?: string;
};

export const FilterQtyPage: FC<filterPage> = ({ handleFilter=()=>{} }) => {

  const handleRowPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let limit = event.target.value;
    let filter = {
      limit: parseInt(limit),
      page: 1,
      // status: status
    };
    handleFilter(filter);
  }
  return (
    <div className="text-sm">
        <select
          name="filterPage"
          id="filterPage"
          className="cursor-pointer rounded-lg pl-4 pr-8 py-3 font-medium text-gray-100 outline outline-1 outline-colorBorder"
          onChange={handleRowPerPage}
          defaultValue={config.filter_default_limit}
        >
          <option value={5}>5 por página</option>
          <option value={10}>10 por página</option>
          <option value={20}>20 por página</option>
        </select>
    </div>
  );
};
