import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import jsonToFormData from "@ajoelp/json-to-formdata";

import { Back } from "../Back";
import FormInput from "../FormElement/FormInput";
import FormUploadImage from "../FormElement/FormUploadImage";
import FormSelectMultiple from "../FormElement/FormSelectMultiple";
import FormSelect from "../FormElement/FormSelect";
import { AuthContext } from "../../context/AuthContext";
import { StateContext } from "../../context/StateProvider";
import RrppService from "../../services/rrppService";
import eventService from "../../services/eventService";
import ticketService from "../../services/ticketService"
import { IRrppRequest, IRrppResponse } from "../../types/IRrpp";
import { IEventResponse, IEventsResponse } from "../../types/IEvent";
import { ITicketResponse } from "../../types/ITicket";
import { ITeamResponse } from "../../types/ITeam";
import 'react-toastify/dist/ReactToastify.css';
import FormCheck from "../FormElement/FormCheck";

const schema = yup.object().shape({
  first_name: yup.string()
    .required('El nombre es requerido'),
  last_name: yup.string()
    .required('El apellido es requerido'),
  email: yup.string()
    .required('El email es requerido')
    .email('El correo electrónico no es válido'),
  password: yup.string()
    .required('El password es requerido'),
  profile_picture: yup.mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if(value instanceof File){
        return value.size <= 20000000;
      }
      return true;
    })
});

const schemaEdit = yup.object().shape({
  first_name: yup.string()
    .required('El nombre es requerido'),
  last_name: yup.string()
    .required('El apellido es requerido'),
  email: yup.string()
    .required('El email es requerido')
    .email('El correo electrónico no es válido'),
  password: yup.string(),
  profile_picture: yup.mixed()
    .test("size", "Tamaño permitido menor a 20MB", (value) => {
      if(value instanceof File){
        return value.size <= 20000000;
      }
      return true;
    })
});

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  picture: '',
  team_role: 'validator',
  user_role: 'validator',
  event_ids: [],
  tickets_to_validate: [],
  validate_all_tickets: true
};

const statusOptions: Array<object> = [
  { value: '', text: 'Seleccione status...' },
  { value: 'active', text: 'Activo' },
  { value: 'paused', text: 'Pausado' },
];

type FormValidatorProps = {
  handleCreateEditValidator?: () => void;
  dataEvent?: IEventResponse;
  currentValidatorId?: string;
};

export const FormValidator = ({handleCreateEditValidator, dataEvent, currentValidatorId}: FormValidatorProps) => {
  const { user, producer } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id, producer_id } = useParams();
  const { state, dispatch } = useContext(StateContext);
  const [eventOptions, setEventOptions] = useState<IEventsResponse>({rows: [], pages: 0, total: 0});
  const [tickets, setTickets] = useState<ITicketResponse[]>([]);

  const createItems = useMutation(
    (item: FormData) => RrppService.create(item), {
      onError(error: any) { toast.error(error.response.data.error) },
    }
  );
  const updateItems = useMutation(
    (item: FormData) => RrppService.update(validatorID, item), {
      onError(error: any) { toast.error(error.response.data.error) },
    }
  );
  const getUserApi = useMutation(
    (id: string) => RrppService.get(id)
  );
  const getEvents = useMutation(() => eventService.getAll({producer_id: producer_id || producer?.id || producer_id}));
  const getTickets = useMutation(() => ticketService.getAll({ event_id: dataEvent ? dataEvent.id : ''  }));
        
  const validatorID = id || currentValidatorId;

  const getTeamStatusForProducer = (row: IRrppResponse) => {
    if (row.teams && row.teams.length && (producer_id || producer?.id)) {
      const team = row.teams.find((i) => i.producer_producer_id == producer?.id);
      if (team) {
        return team.status;
      }
    }
    return row.status;
  }

  const form = useForm<IRrppRequest>({
    resolver: yupResolver(validatorID ? schemaEdit : schema),
    defaultValues,
  });
  useEffect(() => {
    if (producer?.id || producer_id) {
      getEvents.mutateAsync().then(res => setEventOptions(res));
    }

    if (dataEvent) {
      getTickets.mutateAsync().then(res => setTickets(res.rows));
    }
    
    if (validatorID) {
      getUserApi.mutateAsync(validatorID).then(res => {
        res.status = getTeamStatusForProducer(res);
        res.event_ids = getEventsValidator(res.teams);
        if (dataEvent) {
          getTickets.mutateAsync().then(response => {
            const tickets_to_validate = getTicketsValidator(res.teams, response.rows);
            if (tickets_to_validate && tickets_to_validate.length > 0) {
              res.tickets_to_validate = tickets_to_validate;
              res.validate_all_tickets = false; 
            } 
            else res.validate_all_tickets = true; 
            form.reset(res);
          })
        } else {
          form.reset(res);
        }
      });
    } else {
      const selfData: any = { user_role: 'validator' };
      if (dataEvent) {
        selfData.event_ids = [dataEvent.id];
        selfData.producer_producer_id = dataEvent.producer_id;
      }
      form.reset(selfData);
    }
  }, [validatorID, form.reset]);

  const onSubmit = async (data: IRrppRequest) => {
    if (user.user_role != 'admin')
      data.producer_id = user.id;

    if (producer?.id)
      data.producer_producer_id = producer?.id;
    if (producer_id)
      data.producer_producer_id = producer_id;

    if (data.event_ids && data.event_ids.length) {
      if (!Array.isArray(data.event_ids)) {
        data.event_ids = [data.event_ids];
      } else {
        data.event_ids = data.event_ids.map((e: any) => e['value']? e['value'] : e);
      }
    } else {
      data.event_ids = [''];
    }

    if (dataEvent?.id) {
      if (data.validate_all_tickets) {
        data.tickets_to_validate = [];
      } else if (data.tickets_to_validate && data.tickets_to_validate.length) {
        data.tickets_to_validate = data.tickets_to_validate.map((ticket: any) => ticket['value']);
      } else {
        dispatch({ type: "toastError", payload: "Elegí al menos un ticket para validar" });
        return;
      }
    }

    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true
    });

    dispatch({ type: "showLoaderScreen", payload: true });
    if (validatorID) {
      updateItems.mutateAsync(formData).then(res => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Registro actualizado" });
        handleBack();
      });
    } else {
      createItems.mutateAsync(formData).then(res => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Registro creado correctamente" });
        handleBack();
      });
    }
  };

  const handleBack = () => {
    if (handleCreateEditValidator) {
      handleCreateEditValidator();
      return;
    }
    const prefix = user.user_role != 'admin' ? '/client' : `/admin/producers/${producer_id}`;
    navigate(prefix + '/validators');
  };

  const onError = (error: any) => {
    console.log("error ", error);
  }

  const getEventsValidator = (teams: Array<ITeamResponse>) => {
    const result: any = [];
    for (const t of teams) {
      for (const et of t.event_teams) {
        const data = {value: et.event_id, label: et.event?.name || ''}
        if (!result.find((r: any) => r.value == et.event_id)) result.push(data);
      }
    }
    return result;
  }

  const getTicketsValidator = (teams: Array<ITeamResponse>, tickets: any[]) => {
    const result: any = [];
    for (const t of teams) {
      for (const et of t.event_teams) {
        if (et.tickets_to_validate && et.tickets_to_validate.length > 0) {
          for (const ticket of et.tickets_to_validate) {
            const data = { value: ticket, label: tickets.find(t => t.id === ticket)?.name || '' }
            if (!result.find((r: any) => r.value === ticket)) result.push(data)
          }
        }
      }
    }
    return result;
  }

  const ticketsToValidate = form.watch("tickets_to_validate")
  const validateAll = form.watch("validate_all_tickets")

  useEffect(()=>{
    if(!!validateAll) form.setValue("tickets_to_validate",[]) 
    },[validateAll])

  useEffect(()=>{
    if(ticketsToValidate && ticketsToValidate.length> 0) form.setValue("validate_all_tickets",false)
    },[ticketsToValidate])
  return (
    <div className="">
      <Back onClick={handleBack} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold"> {validatorID ? 'Editar Validador' : 'Crear nuevo Validador'}</h1>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)}>
            <FormInput
              name="first_name"
              type="text"
              label="Nombre"
            />
            <FormInput
              name="last_name"
              type="text"
              label="Apellido"
            />
            <FormInput
              name="email"
              type="text"
              label="Correo"
            />
            <FormInput
              name="password"
              type="password"
              label="Contraseña"
            />
            <FormSelect
              name='status'
              options={statusOptions}
              label="Estado del cliente"
            />
            <div className="flex justify-center gap-10 ">
              <FormUploadImage
                name="profile_picture"
                label="Picture"
              />
            </div>
            <div className="flex justify-center gap-10 mt-6">
              {!dataEvent?.id ? (
                <FormSelectMultiple
                  name="event_ids[]"
                  options={eventOptions.rows.map((l: any) => {
                    return {value: l.id, label: l.name}
                  })}
                  label="Eventos"
                />
              ) : (
                <FormSelectMultiple
                  name="tickets_to_validate"
                  options={tickets.map((ticket: ITicketResponse) => {
                    return {value: ticket.id, label: ticket.name}
                  })}
                  label="Tickets a validar"
                />
              )}
            </div>
            
            {dataEvent?.id && (
              <FormCheck name="validate_all_tickets"
                options={[{ value: true, label: 'Validar todos los tickets' }]} />
            )}
            
            <div className="mb-16 flex justify-center gap-10 pt-10">
              <button
                type="submit"
                className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
              >
                {validatorID ? 'Editar Validador' : 'Crear Validador'}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
