import React, {FC, HTMLAttributes} from 'react';
import {NavLink} from "react-router-dom";

type TableCellLinkProps = {
    href: string,
}

const classCellLink = "inline-grid w-full h-full items-center";

const TableCellLink: FC<TableCellLinkProps & HTMLAttributes<HTMLTableCellElement>> = (props) => {

  return (
      <td className={props.className}>
          <NavLink to={props.href} className={classCellLink}>
              { props.children }
          </NavLink>
      </td>
  );
};

export default TableCellLink;
