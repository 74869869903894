import React, { useState, useRef, FC } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import useComponentVisible from "../../../hooks/use-component-visible.hook";
import { Props } from "@headlessui/react/dist/types";
import Restricted from "../../../context/Restricted";
import ImagenIcono from '../../../assets/iconoEventos.svg';


type DropdownDotsProps = {
    options: Array<any>;
    children?: React.ReactNode;
}

export default function Dropdown({ options = [], children }: DropdownDotsProps) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    return (
        <div className="" ref={ref}>
            <button className="text-gray-100 flex" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                {options.length != 0 ?
                    <img src={ImagenIcono} width="100%" height="100%" className="object-cover" />
                    :
                    <div></div>
                }
            </button>

            {
                options.length != 0 ?
                    <div>
                        {isComponentVisible && (
                            <div className={`absolute  z-10 max-w-56 origin-top-right bg-white border border-gray-50 rounded-md shadow-lg mt-1`}>
                                {options.map((item: any, index: number) => (
                                    <div className="p-2" key={index}>
                                        <Restricted to={item.restricted || 'any'}>
                                            <button
                                                onClick={item.action}
                                                className="block w-full px-4 py-2 text-sm text-black-100 rounded-lg hover:bg-gray-100 hover:bg-opacity-20 hover:text-black-700"
                                            >
                                                <div className="flex items-center" onClick={() => setIsComponentVisible(false)}>
                                                    {item.icon}
                                                    <div>
                                                        <p className="w-max">{item.name && <strong> {item.name}</strong>}</p>
                                                    </div>
                                                </div>
                                            </button>
                                        </Restricted>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    :
                    <div></div>
            }
        </div>
    );
}
