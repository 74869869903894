import { useEffect, useState, useContext, ChangeEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

import { Title } from "../../../events/Title";
import { FilterPage } from "../../../table/FilterPage";
import { FilterSection } from "../../../table/FilterSection";
import { FilterQtyPage } from "../../../table/FilterQtyPage";
import { IFilterRequest } from "../../../../types/IFilter";
import { IOrderTicketResponse, IOrderTicketsResponse } from "../../../../types/IOrderTicket";
import UserService from "../../../../services/userService";
import { formatDateTime } from "../../../../utils/format-date";
import { formatMoney } from "../../../../utils/format-money";
import { getTicketStatusFromValue } from "../../../../utils/status";
import config from "../../../../config/variables";
import { StateContext } from "../../../../context/StateProvider";
import DropdownDots from "../../../FormElement/DropdownDots";
import EventService from "../../../../services/eventService";
import TableCellLink from "../../../FormElement/TableCellLink";

const filterSectionProps = [
  { title: "Todos los tickets", id: "all" },
  { title: "Tickets Free", id: "Free" },
];

type ticketUserProps = {
  userOwner: boolean;
};

export const TicketsUser = ({ userOwner }: ticketUserProps) => {
  const defaultFilters = {
    limit: config.filter_default_limit,
    page: 1,
    status: 'all',
    userOwner
  };

  const { user_id } = useParams();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(StateContext);
  
  const [result, setResult] = useState<IOrderTicketsResponse | null>(null);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState<string>("");
  let paramsFilters: any = { status: 'all', sortBy: 'createdAt' };

  const getItems = useMutation(
    () => UserService.getUserTickets(user_id || '' , filters)
  );

  useEffect(() => {
    getItems.mutateAsync().then(res => setResult(res));
  }, [user_id]);

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({...filters, ...filter});
    await getItems.mutateAsync().then(res => setResult(res));
  };

  const columnData = [
    "Nombre",
    "Fecha y hora",
    "Locación",
    "Tipo de ticket",
    "Precio",
    "Status orden",
  ];

  if (!userOwner) columnData.push("Poseedor Actual");

  columnData.push("");

  const searcher = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    paramsFilters = { ...filters, query: e.target.value };
    await setFilters({ ...filters, page: 1, query: e.target.value });
    await getItems.mutateAsync().then(res => setResult(res));
  };

  const handleFilterSection = async (filter: string) => {
    await setFilters({ ...filters, page: 1, status: filter });
    getItems.mutateAsync().then(res => setResult(res));
  };

  const ticketIsSendable = (orderticket: IOrderTicketResponse) => {
    if (
      !['pending','reject','rejected','cancelled','in_process'].includes(orderticket.order?.status) &&
      new Date(orderticket.ticket[0].event.date_end) > new Date() &&
      orderticket.status !== 'used'
    ) return true;
    return false;
  }

  const getDropdownOptions = (orderticket: IOrderTicketResponse) => {
    let options = [
      {title: 'Ver trazabilidad del ticket', action: () => navigate(`/admin/orderTicket/${orderticket.id}`)},
    ];
    
    if (userOwner && ticketIsSendable(orderticket))
      options.push({
        title: 'Reasignar ticket',
        action: () => {
          dispatch({ type: "setOrderTicket", payload: orderticket });
          dispatch({ type: "setOrdersTickets", payload: null });
          dispatch({ type: "addUsersDetailTicket", payload: null });
        }
      });

    if (orderticket.sent_status === 'pending' && orderticket.user_id_send === user_id)
      options.push({
        title: 'Cancelar envío',
        action: () => {
          dispatch({ type: "showLoaderScreen", payload: true });
          EventService.cancelSend({
            "order_ticket_id": orderticket.id,
          }).then(() => {
            getItems.mutateAsync().then(res => setResult(res));
            dispatch({ type: "showLoaderScreen", payload: false });
            dispatch({ type: "toastSuccess", payload: "Ticket cancelado" });
            dispatch({ type: "reloadOrderTicket", payload: true });
          }).catch((e) => {
            dispatch({ type: "showLoaderScreen", payload: false });
            toast.error("error al cancelar ticket");
          });
        }
      });

    return options;
  }

  return (
    <div>
      <Title>Tickets {userOwner ? 'disponibles' : 'enviados'}</Title>
      <div className="mb-7 flex justify-between border-b-[1px] border-colorBorder pr-5 pt-10  ">
        <FilterSection filterSectionProps={filterSectionProps} handleFilter={handleFilterSection} />
        <div className="flex items-center gap-5 ">
          <input type="text" placeholder="Search..." className="rounded-full border-[2px] border-black-100 px-4 py-1 font-bold text-black-100  hover:text-black" value={search} onChange={searcher} />
        </div>
      </div>

      <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {result?.rows.map((row: IOrderTicketResponse, i: number) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{row.ticket[0].event?.name}</td>
              <td className="">{formatDateTime(row.ticket[0].event?.date_start)}</td>
              <td className="flex h-full items-center gap-2 ">{row.ticket[0].event?.place?.name}</td>
              <td>{row.ticket[0].name}</td>
              <td>{formatMoney(row.ticket[0].price)}</td>
              <td>
                <div className="flex w-34 items-center justify-center gap-3 rounded-full border-[1px] border-colorBorder  py-2 text-center ">
                  <div className={`rounded-full  ${['approved','success', undefined].includes(row.order_status) ? 'bg-green' : 'bg-red'} p-[.35rem]`} />
                  <p className="capitalize">{row.order_status ? getTicketStatusFromValue(row.order_status) : 'Sin orden'}</p>
                </div>
              </td>
              {!userOwner && row.user_id && (
                <TableCellLink href={`/admin/users/${row.user_id}`} className="pl-4">
                  {row.end_user_fullname || row.email}
                </TableCellLink>
              )}
              {!userOwner && !row.user_id && (
                <td className="pl-4">
                  {row.end_user_fullname || row.email}
                </td>
              )}
              <td>
                <DropdownDots options={getDropdownOptions(row)} />
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {result?.rows.length == 0 && <p className="text-center">No existen tickets disponibles</p>}
      <div className="flex justify-between pt-10">
        <FilterPage  handleFilter={handleFilter} total={result?.total} limit={filters.limit}  currentPage={filters.page}/>
        <FilterQtyPage handleFilter={handleFilter} />
      </div>
    </div>
  );
};