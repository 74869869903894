import React, { Fragment, useContext, Dispatch, SetStateAction } from "react";
import { Status } from "../Status";
import bigjamPhoto from "../../assets/bigjam.png";
import { IEventResponse } from "../../types/IEvent";
import Restricted from "../../context/Restricted";
import { AuthContext } from "../../context/AuthContext";
import { formatMoney } from "../../utils/format-money";
import { getEventStatus } from "../../utils/status";
import { formatHumanDate } from "../../utils/format-date";
import { Link } from "react-router-dom";

type selectedProps = {
  data: IEventResponse;
  edit?: boolean;
  handleEdit?: (newData?: any | undefined) => void;
  handleDelete?: (id: string) => void;
  setEditEvent?: Dispatch<SetStateAction<boolean>>;
};

const styles = {
  titleLi: "font-bold pb-1 text-lg",
  pLi: "text-gray-100 pr-6",
  wrapperLi: "flex justify-between",
};

export const BannerEvent = ({
  data,
  edit,
  handleEdit,
  handleDelete,
  setEditEvent = () => {},
}: selectedProps) => {
  const { role, permissions } = useContext(AuthContext);
  const getProducerName = (item: any) => {
    return item && item.producer && item.producer["name"]
      ? item.producer["name"]
      : "Productora";
  };

  const canEdit = () => {
    if (["producer", "coproducer"].includes(role)) {
      if (data.tickets && data.tickets.length) return false;
    }
    return true;
  };

  let nonFreeTickets = data?.tickets?.filter(
    (ticket) => ticket?.type !== "Free"
  );
  const ticketSales = nonFreeTickets?.map(
    (ticket) => ticket?.tickets_sold * ticket?.price
  );
  let totalSales = 0;
  if (ticketSales && ticketSales.length > 0) {
    totalSales = ticketSales.reduce((total, sales) => total + sales);
  }

  const getPercentage = (partial: number, total: number) => {
    if (total == 0) return 0;
    return Math.round(((100 * partial) / total) * 100) / 100;
  };

  return (
    <div className="flex flex-row rounded-xl px-5 py-5 outline outline-2">
      <div className="flex w-[40%]">
        {/* <div className="h-full w-24 rounded-lg bg-[#D6D6D6] mr-4 overflow-hidden" style={{ backgroundImage: `url(${data.promo_image})`, backgroundRepeat: "no-repeat", backgroundSize: "auto 100%"}}>*/}
        <img
          src={data.promo_image || bigjamPhoto}
          className="h-full w-[100px] rounded-lg object-cover"
        />

        <div className="grid grid-cols-1 pl-8 2xl:pl-12">
          <h1 className="text-2xl font-bold">
            {getProducerName(data)} presenta <br />
            <span className="text-black-300">
              {data && data.name ? data.name.toUpperCase() : ""}
            </span>
          </h1>
          <p className="font-sans text-[14px] leading-[21px]">
            {formatHumanDate(data.date_start)}
          </p>
          <div className="mt-5 flex flex-nowrap gap-2 py-2 xl:flex-wrap">
            <Restricted to="events">
              <Link
                to="edit"
                onClick={() => setEditEvent(true)}
                className="w-32 rounded-full border-[1px] border-black-100  py-1 text-center text-sm font-bold text-black-100 hover:bg-black-100 hover:text-white"
              >
                {data.temp && role == "admin" ? "Revisar" : "Editar"} evento
              </Link>
            </Restricted>

            {handleDelete && (
              <Restricted to={"admin"}>
                <button
                  onClick={() => handleDelete(data.id)}
                  className="w-32 rounded-full border-[1px] border-black-100  py-1 text-sm font-bold text-black-100 hover:bg-black-100 hover:text-white"
                >
                  Eliminar evento
                </button>
              </Restricted>
            )}
          </div>
        </div>
      </div>

      <div className="w-[60%] flex-1 pl-7">
        <ul className="flex flex-col pr-0 xl:pr-4 2xl:pr-36">
          <div className={`${styles.wrapperLi} xl:pb-3`}>
            {!(permissions.can_see_balance === false) && (
              <li>
                <h3 className={`${styles.titleLi}`}>
                  {formatMoney(data?.total_sales)}
                </h3>
                <p className={`${styles.pLi}`}>Balance</p>
              </li>
            )}
            <li>
              <h3 className={`${styles.titleLi}`}>{data?.sellers}</h3>
              <p className={`${styles.pLi}`}>Vendedores</p>
            </li>
            <li>
              <h3 className={`${styles.titleLi}`}>{data?.leaders}</h3>
              <p className={`${styles.pLi}`}>Líderes de grupo</p>
            </li>
            {!(permissions.can_see_balance === false) && (
              <li>
                <h3 className={`${styles.titleLi}`}>
                  {data.tickets_sold}
                  <span className="font-medium">/{data.tickets_qty}</span>{" "}
                  <span className="text-sm ">
                    {getPercentage(data.tickets_sold, data.tickets_qty)}%
                  </span>
                </h3>
                <p className={`${styles.pLi}`}>Entradas vendidas</p>
              </li>
            )}
          </div>
          <div className={`${styles.wrapperLi} items-center`}>
            <li className="w-[73%] pt-5">
              <h3 className={`${styles.titleLi}`}>Link público del evento</h3>
              {data.status != "paused" ? (
                <a
                  href={data?.link}
                  target="_blank"
                  className={`${styles.pLi} text-[#3b82f6]`}
                >
                  {data?.link}
                </a>
              ) : (
                <p className={`${styles.pLi}`}>{data?.link}</p>
              )}
            </li>
            <div className="mr-2 pt-2">
              <Status status={getEventStatus(data)} />
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};
