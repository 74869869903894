import api from "./api";
import authHeader from "./authHeader";
import { IConfigResponse } from "../types/IConfig";
import { IPaginationResponse } from "../types/IPagination";

const get = async (id: string) => {
  const response = await api().get<IConfigResponse>(
    `configs/app-config/${id}`,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const update = async (id: string | undefined, item: FormData) => {
  const response = await api().put<IConfigResponse>(
    `configs/app-config/${id}`,
    item,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader(),
      },
    }
  );
  return response.data;
};

const ConfigService = {
  get,
  update,
};

export default ConfigService;
