import { useState } from "react";

import { ISectionResponse } from "../../types/ISection";
import { TableSchedule } from "./TableSchedule";
import { FormSchedule } from "./Form";

export const ScheduleView = () => {
  const [createItem, setCreateItem] = useState(false);
  const [id, setId] = useState<string>("");
  const [showSelected, setShowSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();

  const handleCreateorEdit = async(id:string) => {
    await setId(id);
    await setCreateItem(!createItem);
  };

  const handleSelectItem = (data: ISectionResponse) => {
     setShowSelected(!showSelected);
     if (data) {
      setSelectedItem(data);
     }
  };;

  const itemsTableProps = {
    handleCreateorEdit,
    handleSelectItem,
  };

  const formSectionProps = {
    handleCreateorEdit,
  };

  return (
    <div>
      {createItem
        ? <FormSchedule {...formSectionProps} id={id}/>
        : <TableSchedule {...itemsTableProps} />
      }
    </div>
  );
};
