import React, { useState, useContext } from "react";
import logo from "../../assets/logo.svg";
import account from "../../assets/account.png";
import { IoIosNotifications } from "react-icons/io";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";
import DropdownDots from "../FormElement/DropdownDots";

export const Navbar = () => {
  const [notification, setNotification] = useState(true);
  const { role, changeClient, logout, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const handleNotification = () => {
    setNotification(!notification);
  };

  const handleChangeMenu = () => {
    let path = role == "admin" ? "/admin/events" : "/client/producers";
    if (role !== "admin") {
      auth.changeClient();
    }
    navigate(path);
  };

  return (
    <nav className="z-[100] flex h-16 items-center justify-between border-b-[1px] border-colorBorder  px-10">
      <picture>
        <div onClick={handleChangeMenu} className="cursor-pointer">
          <img src={logo} />
        </div>
      </picture>
      <div className="flex select-none items-center gap-4 text-2xl">
        <div className="relative">
          <div
            className={`cursor-pointer text-gray-100 after:absolute after:-right-2 after:-top-1 after:h-2 after:w-2  after:rounded-full after:bg-red/60 ${
              notification ? "after:block" : "after:hidden"
            }`}
          >
            <IoIosNotifications onClick={handleNotification} />
          </div>
        </div>
        <p className="text-black text-xs">{user.email}</p>
        <DropdownDots
          options={[
            {
              title: "Perfil",
              action: () => navigate("/client/profile"),
              restricted: "only_producer",
            },
            {
              title: "Cambiar Contraseña",
              action: () => navigate("/client/change_password"),
              restricted: "only_producer",
            },
            { title: "Logout", action: logout },
          ]}
        >
          <img
            src={user.profile_picture ? user.profile_picture : account}
            alt="profile-picture"
            className="rounded-full object-cover"
            width="36px"
            height="36px"
          />
        </DropdownDots>
      </div>
    </nav>
  );
};
