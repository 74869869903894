import React, {Fragment, useContext, useEffect, useState} from "react";
import { Back } from "../../Back";
import { useNavigate } from "react-router";
import { Status } from "../../Status";
import { BannerSeller } from "./components/BannerSeller/BannerSeller";
import {IRrppResponse} from "../../../types/IRrpp";
import {useMutation} from "@tanstack/react-query";
import TeamService from "../../../services/teamService";
import jsonToFormData from "@ajoelp/json-to-formdata";
import {setFormErrorsFromServer} from "../../../utils/form";
import {ITeamRequest} from "../../../types/ITeam";
import {AuthContext} from "../../../context/AuthContext";
import {toast, ToastContainer} from "react-toastify";
import { PageContainer } from "../../PageContainer";
import { CardAlert } from "../../AlertToastify/CardAlert";
import {useParams} from "react-router-dom";

const styles = {
    titleLi: "font-bold pb-1 text-xs leading-[21px] ",
    pLi: "font-medium text-xs leading-[18px] ",
    wrapperLi: "flex justify-between gap-28 ",
  };

type addSellerViewProps = {
    selected: IRrppResponse;
    handleSetSection: (section: string) => void;
    handleSetRole: (role: string) => void;
}

export const AddSellerView = ({selected, handleSetSection, handleSetRole}: addSellerViewProps) => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { seller_id, producer_id, producer_producer_id } = useParams();
    const queryParams = new URLSearchParams(window.location.search)
    const event_id = queryParams.get("event_id");
    const updateItems = useMutation(
        (item: FormData) => TeamService.update('cms', item),
    );

    const handleSubmit = () => {
        if (selected.user_role != 'leader' && (!selected.team_leader || (Array.isArray(selected.team_leader) && selected.team_leader.length == 0))) {
            if (seller_id) { // edit seller solo permite editar rol, y no así a qué lider pertenece
                handleBack('search');
                return;
            }
            toast.error('Debe seleccionar un team leader');
            return;
        }
        const leader_id = Array.isArray(selected.team_leader) ?
            selected.team_leader.map((l) => l.id) :
            selected.team_leader?.id;
        const data: any = {
            user_id: selected.id,
            leader_id: leader_id,
            team_role: selected.user_role != 'leader' ? 'seller' : selected.user_role,
            producer_producer_id: producer_producer_id || auth.producer?.id,
        };
        if (event_id) data.event_id = event_id;

        let formData = jsonToFormData(data, {
            arrayIndexes: true,
            excludeNull: true
        });
        const redirectSuffix = selected.user_role == 'leader' ? '?tab=sellers':''
        updateItems.mutateAsync(formData).then(res => {
            if (auth.user.user_role == 'admin') {
                navigate(`/admin/producers/${producer_id}/producer_producer/${producer_producer_id}/rrpp/` + res.user_id + redirectSuffix)
            } else {
                navigate('/client/rrpp/' + res.user_id + redirectSuffix)
            }
        }).catch((reason)=>{});
    }

    const handleBack = (section: string) => {
        if (seller_id) {
            let prefix = '';
            if (auth.user.user_role != 'admin') {
                prefix += '/client';
            } else {
                prefix += `/admin/producers/${producer_id}/producer_producer/${producer_producer_id}`;
            }
            navigate(prefix + '/rrpp/');
        } else {
            handleSetSection(section)
        }
    }

    return (
        <Fragment>
            <PageContainer>
                <Back onClick={() => handleBack('search')} />
                <CardAlert/>
                <div className="pt-6">
                    <BannerSeller selected={selected} handleSetSection={handleSetSection} handleSetRole={handleSetRole}/>
                </div>

                <div className="mb-16 flex justify-center gap-10 mt-80">
                    <button
                        onClick={() => handleBack('search')}
                        type="button"
                        className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
                    >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                    >
                        Guardar
                    </button>
                </div>
            </PageContainer>
        </Fragment>
    );
}
