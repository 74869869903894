import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useContext } from "react";
import { StateContext } from "../../../../context/StateProvider";
import { FormProvider, useForm } from "react-hook-form";
import { GrClose } from "react-icons/gr";
import { motion } from "framer-motion";
import FormSelect from "../../../FormElement/FormSelect";
import {
  IOrderTicketResponse,
  IOrderTicketRequest,
} from "../../../../types/IOrderTicket";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../../../utils/format-money";
import { IOrderResponse } from "../../../../types/IOrder";
import OrderService from "../../../../services/orderService";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthContext";
import LoaderCircle from "../../../FormElement/LoaderCircle";

const initialRefund = {
  status: "",
};

type RefundOption = {
  id: any,
  option: any
}

export const PopUpRefund = ({ }) => {
  const { state, dispatch } = useContext(StateContext);
  const { role } = useContext(AuthContext);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [orders, setOrders] = useState<IOrderTicketResponse[]>([]);
  const [data, setData] = useState<IOrderResponse>();
  const [serviceByTicket, setServiceByTicket] = useState<IOrderResponse>();
  const [refund, setRefund] = useState<string[]>([]);
  const [serviceTicketId, setServiceTicketId] = useState<string[]>([]);
  const [countService, setCountService] = useState<any>({});
  const [countTicket, setCountTicket] = useState<any>({});
  const [countTotal, setCountTotal] = useState<any>({});
  const [countFull, setCountFull] = useState<any>({});
  const [idTicket, setIdTicket] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<RefundOption[]>([]);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
  const [loadRefund, setLoadRefund] = useState<boolean>(false);
  useEffect(() => {
    loadOrders();
  }, []);

  const loadOrders = () => {
    if (state.order) {
      setData(state.order);
    }
    if (state.orders_ticket) {
      setOrders(state.orders_ticket);
    }
  };

  const form = useForm<IOrderTicketRequest>({
    defaultValues: { ...initialRefund },
  });

  const handleBack = () => {
    dispatch({ type: "showRequestRefund", payload: false });
  };
  const statusOptions: Array<object> = [
    { value: "", text: "Seleccione una opcion" },
    { value: "full", text: "Reembolso completo" },
    { value: "service", text: "Solo service" },
    { value: "ticket", text: "Solo ticket" },
  ];

  const changeRefund = (e: any, index: number) => {
    const ticketIds = idTicket;
    const newSelectedOptions = [...selectedOptions];

    const searchIdx = newSelectedOptions.findIndex((element: any) => {
      return element.id.id === ticketIds[index].id
    })

    const option = {
      id: ticketIds[index],
      option: e.target.value,
    }

    if(searchIdx === -1) {
      newSelectedOptions.push(option)
    } else {
      newSelectedOptions[searchIdx] = option;
    }

    const validOptionsLength = newSelectedOptions.filter((o) => o.option?.length).length;
    validOptionsLength ? setShowSubmitButton(true) : setShowSubmitButton(false) ;

    setSelectedOptions(newSelectedOptions);
  };

  const handleRequestRefund = () => {
    if (!data?.external_reference) {
      console.error("Referencia externa no disponible para el reembolso");
      return;
    }

    setLoadRefund(true);
    const amount = countTotal.toFixed(2);

    const detail = [];
    for (const opt of selectedOptions) {
      if (opt?.option) {
        console.info("opt", opt);
        const newObject = {
          order_ticket_id: opt.id.id,
          option: opt.option,
          price: 0,
          service: 0,
          total: 0,
        };
        if (opt.option == "full") {
          newObject.service = service * (opt.id.price / total_amount);
          newObject.price = opt.id.price;
          newObject.total = opt.id.price + newObject.service;
        }
        if (opt.option == "service") {
          newObject.service = service * (opt.id.price / total_amount);
          newObject.total = newObject.service;
        }
        if (opt.option == "ticket") {
          newObject.price = opt.id.price;
          newObject.total = opt.id.price;
        }
        detail.push(newObject);  
      }
    }
    console.info("detail", detail);

    const rolePrefix = role == "admin" ? "admin" : "client";
    OrderService.refundOrder(data.external_reference, amount, detail).then(
      (res: any) => {
        if (res) {
          navigate(`/${rolePrefix}/order/${state?.order?.id}`);
          toast.success("El reembolso se ha realizado correctamente");
        } else {
          navigate(`/${rolePrefix}/order/${state?.order?.id}`);
          toast.error("El reembolso no está disponible");
        }

        setLoadRefund(false);
        dispatch({ type: "showRequestRefund", payload: false });
      }
    ).catch((error) => {
      if (error.response?.data?.message) {
        navigate(`/${rolePrefix}/order/${state?.order?.id}`);
        toast.error(error.response.data.message);
      }
      setLoadRefund(false);
      dispatch({ type: "showRequestRefund", payload: false });
    });
  };

  useEffect(() => {
    const ordersWithTickets = orders.filter(
      (order) => order.ticket && order.ticket.length > 0
    );
    const tempIds = ordersWithTickets.map((order) => ({ id: order.id }));
    const ticketPrices = ordersWithTickets.map(
      (order) => order.ticket[0].price
    );
    setIdTicket(
      tempIds.map((tempId, i) => ({ ...tempId, price: ticketPrices[i] }))
    );
  }, [orders]);

  let eventTitle = "";
  const ticketQuantities: any = {};
  data?.order_tickets.forEach(
    (
      orderTicket: { ticket: any[]; qty: number; price: number },
      index: number
    ) => {
      if (!eventTitle) {
        eventTitle = orderTicket.ticket[0].name_event;
      }
      if (eventTitle === orderTicket.ticket[0].name_event) {
        orderTicket.ticket.forEach((ticket) => {
          const total = orderTicket.qty * ticket.price;
          if (ticketQuantities[ticket.name]) {
            ticketQuantities[ticket.name].qty += orderTicket.qty;
            ticketQuantities[ticket.name].total += total;
          } else {
            ticketQuantities[ticket.name] = {
              qty: orderTicket.qty,
              price: ticket.price,
              total: total,
            };
          }
        });
      }
    }
  );
  let service = data?.service;
  let total_amount = data?.subtotal;
  let total = 0;
  let totalServicePrice = 0;
  let quantityServicePrice = 0;
  let quantitydifferentServicePrice = 0;
  let tickets = {};

  useEffect(() => {
    const countSelectedOptions = () => {
      let serviceCount = 0;
      let fullCount = 0;
      let ticketCount = 0;
      let servicePrice = {};
      let fullPrices = {};
      let ticketPrices = {};
      let full = {};
      let totalFullPrice = 0;

      selectedOptions.forEach((option) => {
        if (option?.option === "service") {
          serviceCount++;
          if (option.id && option.id.price in servicePrice) {
            servicePrice[option.id.price]++;
          } else if (option.id) {
            servicePrice[option.id.price] = 1;
          }
        } else if (option?.option === "full") {
          fullCount++;
          if (option.id && option.id.price in fullPrices) {
            fullPrices[option.id.price.toString()]++;
          } else if (option.id) {
            fullPrices[option.id.price.toString()] = 1;
          }
        } else if (option?.option === "ticket") {
          ticketCount++;
          if (option.id.price in ticketPrices) {
            ticketPrices[option.id.price]++;
          } else {
            ticketPrices[option.id.price] = 1;
          }
        }
      });

      for (let priceStr in fullPrices) {
        let price = parseFloat(priceStr);
        let count = fullPrices[priceStr];
        if (!isNaN(count)) {
          totalFullPrice += count * price;
          full[price] = {
            count: count,
            price: price,
          };
        }
      }
      setCountFull(totalFullPrice);
      let quantityTicketPrice = 0;
      let quantitydifferentTicketPrice = 0;
      let service = {};

      let totalTicketPrice = 0;
      for (let price in ticketPrices) {
        let count = ticketPrices[price];
        if (!isNaN(count)) {
          quantityTicketPrice += count;
          if (count > 0) {
            quantitydifferentTicketPrice++;
            tickets[price] = {
              count: count,
              price: price,
            };
          }
          totalTicketPrice += count * price;
        }
      }
      setCountTicket(tickets);

      for (let price in servicePrice) {
        let count = servicePrice[price];
        if (!isNaN(count)) {
          quantityServicePrice += count;
          if (count > 0) {
            quantitydifferentServicePrice++;
            service[price] = {
              count: count,
              price: price,
            };
          }
          totalServicePrice += count * price;
        }
      }
      setCountService(service);
    };
    countSelectedOptions();
  }, [selectedOptions]);

  function calcularTotal(
    countTicket,
    countService,
    total_amount,
    service,
    countFull
  ) {
    const valueTickets = Object.values(countTicket);
    const services = Object.values(countService);

    let totalTickets = 0;
    let totalServices = 0;

    valueTickets.forEach((ticket) => {
      totalTickets += ticket.count * ticket.price;
    });

    services.forEach((serviceTicket) => {
      totalServices +=
        serviceTicket.count * service * (serviceTicket.price / total_amount);
    });

    const totalFull = countFull + service * (countFull / total_amount);
    const totalTodo = totalTickets + totalServices + totalFull;

    return totalTodo;
  }

  useEffect(() => {
    const total = calcularTotal(
      countTicket,
      countService,
      total_amount,
      service,
      countFull
    );
    setCountTotal(total);
  }, [countTicket, countService, total_amount, service, countFull]);

  const roundTwoDecimals = (value: number) => {
    return Math.round(value * 100) / 100;
  };

  const columnData = [
    "Nombre del evento",
    "Tipo de ticket",
    "Costo",
    "Service",
    "Total",
    "Item/s a reembolsar",
  ];
  return (
    <div
      className="fixed  z-50 flex h-screen w-screen items-center justify-center bg-black-100/30 backdrop-blur-md px-[10%] py-[6%]"
    >
      <FormProvider {...form}>
        <div className=" relative w-full h-full rounded-2xl bg-white">
          <div className="h-[72px] flex w-full items-center justify-center border-b border-colorBorder  text-center">
            <div>
              <p className="text-lg font-bold">Solicitar reembolso</p>
              <p className="text-sm">
                Seleccione el item correspondiente de cada ticket a reembolsar.
              </p>
            </div>

            <button className="absolute right-12 pt-4">
              <GrClose onClick={() => handleBack()} />
            </button>
          </div>
          <div className="mx-[10%] overflow-auto " style={{ height: "calc(70% - 72px)" }}>
            <table className="my-table-spacing w-full border-separate  border-spacing-y-2   ">
              <thead className="divide-y-2 ">
                <tr className="pb-4 text-sm font-bold text-gray-100">
                  {columnData.map((column, index) => (
                    <th className="" key={column + index}>
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {orders &&
                  orders.map((item, i) => (
                    <motion.tr
                      key={i}
                      layout
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0.2 }}
                      transition={{
                        type: "spring",
                        stiffness: 100,
                        bounce: 0,
                      }}
                    >
                      <td className="pl-4">{item.event.name}</td>
                      <td className="pl-4">{item.ticket[0].type}</td>
                      {Object.keys(ticketQuantities).map((name) => {
                        if (name == item?.ticket[0]?.name) {
                          return (
                            <td className="pl-4">
                              {formatMoney(ticketQuantities[name].price)}
                            </td>
                          );
                        }
                      })}
                      {Object.keys(ticketQuantities).map((name) => {
                        if (name == item?.ticket[0]?.name) {
                          return (
                            <td className="pl-4">
                              {formatMoney(
                                service *
                                (ticketQuantities[name].price /
                                  total_amount)
                              )}
                            </td>
                          );
                        }
                      })}
                      {Object.keys(ticketQuantities).map((name) => {
                        if (name == item?.ticket[0]?.name) {
                          return (
                            <td className="pl-4">
                              {formatMoney(
                                ticketQuantities[name].price +
                                service *
                                (ticketQuantities[name].price /
                                  total_amount)
                              )}
                            </td>
                          );
                        }
                      })}

                      <td className="pl-4">
                        <div>
                          <select
                            id={`filterPage-${i}`}
                            className="h-full w-full outline-none"
                            onChange={(e) => changeRefund(e, i)}
                          >
                            {statusOptions.map((option, i) => (
                              <option key={i} value={option.value}>
                                {option.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </motion.tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="h-[20%] overflow-auto">
            <div className=" divide-solid border"></div>
            <tbody className="flex w-full text-center">
              <div className="my-8 w-1/2 text-sm font-bold">
                <h1 className="text-lg font-bold">Detalle del Reembolso</h1>
              </div>
              <div className="my-8 pr-8">
                <table>
                  <tbody>
                    {countService && Object.keys(countService).length > 0 ? (
                      <>
                        {Object.entries(countService).map(
                          ([key, value], index) => {
                            total += value.price;
                            return (
                              <Fragment key={key}>
                                {index !== 0}
                                <tr>
                                  <td
                                    className="px-6 pr-16"
                                    style={{ textAlign: "left" }}
                                  >
                                    {value.count} Service x{" "}
                                    {formatMoney(
                                      service * (value.price / total_amount)
                                    )}
                                  </td>
                                  <td
                                    className="align-right"
                                    style={{ textAlign: "right" }}
                                  >
                                    {formatMoney(
                                      value.count *
                                      (service * (value.price / total_amount))
                                    )}
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          }
                        )}
                      </>
                    ) : null}
                    {countTicket && Object.keys(countTicket).length > 0 ? (
                      <>
                        {Object.entries(countTicket).map(
                          ([key, value], index) => {
                            total += value.count * value.price; // Sumar al total
                            return (
                              <Fragment key={key}>
                                {index !== 0} {/* Separador entre tickets */}
                                <tr>
                                  <td
                                    className="px-6 pr-16"
                                    style={{ textAlign: "left" }}
                                  >
                                    {value.count} Tickets x{" "}
                                    {formatMoney(value.price)}
                                  </td>
                                  <td
                                    className="align-right"
                                    style={{ textAlign: "right" }}
                                  >
                                    {formatMoney(value.count * value.price)}
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          }
                        )}
                      </>
                    ) : null}
                    {countFull && countFull > 0 ? (
                      <>
                        {/* Separador entre "full" y tickets */}
                        {countTicket && Object.keys(countTicket).length > 0 ? (
                          <tr>
                            <td colSpan="2" className="py-2"></td>
                          </tr>
                        ) : null}
                        <tr>
                          <td
                            className="px-6 pr-16"
                            style={{ textAlign: "left" }}
                          >
                            Reembolso completo{" "}
                          </td>
                          <td
                            className="align-right"
                            style={{ textAlign: "right" }}
                          >
                            {formatMoney(
                              countFull + service * (countFull / total_amount)
                            )}
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                  <tfoot className="text-center">
                    <tr>
                      <td className="px-6 pr-16" style={{ textAlign: "left" }}>
                        Total
                      </td>
                      <td
                        className="align-right"
                        style={{ textAlign: "right" }}
                      >
                        {formatMoney(countTotal)}
                      </td>{" "}
                      {/* Usar la variable total */}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </tbody>
          </div>
          <div className=" divide-solid border"></div>
          <div className="flex h-[10%] items-center justify-center">
            <div className="flex gap-10">
              <button
                onClick={handleBack}
                type="button"
                className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
              >
                Cancelar
              </button>
              {showSubmitButton && !loadRefund &&
                  <button
                      onClick={handleRequestRefund}
                      type="submit"
                      className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
                  >
                    Reembolsar
                  </button>
              }
              {loadRefund &&
                  <button
                      className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium text-white"
                  >
                    <LoaderCircle width={16} height={16}/>
                  </button>
              }
            </div>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};
