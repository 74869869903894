import api from "./api";
import authHeader from "./authHeader";
import { ILabelResponse } from "../types/ILabel";
import { IPaginationResponse } from "../types/IPagination";

const getAll = async (params: object = {}) => {
  const response = await api().get<IPaginationResponse>(`countries/list`, {
    headers: authHeader(),
    params: params,
  });
  return response.data;
};

const CountryService = {
  getAll,
};

export default CountryService;
