import { useContext } from 'react';

import PermissionContext from "../context/PermissionContext";

const useRoutePermission = (permission: string) => {
  const { canAccessRoute } = useContext(PermissionContext);
  return canAccessRoute(permission);
};

export default useRoutePermission;
