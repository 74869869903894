import React, {useEffect, useState} from "react";
import { motion } from "framer-motion";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { IoMailOpen } from "react-icons/io5";
import { BsCalendar4Range } from "react-icons/bs";
import { Title } from "../../../events/Title";
import { GoSearch } from "react-icons/go";
import {IClientsResponse} from "../../../../types/IClient";
import {useMutation} from "@tanstack/react-query";
import ClientService from "../../../../services/clientService";
import {formatDate} from "../../../../utils/format-date";

type ClientProducerProps = {
  producerId: string;
}

export const ClientsProducer = ({producerId}: ClientProducerProps) => {
  const [ result, setResult ] = useState<IClientsResponse>({rows: [], total: 0, pages: 0});
  const [ idClient, setIdClient ] = useState<string>("");

  const getItems = useMutation(
      () => ClientService.getAll({producer_id: producerId, user_role: 'client'})
  );
  useEffect(()=>{
    loadItems();
  },[])

  const loadItems = () => {
    getItems.mutateAsync().then(res=>setResult(res));
  }

  const getTicketsQty = (order_tickets: Array<any>) => {
    let result = 0;
    for (const ot of order_tickets) {
      result += ot.qty;
    }
    return result;
  }

  const columnData = [
    "ID",
    "Nombre",
    "Email",
    "Tickets",
    "Cliente desde",
    "Última conexión",
  ];

  return (
    <div>
      <Title>Clientes</Title>
      <table className="my-table-spacing h-full w-full border-separate gap-5 overflow-x-auto pt-5 text-left border-spacing-y-2">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th className="border-b-[1px] border-colorBorder pb-4" key={column + index}>{column}</th>
            ))}
            <th className="border-b-[1px] border-colorBorder pb-4">
              <GoSearch className="cursor-pointer text-gray-100 ml-auto" size={16} />
            </th>
          </tr>
        </thead>

        <tbody>
          {result.rows.map((_, i) => (
            <motion.tr
                key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{_.id}</td>
              <td className="">{_.first_name+' '+_.last_name}</td>
              <td className="flex h-full items-center gap-2">
                {_.email}
              </td>
              <td className="">{getTicketsQty(_.order_tickets)}</td>
              <td>{formatDate(_.order_tickets[0].createdAt) || '-'}</td>
              <td>{formatDate(_.updatedAt) || '-'}</td>
              <td className="pl-4">
                <button className="text-gray-100">
                  <BiDotsHorizontalRounded size={23} />
                </button>
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      {!result.rows.length && <p className="text-center">No existen clientes disponibles</p>}
      {/* <div className="flex justify-between pt-10">
        <FilterPage />
        <FilterQtyPage />
      </div> */}
    </div>
  );
};
