import axios from "axios";
import config from "../config/variables";

export const staticApiFront = axios.create({
  baseURL: config.api_url,
  headers: {
    "Content-type": "application/json",
  },
});

const apiFront = () =>
  axios.create({
    baseURL: config.getValues().api_url,
    headers: {
      "Content-type": "application/json",
    },
  });

export default apiFront;
