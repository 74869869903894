import React, {FC} from "react";
import {getTeamRoleFromValue} from "../../../../utils/roles";

type sellsGroupProps = {
    data: Array<any>
}

export const SellsGroup: FC<sellsGroupProps> = ({data = []}) => {
  const team = [
    { name: "Juan Matas", user_role: "Team Leader", tickets: 324 },
    { name: "Lucas Puello", user_role: "Team Leader", tickets: 234 },
    { name: "Esteban Piel", user_role: "Team Leader", tickets: 566 },
    { name: "Marcos Mancha", user_role: "Team Leader", tickets: 333 },
    { name: "Diego Lesi", user_role: "Team Leader", tickets: 256 },
    { name: "Pachi Turco", user_role: "Team Leader", tickets: 175 },
  ];

  return (
    <div className="h-full rounded-xl outline outline-2 outline-colorBorder">
      <div className="flex justify-between border-b border-colorBorder px-5 py-4">
        <h3 className="text-xl font-bold">Grupo de ventas</h3>
      </div>
      <div className="flex h-full flex-col justify-center gap-7 px-7  pb-12">
        {data.map((member, index) => (
          <Person {...member} key={index}/>
        ))}
          {data.length <= 0 && <p className="text-center">No existen ventas disponibles</p>}
      </div>
    </div>
  );
};

const Person = ({ name = "Nombre", user_role = "Team Leader", tickets = 0, amount = 0.00, status = '' }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-start gap-5">
        <div className="rounded-full bg-gray-200 p-5"></div>
        <div>
          <h3 className="text-lg font-bold">
              {name}
              { status == 'deleted' && <small className="font-light"> (eliminado)</small>}
          </h3>
          <p>{getTeamRoleFromValue(user_role)}</p>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-bold ">{tickets} Tickets</h3>
        {/* <p className="text-right">${amount}</p> */}
      </div>
    </div>
  );
};
