import api from "./api";
import {
  IAuthRequest,
  IAuthValidationCodeRequest,
  IAuthResponse,
  IValidateAuthResponse,
} from "../types/IAuth";
import authHeader from "./authHeader";

const validateCredentials = async (user: IAuthRequest) => {
  const response = await api().post<IValidateAuthResponse>(
    `auth/validate-credentials`,
    user
  );
  return response;
};

const login = async (data: IAuthValidationCodeRequest) => {
  const response = await api().post<IAuthResponse>(`auth/login`, data);
  return response;
};

const getUser = async () => {
  const response = await api().post<IAuthResponse>(
    `auth/user`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response;
};

const logout = async () => {
  /* const response = await api().get<any>(`auth/logout`);
  return response; */
};

const getQRCode = async (userId: string) => {
  return (await api().get(`auth/qrcode/${userId}`)).data.qrcode;
};

const AuthService = {
  validateCredentials,
  login,
  getUser,
  logout,
  getQRCode,
};

export default AuthService;
