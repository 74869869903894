import { useState } from "react";

import { IArtistResponse } from "../../types/IArtist";
import { FormArtist } from "./Form";
import { SelectedArtist } from "./selected/SelectedArtist";
import { TableArtists } from "./TableArtists";

export const ArtistsView = () => {
  const [createItem, setCreateItem] = useState(false);
  const [id, setId] = useState<string>("");
  const [showSelected, setShowSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IArtistResponse>();

  const handleCreateorEdit = async (id: string) => {
    await setId(id);
    await setCreateItem(!createItem);
  };

  const handleSelectItem = (data: IArtistResponse) => {
     setShowSelected(!showSelected);
     if (data) {
      setSelectedItem(data);
     }
  };

  const itemsTableProps = {
    handleCreateorEdit,
    handleSelectItem,
  };

  const formArtistProps = {
    handleCreateorEdit,
  };

  const selectedArtistProps = {
    handleSelectItem,
  };

  return(
    <div>
      {showSelected
        ? <SelectedArtist {...selectedArtistProps} data={selectedItem} />
        : createItem
        ? <FormArtist {...formArtistProps} id={id}/>
        : <TableArtists {...itemsTableProps} />
      }
    </div>
  );
};
